import React, { ReactNode } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Layout, Space } from 'antd';
import './index.scss';
import { Head } from '../../Head';

const { Footer, Sider, Content } = Layout;

interface MainLayoutProps {}

export const MainLayout: React.FC<MainLayoutProps> = () => {
    return (
        <Layout>
            <Head />
            <Content>
                <Outlet />
            </Content>
        </Layout>
    );
};
