import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import { DeleteUserBulkMutationParams, UserDsQueryParams, UserDsResponse } from './types/user';
import { DeleteSchoolBulkMutationParams, SchoolDsQueryParams, SchoolDsResponse } from './types/school';

export const schoolsApi = createApi({
    reducerPath: 'schoolApi',
    baseQuery,
    // TODO: add types for endpoints
    endpoints: (builder) => ({
        getSchools: builder.query({
            query: (params) => ({
                url: `admin/schools`,
                method: 'GET',
                params
            })
        }),
        getSchoolDs: builder.query<SchoolDsResponse, SchoolDsQueryParams>({
            query(params) {
                return {
                    url: '/admin/schools',
                    method: 'GET',
                    params
                };
            },
            keepUnusedDataFor: 0
        }),
        createSchool: builder.mutation({
            query: (data) => {
                return {
                    url: '/admin/schools',
                    method: 'POST',
                    formData: true,
                    body: data
                };
            }
        }),
        getSchool: builder.query({
            query: (id) => ({
                url: `/admin/schools/${id}`,
                method: 'GET'
            })
        }),
        editSchool: builder.mutation({
            query: (data) => ({
                url: `/admin/schools/${data.id}`,
                method: 'POST',
                formData: true,
                body: data.data
            })
        }),
        deleteSchool: builder.mutation({
            query: (id) => ({
                url: `/admin/schools/${id}`,
                method: 'DELETE'
            })
        }),
        deleteSchoolBulk: builder.mutation<string, DeleteSchoolBulkMutationParams>({
            query: (params) => ({
                url: '/admin/schools/bulk-delete',
                method: 'Delete',
                params
            })
        })
    })
});

export const {
    useLazyGetSchoolsQuery,
    useCreateSchoolMutation,
    useLazyGetSchoolQuery,
    useEditSchoolMutation,
    useGetSchoolDsQuery,
    useDeleteSchoolMutation,
    useDeleteSchoolBulkMutation
} = schoolsApi;
