import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import { UpdatePasswordMutationParams } from './types/auth';

// TODO: add types for endpoints
interface ResponsTypeEditUser {
    id: number;
    data: any;
}

type ArgsTypesEditUser = {
    id: number;
    data: any;
};

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery,
    endpoints: (builder) => ({
        editProfile: builder.mutation<ResponsTypeEditUser, ArgsTypesEditUser>({
            query: (data) => ({
                url: `/auth/profile`,
                method: 'POST',
                formData: true,
                body: data
            })
        }),
        updatePassword: builder.mutation<string, UpdatePasswordMutationParams>({
            query: (credentials) => ({
                url: '/auth/profile/update-password',
                method: 'POST',
                body: credentials
            })
        })
    })
});

export const { useEditProfileMutation, useUpdatePasswordMutation } = profileApi;
