import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { ManageUsers } from './ManageUsers';
import { useNavigate } from 'react-router-dom';
import { ManageSchools } from './ManageSchools';
import { useAppSelector } from '../../hooks';

import './index.scss';
import { ManageSuppliers } from './ManageSuppliers';

export const AdminPanel = () => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const navigate = useNavigate();

    const items =
        role === 'super_admin'
            ? [
                  {
                      key: '1',
                      label: `Users`,
                      children: <ManageUsers />
                  },
                  {
                      key: '2',
                      label: `Schools`,
                      children: <ManageSchools />
                  },
                  {
                      key: '3',
                      label: `Suppliers`,
                      children: <ManageSuppliers />
                  }
              ]
            : [
                  {
                      key: '3',
                      label: `Suppliers`,
                      children: <ManageSuppliers />
                  }
              ];

    useEffect(() => {
        if (!role) return;
        if (role !== 'super_admin' && role !== 'onboarding_representative') {
            navigate('/page-not-found');
        }
    }, [role]);

    return (
        <div className="admin-panel">
            <div className="admin-panel__nav nav">
                <div className="nav__title">Admin panel</div>
                <Tabs defaultActiveKey="1" items={items} />
            </div>
        </div>
    );
};
