import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import close from '../../assets/RoundedClose.svg';
import * as Yup from 'yup';
import { useUpdateStatusSupplierMutation } from '../../api/users';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    data: any;
    message: any;
}

interface commentData {
    comment: string | null;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('This is a required field')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        })
});

export const BlacklistSupplier: FC<ModalProps> = ({ openModal, setOpenModal, data, setTrigger, message }) => {
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [updateStatus, { data: successStatusData, isSuccess: StatusUpdatedSuccessfully, isLoading }] =
        useUpdateStatusSupplierMutation();

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                if (isLoading) return;
                updateStatus({
                    id: data.id,
                    data: {
                        status: 'blacklisted',
                        comment: values.comment
                    }
                });
            })
            .catch(() => {
                return;
            });
    };

    useEffect(() => {
        if (StatusUpdatedSuccessfully && successStatusData) {
            message.success(successStatusData.message);
            setOpenModal(false);
            setTrigger(data.id);
        }
    }, [StatusUpdatedSuccessfully, successStatusData]);

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            No
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : 'Yes, Blacklist'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Blacklist supplier?</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__minititle">
                            Are you sure you want to blacklist <b>{data?.name}</b> ?
                        </div>

                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    disabled={false ? true : false}
                                    floatLabel={'Comment'}
                                    maxLength={512}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                    required={true}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
