import { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';

import close from '../../assets/RoundedClose.svg';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    type?: string;
    data?: tenderDataProps;
    taskId?: number | null | string;
    submitButtonText?: string;
    specialFormats?: string[];
    name: string;
}

interface SuppDataI {
    index: number;
    id: number;
    file: any;
    error: string;
    name: string;
}

interface FormDataI {
    comment: string;
}

export const SuppliersFilesModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    type,
    data,
    setTrigger,
    taskId,
    submitButtonText,
    specialFormats,
    name
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm<FormDataI>();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [fileErr, setFileErr] = useState('');
    const [suppliersArray, setSuppliersArray] = useState<SuppDataI[]>();
    const [isDirty, setIsDirty] = useState(false);

    // set count of suppliers
    useEffect(() => {
        if (!name || !data) return;
        const index = data.phase_tasks.findIndex((item: any) => item.name === name);

        if (index === 0 || index > 0) {
            if (data?.phase_tasks[index]?.files?.suppliers?.length) {
                let array: SuppDataI[] = [];
                const SuppArray = data?.phase_tasks[index]?.files?.suppliers;
                SuppArray.forEach((elem: any, index: number) => {
                    array.push({
                        index: index,
                        id: elem.id,
                        file: elem.file_name ? [{ name: elem.file_name }] : [],
                        error: '',
                        name: elem.name
                    });
                });
                setSuppliersArray(array);

                if (data?.phase_tasks[index]?.comments?.tender_representative) {
                    form.setFieldsValue({ comment: data?.phase_tasks[index]?.comments.tender_representative });
                }
            }
        }
    }, [data?.phase_tasks, name]);

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    interface postData {
        ids: { tender_id: number | undefined; task_id: number | undefined | string | null };
        data: FormData;
    }

    const updateTaskFunc = async (prop: { comment: string }) => {
        setIsLoading(true);
        const abortController = createAbortController();
        const fileData = new FormData();
        const comment: string = prop.comment ? prop.comment : '';
        const method: string = 'PUT';
        fileData.append('comment', comment);
        fileData.append('_method', method);

        const copy = suppliersArray ? [...suppliersArray] : [];
        suppliersArray?.forEach((elem: SuppDataI, index: number) => {
            const id: string = elem.id + '';

            if (elem.file && (!elem.error || elem.error === 'Upload failed. Check your internet connection')) {
                const contentFile: any = elem?.file?.[0]?.originFileObj;

                if (contentFile) {
                    const file: File = contentFile;
                    const fileName: string = elem.file.name;
                    fileData.append(`suppliers[${index}][file]`, file, fileName);
                }

                fileData.append(`suppliers[${index}][id]`, id);
            }

            if (abortController) {
                abortController.abort();
            }
            copy[index].error = '';
        });

        setSuppliersArray(copy);
        const datatoPost: postData = {
            ids: { tender_id: data?.id, task_id: taskId },
            data: fileData
        };
        const newAbortController = createAbortController();
        setAbortController(newAbortController);
        const signal = newAbortController.signal;
        try {
            await UpdateTaskFile(datatoPost, signal);
            await setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            await messageApi?.success('The task has been updated successfully');
        } catch {
            setFileErr('Upload failed. Check your internet connection');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = () => {
        let isValid = true;
        if (fileErr) {
            isValid = false;
        }

        const copy = suppliersArray ? [...suppliersArray] : [];
        suppliersArray?.forEach((elem: SuppDataI, index: number) => {
            if (elem.error) {
                isValid = false;
            }
            if (!elem.file.length) {
                isValid = false;
                copy[index].error =
                    'You can upload files in the following formats: doc, pdf with a maximum size of 100MB';
            }
        });
        setSuppliersArray(copy);
        if (isValid) {
            form.validateFields()
                .then((values: any) => {
                    if (isLoading) return;
                    updateTaskFunc(values);
                })
                .catch(() => {
                    setFileErr('You can upload files in the following formats: doc, pdf with a maximum size of 100MB');
                });
        }
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (isDirty) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (isDirty) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : submitButtonText ? submitButtonText : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">{name}</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            Upload a file to import {name} for{' '}
                            <span style={{ color: '#001965' }}>
                                each <br /> suppliers ({suppliersArray?.length} files)
                            </span>{' '}
                            <span style={{ color: '#E80A0F' }}>*</span>
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            {suppliersArray?.map((elem: any, index: number) => {
                                return (
                                    <div>
                                        <div className="main__name">{elem.name}</div>
                                        <Form.Item name="file">
                                            <FilePicker
                                                disabled={isLoading ? true : false}
                                                fileList={elem.file}
                                                onChange={(value: any) => {
                                                    if (!suppliersArray) return;
                                                    let copy = [...suppliersArray];
                                                    const indexEl = copy.findIndex(
                                                        (item: SuppDataI) => item.id === elem.id
                                                    );
                                                    copy[indexEl].file = value;
                                                    setSuppliersArray(copy);
                                                    setFileErr('');
                                                    setIsDirty(true);
                                                }}
                                                error={elem.error}
                                                setError={(error: any) => {
                                                    if (!suppliersArray) return;
                                                    let copy = [...suppliersArray];
                                                    const indexEl = copy.findIndex(
                                                        (item: SuppDataI) => item.id === elem.id
                                                    );
                                                    copy[indexEl].error = error;
                                                    setSuppliersArray(copy);
                                                }}
                                                specialFormats={specialFormats}
                                            />
                                        </Form.Item>
                                    </div>
                                );
                            })}
                            <Form.Item name="comment">
                                <FloatTextArea
                                    disabled={isLoading ? true : false}
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment'])?.length}
                                    onInput={() => setIsDirty(true)}
                                />
                            </Form.Item>
                        </Form>
                        <div className="error-text">{fileErr}</div>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
