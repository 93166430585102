import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, useState } from 'react';
import {
    useApproveTaskMutation,
    useDeclineTaskMutation,
    useDeleteFileMutation,
    useSendActivitiesMutation
} from '../../../api/tenders';
import { SupplierScorecardModal } from '../../Modals/SupplierScorecardModal';
import { Button, Modal, Tooltip } from 'antd';
import done from '../../../assets/Done, Check.svg';
import pin from '../../../assets/pinIcon.svg';
import { Loader } from '../../Loader/Loader';
import messageGrey from '../../../assets/messageGrey.svg';
import edit from '../../../assets/editAdminIcon.svg';
import plus from '../../../assets/Plus, Add.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import TenderMandat from '../../../assets/TenderMandat.svg';
import { AllTasksCompleted } from '../AllTasksCompleted';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export interface PrevData {
    link: string;
    comment: string;
}

export const SupplierScorecard: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isSupplierScorecardModal, setIsSupplierScorecardModal] = useState(false);

    const [taskId, setTaskId] = useState<number | null>(null);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [deleteFile, { isLoading: isLoadingDeleteFile }] = useDeleteFileMutation();
    const [prevData, setPrevData] = useState<PrevData>({
        link: '',
        comment: ''
    });
    const [approveTask, { isLoading: isLoadingApproveTask }] = useApproveTaskMutation();
    const [sendActivities] = useSendActivitiesMutation();
    const [declineTask, { isLoading: isLoadingDeclineTask }] = useDeclineTaskMutation();

    const declineTaskFunc = async (id: number) => {
        try {
            const formData = {
                ids: {
                    tenderId: data?.id,
                    taskId: id
                }
            };
            await declineTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const sendActivitiesFunc = async (id: number) => {
        const formData = {
            tenderId: data.id,
            taskId: id,
            data: {
                action: 'link_click'
            }
        };
        try {
            await sendActivities(formData);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const deleteFileFunc = async () => {
        if (role === 'school_user') {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file: data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.school_user[0].uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        } else {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file:
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0]
                            .uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    const approveTaskFunc = async (id: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: id
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'supplier_scorecard') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved && !elem.link) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role !== 'super_admin' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsSupplierScorecardModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && !elem.approved && elem.link) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Supplier Scorecard Link</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div className="task-complete__link-supplier-scorecard">
                                                            <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {elem.comments.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setPrevData({
                                                            link: elem.link ? elem.link : '',
                                                            comment: elem.comments.tender_representative
                                                        });
                                                        setIsSupplierScorecardModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Supplier Scorecard Link</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div className="task-complete__link-supplier-scorecard">
                                                            <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {elem.comments.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                (elem.status === 'need_approve_tr' || elem.status === 'need_resend_tr') &&
                                elem.approved
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Completed by School User
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Supplier Scorecard Link</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div className="task-complete__link-supplier-scorecard">
                                                            <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {role !== 'super_admin' && (
                                                        <img
                                                            src={edit}
                                                            className="task-complete__edit"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setPrevData({
                                                                    link: elem.link ? elem.link : '',
                                                                    comment: elem.comments.tender_representative
                                                                });
                                                                setIsSupplierScorecardModal(true);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                {elem.comments.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem.approved && elem.link) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={TenderMandat} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <Tooltip
                                                    title="To approve this activity, you need to complete the Supplier Scorecard first"
                                                    overlayClassName={` ${
                                                        !elem.link_clicked
                                                            ? 'custom-tooltip-disabled'
                                                            : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`task-complete__approved ${
                                                            !elem.link_clicked ? 'disabled-blue-button' : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.link_clicked && !isLoadingApproveTask) {
                                                                approveTaskFunc(elem.id);
                                                            }
                                                        }}
                                                    >
                                                        Completed
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Supplier Scorecard Link</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div className="task-complete__link-supplier-scorecard">
                                                            <a
                                                                href={elem.link ? elem.link : ''}
                                                                target="_blanc"
                                                                onClick={() => sendActivitiesFunc(elem.id)}
                                                            >
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {elem.comments.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value school-user">
                                                                <img src={messageGrey} />
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && elem.approved && elem.link) {
                                return (
                                    <>
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline"
                                                        onClick={() => {
                                                            if (isLoadingDeclineTask) return;
                                                            declineTaskFunc(elem.id);
                                                        }}
                                                    >
                                                        Reject
                                                    </div>
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Completed
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">
                                                            Supplier Scorecard Link
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link-supplier-scorecard">
                                                                <a
                                                                    href={elem.link ? elem.link : ''}
                                                                    target="_blanc"
                                                                    onClick={() => sendActivitiesFunc(elem.id)}
                                                                >
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {elem.comments.tender_representative && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {elem.comments.tender_representative}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (
                                (elem.status === 'need_approve_tr' || elem.status === 'pending_tr') &&
                                elem.approved &&
                                elem.link
                            ) {
                                return (
                                    <>
                                        <AllTasksCompleted />
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={TenderMandat} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">
                                                            Supplier Scorecard Link
                                                        </div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link-supplier-scorecard">
                                                                <a
                                                                    href={elem.link ? elem.link : ''}
                                                                    target="_blanc"
                                                                    onClick={() => sendActivitiesFunc(elem.id)}
                                                                >
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {elem.comments.tender_representative && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {elem.comments.tender_representative}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}

            {isSupplierScorecardModal && (
                <SupplierScorecardModal
                    openModal={isSupplierScorecardModal}
                    setOpenModal={setIsSupplierScorecardModal}
                    messageApi={messageApi}
                    data={data}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    prevData={prevData}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
