import { Button, Modal, Spin } from 'antd';
import DatePicker from 'react-datepicker';

import close from '../../assets/RoundedClose.svg';
import CalendarIcon from '../../assets/calendar-chedule.svg';
import arrow from '../../assets/Rounded-arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

export const TaskDeadlineModal = ({
    openModal,
    setOpenModal,
    setSelectedDate,
    SelectedDate,
    isLoading,
    updateDeadlineFunc,
    title = 'Task Deadline',
    buttonText = 'Update Deadline'
}) => {
    const isDayDisabled = (date) => {
        const isSunday = (date) => {
            return date.getDay() === 0 || date.getDay() === 6;
        };

        return !isSunday(date);
    };

    return (
        <Modal
            destroyOnClose
            className="modal modal-deadline"
            centered
            open={openModal}
            onCancel={() => setOpenModal(false)}
            footer={<></>}
            closeIcon={<img src={close} className="image-deadline-close" />}
            width={344}
        >
            <Spin spinning={isLoading} size="large">
                <div className="modal deadline">
                    <div className="modal__header deadline">
                        <div className="modal__title deadline">{title}</div>
                    </div>
                    <div className="modal__main main deadline-main">
                        <div className="input-date">
                            <img src={CalendarIcon} />
                            {moment(SelectedDate).format('YYYY-MM-DD')}
                        </div>

                        <div className="main__line"></div>
                        <DatePicker
                            className="datepicker"
                            selected={new Date(SelectedDate)}
                            onChange={(date) => setSelectedDate(date)}
                            startDate={new Date()}
                            inline
                            dateFormat="yyyy-MM-dd"
                            showPopperArrow={true}
                            minDate={new Date()}
                            filterDate={isDayDisabled}
                            renderCustomHeader={({
                                date,
                                _changeYear,
                                _changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                _prevMonthButtonDisabled,
                                _nextMonthButtonDisabled
                            }) => (
                                <div className="current-month">
                                    <img src={arrow} onClick={decreaseMonth} style={{ cursor: 'pointer' }} />
                                    <div className="current-month__month">
                                        {date?.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
                                    </div>

                                    <img
                                        src={arrow}
                                        style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
                                        onClick={increaseMonth}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="footer-modal-deadline">
                        <Button type="primary" style={{ width: '100%' }} onClick={updateDeadlineFunc}>
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
