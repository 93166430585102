import { FC } from 'react';
import { PhaseI } from '../../api/types/common';
import { Divider } from 'antd';
import onTrack from '../../assets/onTrack.svg';
import clock from '../../assets/clock-time-cross.svg';
import flag from '../../assets/Flag, Circle.svg';
import suitcase from '../../assets/suitcase.svg';
import DesignPhase from '../../assets/DesignPhase.svg';
import messageIcon from '../../assets/chat-message.svg';
import Tasklist from '../../assets/Task list, Document, Text.svg';
import tenderSubmit from '../../assets/tenderSubmit.svg';
import tenderDesign from '../../assets/tenderDesign.svg';
import tenderDefine from '../../assets/tenderDefine.svg';
import tenderLaunch from '../../assets/tenderLaunch.svg';
import DefinePhase from '../../assets/DefinePhase.svg';
import LaunchPhase from '../../assets/LaunchPhase.svg';
import Evaluate1 from '../../assets/Evaluate1+2.svg';
import Evaluate3 from '../../assets/Evaluate3.svg';
import AwardIcon from '../../assets/AwardIcon.svg';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from '../../hooks';

interface Props {
    item: PhaseI;
}

export const KanbanItemSU: FC<Props> = ({ item }) => {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.profileReducer);

    return (
        <div
            className={`su kanban ${user.id === item.su_id ? '' : 'not-active'}`}
            onClick={() => {
                if (user.id === item.su_id) {
                    navigate(`/tender/${item.id}`);
                } else {
                    return;
                }
            }}
        >
            <div className="su__top-row">
                <div className="su__item left">
                    <div className="su__title">{item.expense_area_name} tender</div>
                    <div className="su__wrap">
                        {item.phase_task_status === 'on_track' && (
                            <div className="card__status on-track">
                                <img src={onTrack} />
                                On-track
                            </div>
                        )}
                        {(item.phase_task_status === 'late' || item.phase_task_status === 'follow_up') && (
                            <div className="card__status late">
                                <img src={clock} />
                                Late
                            </div>
                        )}

                        <div className="su__id">
                            {' '}
                            ID <span>{item.number}</span>
                        </div>
                    </div>
                </div>
                <div className="su__item phase">
                    {item?.phase_name === 'Submit' && <img src={tenderSubmit} />}
                    {item?.phase_name === 'Define' && <img src={tenderDefine} />}
                    {item?.phase_name === 'Design' && <img src={tenderDesign} />}
                    {item?.phase_name === 'Launch' && <img src={tenderLaunch} />}
                    {item?.phase_name === 'Evaluation Gate 1+2' && <img src={Evaluate1} />}
                    {item?.phase_name === 'Evaluation Gate 3' && <img src={Evaluate3} />}
                    {item?.phase_name === 'Evaluation Gate 4' && <img src={Evaluate3} />}
                    {item?.phase_name === 'Award' && <img src={AwardIcon} />}
                    <div className="phase__title">
                        {item.phase_name === 'Evaluation Gate 1+2' ||
                        item.phase_name === 'Evaluation Gate 3' ||
                        item.phase_name === 'Evaluation Gate 4'
                            ? item.phase_name
                            : `${item.phase_name} Phase`}
                    </div>
                    <div className="phase__count">
                        {item.phase_number}
                        <span>/7</span>
                    </div>
                </div>
            </div>
            <Divider />
            <div className="su__bottom-row">
                <div className="su__element element">
                    <div className="element__title">Expense area</div>
                    <div className="element__value">
                        <img src={suitcase} /> {item.expense_area_name}
                    </div>
                </div>
                <div className="su__element element">
                    <div className="element__title">Contract Value</div>
                    <div className="element__value">
                        <img src={suitcase} /> R {item.contract_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                </div>
                <div className="su__element element">
                    <div className="element__title">Messenger</div>
                    <div className="element__value">
                        <img src={messageIcon} />{' '}
                        <div className={`element__number ${item.messages_count > 0 ? 'blue' : 'grey'}`}>
                            {item.messages_count}
                        </div>
                    </div>
                </div>
                <div className="su__element element">
                    <div className="element__title">Task(s)</div>
                    <div className="element__value">
                        <img src={Tasklist} />{' '}
                        <div className={`element__number ${item.tasks_count > 0 ? 'red' : 'grey'}`}>
                            {item.tasks_count}{' '}
                        </div>
                        <div>
                            {item.deadline && (
                                <div className={`card__deadline`}>
                                    Deadline {moment(item.deadline).format('YYYY-MM-DD')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
