import { useState, FC } from 'react';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { useApproveTaskMutation, useDeclineTaskMutation } from '../../../api/tenders';
import CancelIcon from '../../../assets/CancelIcon.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import stopImage from '../../../assets/stopImage.svg';
import done from '../../../assets/Done, Check.svg';
import pendingIcon from '../../../assets/panding-circle.svg';

interface CancelIconTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CancelTender: FC<CancelIconTaskProps> = ({ data, role, setTrigger }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);

    const [declineTask, { isLoading: isLoadingDeclineTask }] = useDeclineTaskMutation();
    const [approveTask, { isLoading: isLoadingApprove }] = useApproveTaskMutation();

    const declineTaskFunc = async (id: number) => {
        try {
            const formData = {
                ids: {
                    tenderId: data?.id,
                    taskId: id
                }
            };
            await declineTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    // const declineTaskFunc = async () => {
    //     try {
    //         const formData = {
    //             ids: {
    //                 tenderId: data?.id,
    //                 taskId: taskId
    //             }
    //         };
    //         await declineTask(formData).unwrap();
    //         setDeclineMeeting(false);
    //         await setTrigger((prev: boolean) => !prev);
    //     } catch {}
    // };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks?.map((elem: taskProps) => {
                    if (elem.action === 'canceled_tender') {
                        if (role === 'super_admin') {
                            if (elem.status === 'completed') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CancelIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"> </div>
                                        </div>

                                        {elem.comments.tender_representative_sent && (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.tender_representative_sent}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_sa' && !elem.approved && !elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CancelIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div
                                                    className="task-complete__decline"
                                                    onClick={() => {
                                                        if (isLoadingDeclineTask) return;
                                                        declineTaskFunc(elem.id);
                                                    }}
                                                >
                                                    Reject
                                                </div>
                                                <div
                                                    className={`task-complete__approved`}
                                                    onClick={() => {
                                                        if (isLoadingApprove) return;
                                                        approveTaskFunc(elem.id);
                                                    }}
                                                >
                                                    Approve
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.tender_representative_sent && (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.tender_representative_sent}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_sa' && elem.approved && !elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CancelIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div
                                                    className="task-complete__decline"
                                                    onClick={() => {
                                                        if (isLoadingDeclineTask) return;
                                                        declineTaskFunc(elem.id);
                                                    }}
                                                >
                                                    Reject
                                                </div>
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Approved
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.tender_representative_sent && (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.tender_representative_sent}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_sa' && !elem.approved && elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete red`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CancelIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__title-row"
                                                    style={{ margin: '0 15px 0 0' }}
                                                >
                                                    <div className="task-complete__decline-active">
                                                        <img src={stopImage} />
                                                        Rejected
                                                    </div>
                                                </div>
                                                <div
                                                    className={`task-complete__approved`}
                                                    onClick={() => {
                                                        if (isLoadingApprove) return;
                                                        approveTaskFunc(elem.id);
                                                    }}
                                                >
                                                    Approve
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.tender_representative_sent && (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.tender_representative_sent}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        }
                        if (role === 'tender_representative') {
                            if (elem.status === 'completed') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CancelIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"> </div>
                                        </div>

                                        {elem.comments.tender_representative_sent && (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.tender_representative_sent}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_sa' && !elem.approved && !elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={CancelIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.tender_representative_sent && (
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.tender_representative_sent}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}
        </>
    );
};
