import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.thehive.schooladvisor.co.za/api/v1';

const token = JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}');

const service = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'blob',
        ResponsType: 'blob',
        Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser'))?.authToken
        }`
    }
});

service.interceptors.request.use(
    (request) => {
        request.headers.Authorization = `Bearer ${
            JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser'))?.authToken
        }`;
        return request;
    },
    (error) => {
        return error;
    }
);

service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status) {
            const { status } = error.response;

            if (status === 401 && window.location.pathname !== '/login') {
                localStorage.removeItem('token');
            }
        }

        return Promise.reject(error);
    }
);

export default service;
