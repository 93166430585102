import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { SupplierI, tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { useRejectSupplierMutation } from '../../api/tenders';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedSupplier: SupplierI | null;
    data: any;
    taskId: number | null;
    messageApi: any;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string().required('This is a required field')
});

export const RejectIncumbentSupplier: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    taskId,
    setTrigger,
    selectedSupplier,
    data,
    messageApi,
    setIsRejectModal
}) => {
    const handleFormSubmit = () => {
        setOpenModal(false);
        setIsRejectModal(true);
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            Yes, I’m sure
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">Reject your incumbent supplier?</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 55px 56px' }}>
                        <div className="modal__subtitle">
                            Are you sure you want to reject your incumbent supplier{' '}
                            <b>
                                ({selectedSupplier?.name_prefix}) {selectedSupplier?.name}{' '}
                            </b>
                            ? We recommend keeping them in the selection process until the final evaluation gate to
                            avoid potential service disruptions.
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
