import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../../../api/auth';
import logo from '../../../assets/logo.svg';
import { Button, Form, message } from 'antd';
import arrow from '../../../assets/arrow-left.svg';
import * as Yup from 'yup';
import iconSuccess from '../../../assets/mail-notification-success.svg';
import '../index.scss';
import { FloatInput } from '../../../components/Fields/FloatInput';
import { yupSync } from '../../../utils';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { Loader } from '../../../components/Loader/Loader';
import moment from 'moment';

interface FormData {
    password: string;
    confirmPassword: string;
}

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required('Field is required')
        .test('password-requirements', '', (value) => {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordRegex.test(value);
        }),
    confirmPassword: Yup.string().required('Field is required')
});

export const ResetPassword: React.FC = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [searchParams] = useSearchParams();
    const [resetPasswordMutation, { isLoading, isSuccess }] = useResetPasswordMutation();
    const navigate = useNavigate();
    const { handleKeyPress } = usePreventSpaceTyping();
    const [passwordErr, setPasswordErr] = useState(false);

    useEffect(() => {
        if (!searchParams.get('token')) {
            // navigate('/login');
        }
    }, [navigate, searchParams]);

    const handleSubmit = async () => {
        try {
            const values = form.getFieldsValue();
            await resetPasswordMutation({
                email: decodeURIComponent(searchParams.get('email') || ''),
                token: searchParams.get('token') as string,
                password: values.password,
                password_confirmation: values.confirmPassword
            }).unwrap();
            localStorage.clear();
            sessionStorage.clear();
        } catch (error: any) {
            if (error?.data?.message) {
                messageApi.error(error?.data?.message);
            } else {
                messageApi.error('Something went wrong, please try again later');
            }
        }
    };

    return (
        <>
            {contextHolder}
            {isSuccess ? (
                <div className="auth">
                    <div className="auth__logo-container">
                        <img src={logo} className="auth__logo" alt="logo" />
                    </div>
                    <div className="auth__form form success-form-reset">
                        <img src={iconSuccess} alt="icon" />
                        <div className="form__title">Password changed</div>
                        <div className="form__subtitle">Please log in with the new password</div>
                        <Button type="primary" htmlType="submit" block onClick={() => navigate('/login')}>
                            Log In
                        </Button>
                    </div>
                    <div className="auth__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
                </div>
            ) : (
                <div className="auth">
                    <div className="auth__logo-container">
                        <img src={logo} className="auth__logo" alt="logo" />
                    </div>
                    <div className="auth__form form reset-form">
                        <div className="form__title">Reset Password</div>
                        <Form form={form} onFinish={handleSubmit} className="form">
                            <Form.Item
                                className="input-wrapper"
                                name="password"
                                rules={yupSync('password', validationSchema)}
                            >
                                <FloatInput
                                    name="password"
                                    type="password"
                                    floatLabel="Password"
                                    placeholder="Password(>8characters)"
                                    className="input"
                                    onKeyPress={handleKeyPress}
                                    maxLength={32}
                                    onBlur={() => {
                                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
                                        const res = passwordRegex.test(form.getFieldValue('password'));
                                        if (!res && form.getFieldValue('password')) {
                                            setPasswordErr(true);
                                        } else {
                                            setPasswordErr(false);
                                        }
                                    }}
                                />
                            </Form.Item>
                            <div className="reset-form__label" style={passwordErr ? { color: '#e80a0f' } : {}}>
                                {`The password should contain numbers and at least one upper case and lower case letter (>8 characters)`}
                            </div>

                            <Form.Item
                                className="input-wrapper"
                                name="confirmPassword"
                                rules={[
                                    ...yupSync('confirmPassword', validationSchema),
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Oops! Your passwords do not match'));
                                        }
                                    })
                                ]}
                                dependencies={['password']}
                            >
                                <FloatInput
                                    name="confirmPassword"
                                    type="password"
                                    className="input"
                                    floatLabel="Repeat Password"
                                    placeholder="Repeat Password(>8characters)"
                                    onKeyPress={handleKeyPress}
                                    maxLength={32}
                                />
                            </Form.Item>
                            <Button className="auth__reset" type="primary" htmlType="submit" block>
                                {isLoading ? <Loader /> : 'Reset Password'}
                            </Button>

                            <div className="auth__back" onClick={() => navigate('/login')}>
                                <img src={arrow} alt="back icon" /> Back to Log in
                            </div>
                        </Form>
                    </div>
                    <div className="auth__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
                </div>
            )}
        </>
    );
};
