import { ThemeConfig } from 'antd/es/config-provider';
import { colors } from './colors';

export const defaultTheme: ThemeConfig = {
    token: {
        fontFamily: 'Quicksand, sans-serif',
        colorPrimary: colors.Primary.Dark,
        colorPrimaryHover: colors.Buttons.Accent_Hover,
        colorPrimaryActive: colors.Buttons.Accent_Pressed,
        colorSuccess: colors.Secondary.Success,
        colorError: colors.Secondary.Negative,
        colorInfo: colors.Texts_and_Icons.Primary,
        colorText: colors.Texts_and_Icons.Primary
    },
    components: {
        Table: {
            colorTextHeading: colors.Texts_and_Icons.Secondary
        },
        Button: {
            colorPrimary: colors.Buttons.Accent,
            colorPrimaryHover: colors.Buttons.Accent_Hover,
            colorPrimaryActive: colors.Buttons.Accent_Pressed,
            colorErrorHover: colors.Buttons.Danger_Hover,
            colorError: colors.Buttons.Danger,
            colorErrorActive: colors.Buttons.Danger_Pressed,
            colorLink: colors.Buttons.Main,
            colorLinkHover: colors.Buttons.Main_Hover,
            colorLinkActive: colors.Buttons.Main_Pressed,
            colorTextDisabled: colors.Buttons.Main_Disabled,
            colorBgTextHover: 'transparent',
            borderRadius: 20,
            fontSize: 16,
            fontFamily: 'Quicksand, sans-serif',
            controlOutline: 'none'
        },
        Segmented: {
            fontSize: 16,
            borderRadius: 20,
            lineHeight: 20,
            itemHoverBg: 'transparent',
            itemActiveBg: 'transparent',
            itemSelectedBg: 'white',
            itemHoverColor: colors.Texts_and_Icons.Primary
        },
        Input: {
            colorPrimaryHover: colors.Borders_and_Lines.Accent
        },
        Checkbox: {
            colorPrimary: colors.Buttons.Main,
            colorPrimaryHover: colors.Buttons.Main_Hover,
            controlInteractiveSize: 20,
            lineWidth: 1.5,
            borderRadiusSM: 6
        },
        Menu: {
            horizontalItemBorderRadius: 20,
            itemHoverColor: colors.Texts_and_Icons.Secondary
        },
        Tabs: {
            colorText: colors.Texts_and_Icons.Label,
            itemHoverColor: colors.Texts_and_Icons.Secondary,
            itemActiveColor: colors.Texts_and_Icons.Primary,
            itemSelectedColor: colors.Texts_and_Icons.Primary,
            fontSize: 16
        }
    }
};
