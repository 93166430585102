import React from 'react';
import { Button, Checkbox, Table as AntTable, TableProps as AntTableProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Key } from 'antd/es/table/interface';
import './index.scss';
import Delete from '../Icons/Delete';
import { colors } from '../../themes/colors';

interface TableProps<T> extends AntTableProps<T> {
    setSelectedRowKeys?: React.Dispatch<React.SetStateAction<Key[]>>;
    selectedRowKeys?: Key[];
    handleDelete?: () => void;
}

export const Table = <T extends Record<string, any>>({
    setSelectedRowKeys,
    selectedRowKeys,
    handleDelete,
    ...restProps
}: TableProps<T>) => {
    const handleSelectAll = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        // TODO: fix this. row.id can be undefined
        const keys = checked ? restProps.dataSource!.map((row) => row.id) : [];
        setSelectedRowKeys && setSelectedRowKeys(keys);
    };

    const renderHeader = () => {
        return (
            <thead className="ant-table-thead table-select-header">
                <tr>
                    <th className="ant-table-cell">
                        {selectedRowKeys && (
                            <Checkbox
                                indeterminate={
                                    selectedRowKeys.length > 0 && selectedRowKeys.length < restProps.dataSource!.length
                                }
                                checked={selectedRowKeys.length === restProps.dataSource!.length}
                                onChange={handleSelectAll}
                            />
                        )}
                    </th>
                    <th className="ant-table-cell" colSpan={restProps.columns!.length}>
                        {selectedRowKeys && selectedRowKeys.length} Checked item(s)
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                handleDelete && handleDelete();
                            }}
                            icon={<Delete style={{ fontSize: '18px', margin: '0 -5px 0 10px' }} />}
                            danger
                            style={{ fontSize: '16px' }}
                        >
                            Delete
                        </Button>
                    </th>
                </tr>
            </thead>
        );
    };
    return (
        <AntTable<T>
            components={{
                ...(Array.isArray(selectedRowKeys) &&
                    selectedRowKeys.length > 0 && {
                        header: {
                            wrapper: renderHeader
                        }
                    })
            }}
            {...restProps}
        />
    );
};
