import React, { useState } from 'react';
import { Segmented } from 'antd';
import { SchoolUsers } from './SchoolUsers';
import { SchoolAdvisors } from './SchoolAdvisors';

export const ManageUsers = () => {
    const [role, setRole] = useState<string | number>('External Users');
    return (
        <div className="admin-panel__table-container">
            <div className="full-width">
                <Segmented options={['External Users', 'Internal Users']} value={role} onChange={setRole} />
            </div>
            {role === 'External Users' && <SchoolUsers />}
            {role === 'Internal Users' && <SchoolAdvisors />}
        </div>
    );
};
