import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const LockSvg = ({ fill }: { fill: string }) => (
    <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
            <path
                id="Path"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1682 18.5025H6.83142C6.38917 18.5026 5.965 18.327 5.65229 18.0143C5.33957 17.7016 5.16394 17.2774 5.16406 16.8352V10.9994C5.16394 10.5571 5.33957 10.133 5.65229 9.82026C5.965 9.50754 6.38917 9.33191 6.83142 9.33203H15.1682C15.6105 9.33191 16.0346 9.50754 16.3474 9.82026C16.6601 10.133 16.8357 10.5571 16.8356 10.9994V16.8352C16.8357 17.2774 16.6601 17.7016 16.3474 18.0143C16.0346 18.327 15.6105 18.5026 15.1682 18.5025V18.5025Z"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Path_2"
                d="M11.0304 13.8863C11.0428 13.8986 11.0465 13.9172 11.0398 13.9334C11.0331 13.9495 11.0173 13.96 10.9998 13.96C10.9824 13.96 10.9666 13.9495 10.9599 13.9334C10.9532 13.9172 10.9569 13.8986 10.9693 13.8863V13.8863C10.9862 13.8694 11.0135 13.8694 11.0304 13.8863"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Path_3"
                d="M13.8651 15.169V12.668"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Path_4"
                d="M7.66504 9.33186V6.83082V6.83082C7.66512 4.98913 9.15808 3.49618 10.9998 3.49609V3.49609C12.8414 3.49618 14.3344 4.98913 14.3345 6.83082V6.83082V9.33186"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Path_5"
                d="M8.16517 13.8863C8.17753 13.8986 8.18123 13.9172 8.17454 13.9334C8.16785 13.9495 8.15209 13.96 8.13461 13.96C8.11713 13.96 8.10137 13.9495 8.09469 13.9334C8.088 13.9172 8.09169 13.8986 8.10406 13.8863V13.8863C8.12093 13.8694 8.14826 13.8694 8.16513 13.8863"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

const Lock = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <LockSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default Lock;
