import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import expiredLink from '../../assets/expiredLink.svg';
import logo from '../../assets/logo.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import './index.scss';
import { Button } from 'antd';
import moment from 'moment';

export const ExpiredLink = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);

    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    const navigate = useNavigate();
    return (
        <div className="home">
            <div className="home__logo-container">
                {screenWidth > 600 && <img src={logo} className="header__logo" />}
                {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
            </div>
            <div className="home__main">
                <img src={expiredLink} />
                <h1 className="home__text">Link is not valid anymore</h1>
                <Button type="primary" className="home__btn" onClick={() => navigate('/login')}>
                    Home
                </Button>
            </div>
            <div className="home__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
        </div>
    );
};
