import { useState, FC, useEffect } from 'react';
import { useCompleteTaskMutation, useDeclineTaskMutation, useUpdateDeadlineMutation } from '../../api/tenders';
import { taskProps, tenderDataProps } from '../../pages/TenderViewPage/types';
import { Button, Tooltip } from 'antd';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';

import done from '../../assets/Done, Check.svg';
import edit from '../../assets/editAdminIcon.svg';
import arrow from '../../assets/arrow-right-white.svg';
import task from '../../assets/task.svg';

import moment from 'moment';
import './phases.scss';

interface PropsPhase {
    data: tenderDataProps;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const SubmitPhase: FC<PropsPhase> = ({ data, setConfirmModal, setTrigger, messageApi }) => {
    const [completeTask] = useCompleteTaskMutation();
    const [declineTask] = useDeclineTaskMutation();
    const [updateDeadline, { data: updatedData, isSuccess, isError, isLoading }] = useUpdateDeadlineMutation();
    const [deadlineModal, setDeadlineModal] = useState(false);
    const [SelectedDate, setSelectedDate] = useState<Date | string>(new Date());

    useEffect(() => {
        if (isSuccess) {
            messageApi.success(updatedData?.message);
            setTrigger((prev) => !prev);
            setDeadlineModal(false);
        }
        if (isError) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccess, isError]);

    const completeTaskFunc = async (id: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: id
            };
            await completeTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    useEffect(() => {
        if (data) {
            const date = data?.phase_deadline_at
                ? moment(data?.phase_deadline_at).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD');
            setSelectedDate(date);
        }
    }, [data]);

    const declineTaskFunc = async (id: number) => {
        try {
            const formData = {
                ids: {
                    tenderId: data.id,
                    taskId: id
                },
                data: {}
            };
            await declineTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                id: data.id,
                data: { phase_deadline_at: SelectedDate }
            };
            await updateDeadline(formData).unwrap();
        } catch {}
    };

    return (
        <>
            <div className={`tasks`}>
                <div className="tasks__header">
                    <div className="count-wrapper">
                        Task Centre
                        <div className="count">{data?.phase_tasks?.length}</div>
                    </div>

                    <div className="tasks__info-wrap info-wrap">
                        <div className="info-wrap__deadline">Task Deadline</div>
                        <div className="info-wrap__status">
                            {data?.phase_deadline_at
                                ? moment(data?.phase_deadline_at).format('YYYY-MM-DD')
                                : 'Not indicated'}
                        </div>
                        <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                            <img src={edit} />
                            Edit
                        </div>
                    </div>
                </div>
                <div className="tasks__list">
                    {data?.phase_tasks.map((elem: taskProps) => {
                        return (
                            <div className={`tasks__task task ${elem.status === 'completed' ? 'green' : ''}`}>
                                <div className="task__title">
                                    <img src={task} />
                                    {elem?.name}
                                </div>
                                {elem.status === 'pending_sa' && (
                                    <div className="task__btn" onClick={() => completeTaskFunc(elem.id)}>
                                        Received
                                    </div>
                                )}
                                {elem.status === 'completed' && (
                                    <div className="task__btn-wrap">
                                        <div className="task__btn-decline" onClick={() => declineTaskFunc(elem.id)}>
                                            Not Received
                                        </div>
                                        <span className="task__received">
                                            <img src={done} /> Received
                                        </span>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="tasks__btn-wrap">
                    <div></div>
                    <Tooltip
                        title="To move to the next Phase, you need to Complete all the tasks"
                        overlayClassName={` ${!data?.can_update_phase ? 'custom-tooltip-disabled' : 'custom-tooltip'}`}
                    >
                        <Button
                            className={`tasks__next-btn ${data?.can_update_phase ? '' : 'disabled'}`}
                            type="primary"
                            onClick={() => {
                                if (data?.can_update_phase) {
                                    setConfirmModal(true);
                                } else {
                                    return;
                                }
                            }}
                        >
                            Next Phase <img src={arrow} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            {deadlineModal && (
                <TaskDeadlineModal
                    openModal={deadlineModal}
                    setOpenModal={setDeadlineModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
