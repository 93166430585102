import { FC, useEffect, useRef, useState } from 'react';
import { Spin, Button, Modal, Form, message, Radio, RadioChangeEvent } from 'antd';
import { yupSync } from '../../utils';
import { FloatInput } from '../Fields/FloatInput';
import {
    useCreateUserMutation,
    useEditUserMutation,
    useLazyGetUserQuery,
    useResendInviteMutation
} from '../../api/users';
import { CustomErrorType } from '../../api/types/global';

import editAdminIcon from '../../assets/editAdminIcon.svg';
import email from '../../assets/mail.svg';
import RoundedWhite from '../../assets/RoundedWhite.svg';
import close from '../../assets/RoundedClose.svg';
import DefaultAvatar from '../../assets/DefaultAvatar.svg';
import heic2any from 'heic2any';

import * as Yup from 'yup';
import './Modals.scss';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<{ type: string; open: boolean; id: number | null }>>;
    type: string;
    userId: number | null;
    refetch: () => void;
}

interface FormData {
    first_name: string;
    last_name: string;
    email: string;
}

const validationSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email field is required')
});

export const ModalAdmin: FC<ModalProps> = ({ openModal, setOpenModal, type, userId, refetch }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [fileAvatar, setFileAvatar] = useState<any>();
    const [role, setRole] = useState('tender_representative');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [changed, setChanged] = useState<boolean>(false);
    const [fileErr, setFileErr] = useState('');

    const [getUserTrigger, { data: dataUser, isSuccess: getUserSuccess }] = useLazyGetUserQuery();
    const [createUser, { isError: isErrorCreateUser, error: errorCreateUser }] = useCreateUserMutation();
    const [editUser, { error: errorEditUser }] = useEditUserMutation();

    const [invite, { isSuccess: inviteSuccess }] = useResendInviteMutation();

    useEffect(() => {
        if (type !== 'create' && openModal) {
            getUserTrigger(userId);
        }
    }, [type, openModal]);

    useEffect(() => {
        if (inviteSuccess) {
            messageApi.success('The invite has been resent successfully');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        }
    }, [inviteSuccess]);

    useEffect(() => {
        if (errorCreateUser) {
            if (
                (errorCreateUser as CustomErrorType)?.data?.errors?.email?.includes(
                    'Oops! It looks like that email is already in use'
                )
            ) {
                messageApi.error('Oops! It looks like that email is already in use');
            } else {
                messageApi.error((errorCreateUser as CustomErrorType)?.data?.message);
            }
        }
    }, [errorCreateUser]);

    useEffect(() => {
        if (!dataUser) return;
        form.setFieldsValue({
            first_name: dataUser.data.first_name ? dataUser.data.first_name : '',
            last_name: dataUser.data.last_name ? dataUser.data.last_name : '',
            email: dataUser.data.email
        });
        setRole(dataUser.data.role.name);
        setImageUrl(dataUser.data.avatar);
    }, [getUserSuccess, dataUser, form]);

    const handleFileChange = async (event: any) => {
        const maxFileSize = 5 * 1024 * 1024;
        const file = event.target.files[0];
        if (!file) return;
        const fileExtension = file.name.split('.').pop();

        let isValid = false;

        if (
            fileExtension === 'jpeg' ||
            fileExtension === 'jpg' ||
            fileExtension === 'png' ||
            fileExtension === 'heic'
        ) {
            isValid = true;
        }

        if (fileExtension === 'heic') {
            if (!isValid) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            } else if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            } else {
                const HeicUrl = await convertHEICToJPG(file);
                setImageUrl(URL.createObjectURL(HeicUrl));
                setFileAvatar(file);
                setFileErr('');
            }
        } else {
            if (!isValid) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            } else if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            } else {
                setChanged(true);
                setImageUrl(URL.createObjectURL(file));
                setFileAvatar(file);
                setFileErr('');
            }
        }
    };

    const clickUpload = () => {
        fileInputRef?.current?.click();
    };

    const convertHEICToJPG = async (heicFile: File) => {
        const res: any = await heic2any({
            blob: heicFile,
            toType: 'image/jpeg',
            quality: 0.5
        });
        const newFile = new File([res], `${heicFile.name}`, {
            lastModified: Math.floor(new Date().getTime() / 1000),
            type: 'image/jpeg'
        });
        return newFile;
    };

    const onChange = (e: RadioChangeEvent) => {
        setRole(e.target.value);
    };

    const createUserHandler = async (res: any) => {
        setLoading(true);
        try {
            await createUser(res).unwrap();
            await messageApi.success('User has been successfully created');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const editUserHandler = async (res: any) => {
        setLoading(true);
        try {
            await editUser(res);
            await messageApi.success('School user has been successfully edited');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        } catch {
            if (errorEditUser) {
                messageApi.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                if (type === 'create') {
                    const res = new FormData();
                    res.append('email', values.email.toLowerCase());
                    res.append('role', role);
                    values.first_name ? res.append('first_name', values.first_name) : res.append('first_name', '');
                    values.last_name ? res.append('last_name', values.last_name) : res.append('last_name', '');
                    if (fileAvatar) {
                        res.append('avatar', fileAvatar);
                    }
                    createUserHandler(res);
                }
                if (type === 'edit') {
                    const res = new FormData();
                    res.append('email', values.email.toLowerCase());
                    res.append('first_name', values.first_name);
                    res.append('last_name', values.last_name);
                    res.append('role', role);

                    if (!imageUrl) {
                        res.append('reset_avatar', '1');
                    } else {
                        if (fileAvatar) {
                            res.append('avatar', fileAvatar);
                        }
                    }
                    const formData = {
                        id: userId,
                        data: res
                    };
                    res.append('_method', 'PUT');
                    editUserHandler(formData);
                }
            })
            .catch((error) => {
                console.log('error');
            });
    };

    const resendInvite = () => {
        //@ts-ignore
        invite(userId).unwrap();
    };

    return (
        <>
            {contextHolder}
            <Modal
                className={type === 'view' || type === 'view_pending' ? 'view' : ''}
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched() || changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal({ open: false, type: '', id: null });
                    }
                }}
                closeIcon={<img src={close} />}
                width={600}
                destroyOnClose
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched() || changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal({ open: false, type: '', id: null });
                                }
                            }}
                            block
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button key="submit" type="primary" onClick={() => handleFormSubmit()} loading={loading} block>
                            {type === 'create' ? 'Create' : type === 'edit' ? 'Save Changes' : ''}
                        </Button>
                    </div>
                }
            >
                <Spin spinning={loading} size="large">
                    <div className="modal">
                        <div className="modal__header">
                            <div className="modal__title">
                                {type === 'create' ? 'Create User' : type === 'edit' ? 'Edit' : ''}
                            </div>
                            {(type === 'view' || type === 'view_pending') && (
                                <div
                                    className="modal__edit"
                                    onClick={() =>
                                        setOpenModal((prevState) => ({ ...prevState, type: 'edit', userId }))
                                    }
                                >
                                    <img src={editAdminIcon} />
                                    <div>Edit</div>
                                </div>
                            )}
                            <div className="modal__avatar-wrap">
                                {(type === 'create' ||
                                    type === 'edit' ||
                                    type === 'view' ||
                                    type === 'view_pending') && (
                                    <div className="modal__addbtn">
                                        {imageUrl && (type === 'create' || type === 'edit') && (
                                            <img
                                                src={RoundedWhite}
                                                className="modal__delete-avatar-btn"
                                                onClick={() => {
                                                    setChanged(true);
                                                    setImageUrl('');
                                                    setFileAvatar('');
                                                }}
                                            />
                                        )}
                                        <input
                                            type="file"
                                            accept=".jpg, .png, .heic"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                        />

                                        {imageUrl ? (
                                            <img src={imageUrl} alt="avatar" className="upload-image" />
                                        ) : (
                                            <img src={DefaultAvatar} />
                                        )}
                                        <div className="modal__addtext" onClick={clickUpload}>
                                            {type === 'create' && !imageUrl
                                                ? 'Add Photo'
                                                : (type === 'edit' && imageUrl) || (type === 'create' && imageUrl)
                                                ? 'Edit Photo'
                                                : type === 'edit' && !imageUrl
                                                ? 'Add Photo'
                                                : ''}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {(type === 'create' || type === 'edit') && (
                            <div className="modal__main main">
                                {type === 'edit' && (
                                    <div
                                        className={`main__role ${
                                            dataUser?.data?.role?.title === 'Tender Representative'
                                                ? 'role-tr'
                                                : dataUser?.data?.role?.title === 'Super Admin'
                                                ? 'role-admin'
                                                : dataUser?.data?.role?.title === 'Onboarding Representative'
                                                ? 'role-or'
                                                : ''
                                        }`}
                                        style={
                                            dataUser?.data?.role?.title === 'Tender Representative'
                                                ? { margin: '20px 0 16px 0', width: '170px' }
                                                : { margin: '20px 0 16px 0' }
                                        }
                                    >
                                        {dataUser?.data?.role?.title}
                                    </div>
                                )}
                                {fileErr && <div className="main__file-error">{fileErr}</div>}

                                <div className="main__subtitle">
                                    Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                                </div>
                                <Form form={form} onFinish={handleFormSubmit}>
                                    <div className="main__block">
                                        <div className="main__row">
                                            <Form.Item
                                                className="small-input-wrapper"
                                                name="first_name"
                                                rules={yupSync('first_name', validationSchema, true)}
                                            >
                                                <FloatInput floatLabel="First Name" className="input" maxLength={60} />
                                            </Form.Item>
                                            <Form.Item
                                                className="small-input-wrapper"
                                                name="last_name"
                                                rules={yupSync('last_name', validationSchema, true)}
                                            >
                                                <FloatInput floatLabel="Last Name" className="input" maxLength={60} />
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            className="input-wrapper"
                                            name="email"
                                            rules={yupSync('email', validationSchema, true)}
                                        >
                                            <FloatInput
                                                floatLabel="Email"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                                disabled={type === 'edit' ? true : false}
                                            />
                                        </Form.Item>
                                        {type === 'create' && (
                                            <Radio.Group
                                                name="radiogroup"
                                                defaultValue={'tender_representative'}
                                                className="main__radiogroup"
                                                onChange={onChange}
                                            >
                                                <Radio value={'tender_representative'} className="main__radio-btn">
                                                    Tender Representative
                                                </Radio>

                                                <Radio value={'super_admin'} className="main__radio-btn">
                                                    Super Admin
                                                </Radio>
                                                <Radio value={'onboarding_representative'} className="main__radio-btn">
                                                    Onboarding Representative
                                                </Radio>
                                            </Radio.Group>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        )}
                        {(type === 'view' || type === 'view_pending') && (
                            <div className="modal__main main">
                                <div className="main__mainInfo-wrap">
                                    <div className="main__fullName">
                                        {dataUser?.data?.full_name ? dataUser?.data?.full_name : '-'}
                                    </div>
                                    <div
                                        className={`main__role ${
                                            dataUser?.data?.role?.title === 'Tender Representative'
                                                ? 'role-tr'
                                                : dataUser?.data?.role?.title === 'Super Admin'
                                                ? 'role-admin'
                                                : dataUser?.data?.role?.title === 'Onboarding Representative'
                                                ? 'role-or'
                                                : ''
                                        }`}
                                    >
                                        {dataUser?.data?.role ? dataUser?.data?.role?.title : '-'}
                                    </div>
                                </div>
                                <div className="main__info">
                                    <img src={email} />
                                    <span>{dataUser?.data?.email ? dataUser?.data?.email : '-'}</span>
                                </div>

                                {type === 'view_pending' && (
                                    <button className="main__invite-btn" onClick={resendInvite}>
                                        Resend Invite
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </Spin>
            </Modal>
            <Modal
                className={type === 'view' || type === 'view_pending' ? 'view' : ''}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal({ open: false, type: '', id: null });
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
