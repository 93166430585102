import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import type { SelectProps } from 'antd';
import { useClickOutside } from '../../hooks/useClickOutSide';
import schoolImage from '../../assets/schoolImage.svg';
import arrow from '../../assets/arrow.svg';
import close from '../../assets/Close.svg';
import noResult from '../../assets/noResult.svg';
import defaultAvatar from '../../assets/DefaultAvatar.svg';
import InputSearch from './InputSearch';
import './index.scss';

interface FloatSelectProps extends SelectProps {
    required: boolean;
    floatLabel: string;
    err: boolean;
    setSearch: any;
    options: any;
    onChange: any;
    search: string;
    form: any;
    isSearch?: boolean;
    hasError: () => boolean;
    prefix: any;
}

export const CustomSelect = (props: FloatSelectProps) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    let {
        floatLabel,
        placeholder,
        value,
        required,
        err,
        setSearch,
        options,
        onChange,
        search,
        isSearch = true,
        form,
        disabled,
        prefix,
        hasError
    } = props;
    if (!placeholder) placeholder = floatLabel;

    useClickOutside(ref, () => setOpenSelect(false));

    const isOccupied = openSelect || value;

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    <img src={value ? '' : arrow} className={'image'} />;

    useEffect(() => {
        if (!openSelect) {
            setSearch('');
        }
    }, [openSelect]);

    return (
        <div className="float-label" ref={ref}>
            <div
                style={disabled ? { cursor: 'not-allowed' } : {}}
                className={`${
                    openSelect && !value
                        ? 'customSelect custom-select'
                        : openSelect && value
                        ? 'customSelec-with-value custom'
                        : 'input-select custom-select'
                } ${err ? 'err' : ''} ${disabled ? 'disabled-select' : ''} ${hasError() && !value ? 'err' : ''}`}
                onClick={() => {
                    if (!openSelect && !disabled) {
                        setOpenSelect(true);
                    }
                }}
            >
                {!disabled && (
                    <img
                        src={value ? close : arrow}
                        className={'image'}
                        onClick={(e) => {
                            if (disabled) return;
                            e.stopPropagation();
                            if (value) {
                                onChange({});
                                setOpenSelect(false);
                            } else {
                                setOpenSelect((prev) => !prev);
                            }
                        }}
                    />
                )}

                {prefix && <img src={prefix} className="prefix" />}
                {!value && (
                    <label className={labelClass}>
                        {isOccupied ? floatLabel : placeholder} {required && <span className="red">*</span>}
                    </label>
                )}
                {value && (
                    <div className={`select-option value`} style={disabled ? { cursor: 'not-allowed' } : {}}>
                        <div className="select-option__wrap">
                            <div className="select-option__name">{value}</div>
                        </div>
                    </div>
                )}

                {openSelect && (
                    <div>
                        {isSearch ? (
                            <Form.Item style={{ width: '95%', margin: '0 0 0 10px' }}>
                                <InputSearch handleChange={(value) => setSearch(value)} />
                            </Form.Item>
                        ) : (
                            ''
                        )}

                        <div className="customSelect__options">
                            {options?.length ? (
                                options?.map((elem: any) => {
                                    return (
                                        <div
                                            className="select-option custom-select"
                                            onClick={() => {
                                                setOpenSelect(false);
                                                onChange(elem);
                                            }}
                                        >
                                            <div className="select-option__wrap">
                                                <div className="select-option__name">{elem.name}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="customSelect__no-result">
                                    <img src={noResult} />
                                    <div>No results found</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
