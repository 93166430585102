export const SpinnerIcon = ({ fill, style }: { fill: string; style: any }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
        <path
            d="M5.625 6.875H2.5V3.75"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.22266 16.6033C6.97266 17.0375 7.80349 17.345 8.68849 17.5"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M2.90234 12.6442C3.20734 13.4808 3.64901 14.25 4.21068 14.9192"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.3125 17.5C12.1975 17.345 13.0275 17.0375 13.7783 16.6033"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.7891 14.9192C16.3507 14.25 16.7924 13.4808 17.0974 12.6442"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.4992 10C17.4992 5.8575 14.1417 2.5 9.99917 2.5C6.97417 2.5 4.37333 4.295 3.1875 6.875"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
