import React, { useRef, useState, useEffect } from 'react';
import { Layout } from 'antd';
import './index.scss';
import logo from '../../assets/Logo.png';
import { MenuProps, Menu } from 'antd';
import Rounded from '../../assets/Rounded.svg';
import arrow from '../../assets/arrow.svg';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../reducers/auth/authSlice';
import settings from '../../assets/settings.svg';
import logoutIcon from '../../assets/logout.svg';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { setProfileInfo } from '../../reducers/profile/profileSlice';
import { useGetMeQuery, useLazyGetMeQuery } from '../../api/users';
import defaultAvatar from '../../assets/DefaultAvatar.svg';
import DashboardIcon from '../Icons/DashboardIcon';
import AdminPanelIcon from '../Icons/AdminPanelIcon';
import PastTendersIcon from '../Icons/PastTendersIcon';
import MenuIcon from '../../assets/Menu.svg';

const { Header } = Layout;

export const Head = () => {
    const [current, setCurrent] = useState('');
    const [profileInfoPopup, setProfileInfoPopup] = useState<boolean>(false);
    const [profileInfoPopupPhone, setProfileInfoPopupPhone] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement | null>(null);
    const refBurger = useRef<HTMLDivElement | null>(null);
    const [trigger, { data: dataMe, isSuccess: isSuccessMe, isLoading: isLoadingMe }] = useLazyGetMeQuery();
    const location = useLocation();
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [burger, setBurger] = useState(false);
    const { role } = useAppSelector((state) => state.profileReducer);

    useEffect(() => {
        if (location.pathname.includes('dashboard')) {
            setCurrent('dashboard');
        }
        if (location.pathname.includes('admin-panel')) {
            setCurrent('admin-panel');
        }
        if (location.pathname.includes('past-tenders')) {
            setCurrent('past-tenders');
        }
    }, [location]);

    let items: MenuProps['items'] = [];

    role === 'super_admin' || role === 'onboarding_representative'
        ? (items = [
              {
                  label: 'Dashboard',
                  key: 'dashboard',
                  icon: <DashboardIcon style={{ fontSize: '20px' }} />
              },
              {
                  label: 'Past Tenders',
                  key: 'past-tenders',
                  icon: <PastTendersIcon style={{ fontSize: '20px' }} />
              },
              {
                  label: 'Admin Panel',
                  key: 'admin-panel',
                  icon: <AdminPanelIcon style={{ fontSize: '20px' }} />
              }
          ])
        : (items = [
              {
                  label: 'Dashboard',
                  key: 'dashboard',
                  icon: <DashboardIcon style={{ fontSize: '20px' }} />
              },
              {
                  label: 'Past Tenders',
                  key: 'past-tenders',
                  icon: <PastTendersIcon style={{ fontSize: '20px' }} />
              }
          ]);

    useEffect(() => {
        if (!location) return;
        if (location.pathname.includes('/tender')) {
            setCurrent('dashboard');
        }
    }, [location]);

    useEffect(() => {
        trigger();
    }, []);

    useClickOutside(ref, () => setProfileInfoPopup(false));
    useClickOutside(ref, () => setProfileInfoPopupPhone(false));
    useClickOutside(refBurger, () => setBurger(false));

    useEffect(() => {
        if (!dataMe) return;
        dispatch(
            setProfileInfo({
                id: dataMe.data.id,
                avatar: dataMe.data.avatar,
                email: dataMe.data.email,
                first_name: dataMe.data.first_name,
                last_name: dataMe.data.last_name,
                full_name: dataMe.data.full_name,
                job_title: dataMe.data.job_title,
                phone_number: dataMe.data.phone_number,
                school_id: dataMe.data.school_id,
                school: dataMe.data.school,
                role: dataMe.data.role.name,
                status: dataMe.data.status
            })
        );
    }, [dataMe]);

    const onClick: MenuProps['onClick'] = (e) => {
        navigate(`/${e.key}`);
        setCurrent(e.key);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    return (
        <Header className="header">
            <div className="header__menu-wrap" ref={refBurger}>
                {screenWidth < 880 && <img src={MenuIcon} className="header__burger" onClick={() => setBurger(true)} />}
                {burger && (
                    <div className="header__berger-menu">
                        <div
                            className="header__burger-item"
                            onClick={() => {
                                navigate('/dashboard');
                                setBurger(false);
                            }}
                        >
                            <DashboardIcon style={{ fontSize: '20px', margin: '0 10px 0 0' }} />
                            Dashboard
                        </div>
                        <div
                            className="header__burger-item"
                            onClick={() => {
                                navigate('/past-tenders');
                                setBurger(false);
                            }}
                        >
                            <PastTendersIcon style={{ fontSize: '20px', margin: '0 10px 0 0' }} />
                            Past Tenders
                        </div>
                    </div>
                )}
                <img src={logo} className="header__logo" onClick={() => navigate('/dashboard')} />
            </div>

            {screenWidth >= 880 && (
                <Menu
                    onClick={onClick}
                    mode="horizontal"
                    items={items}
                    selectedKeys={[current]}
                    className="header__menu"
                />
            )}

            <div className="header__user-data user-data">
                <img src={Rounded} className="user-data__notif" />
                <img
                    src={dataMe?.data?.avatar ? dataMe?.data?.avatar : defaultAvatar}
                    className={`user-data__avatar phone ${profileInfoPopupPhone ? 'active' : ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setProfileInfoPopupPhone((prev) => !prev);
                    }}
                />
                <div
                    className={profileInfoPopup ? 'user-data__wrap open' : 'user-data__wrap'}
                    onClick={() => setProfileInfoPopup((prev) => !prev)}
                    ref={ref}
                >
                    <div style={{ display: 'flex' }}>
                        <img
                            src={dataMe?.data?.avatar ? dataMe?.data?.avatar : defaultAvatar}
                            className="user-data__avatar"
                        />
                        <div className="user-data__data">
                            <div className="user-data__name">{dataMe?.data?.full_name}</div>
                            <div className="user-data__role">
                                {dataMe?.data?.role?.title === 'Onboarding Representative'
                                    ? 'Onboarding Repres.'
                                    : dataMe?.data?.role?.title}
                            </div>
                        </div>
                        <img src={arrow} className="user-data__arrow" />
                    </div>
                    {profileInfoPopup && (
                        <>
                            <div className="user-data__item" onClick={() => navigate('/profile')}>
                                <img src={settings} />
                                <span>Manage Profile</span>
                            </div>
                            <div className="user-data__item" onClick={handleLogout}>
                                <img src={logoutIcon} />
                                <span>Log Out</span>
                            </div>
                        </>
                    )}
                </div>
                {profileInfoPopupPhone && (
                    <div className="user-data-phone">
                        <div className="user-data-phone__wrap">
                            <img
                                src={dataMe?.data?.avatar ? dataMe?.data?.avatar : defaultAvatar}
                                className="user-data-phone__avatar"
                            />
                            <div className="user-data-phone__data">
                                <div className="user-data-phone__name">{dataMe?.data?.full_name}</div>
                                <div className="user-data-phone__role">{dataMe?.data?.role?.title}</div>
                            </div>
                        </div>
                        <>
                            <div className="user-data__item" onClick={() => navigate('/profile')}>
                                <img src={settings} />
                                <span>Manage Profile</span>
                            </div>
                            <div className="user-data__item" onClick={handleLogout}>
                                <img src={logoutIcon} />
                                <span>Log Out</span>
                            </div>
                        </>
                    </div>
                )}
            </div>
        </Header>
    );
};
