import { useState, FC, useEffect } from 'react';
import { Button, Modal, Tooltip, message } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { CreateMeetingModal } from '../../Modals/CreateMeetingModal';
import { Loader } from '../../Loader/Loader';
import { useDeclineTaskMutation, useUpdateTaskDateMutation } from '../../../api/tenders';

import SupplierReportCardPublishDateIcon from '../../../assets/Supplier Report Card Publish Date.svg';
import plus from '../../../assets/Plus, Add.svg';
import calendar from '../../../assets/calendar-chedule.svg';
import clock from '../../../assets/clock.svg';
import pin from '../../../assets/pinIcon.svg';
import edit from '../../../assets/editAdminIcon.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import stopImage from '../../../assets/stopImage.svg';
import done from '../../../assets/Done, Check.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import { useAppSelector } from '../../../hooks';
import { TaskDeadlineModal } from '../../Modals/TaskDeadline';
import moment from 'moment';

interface DefineMeetingTaskProps {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SupplierReportCardPublishDate: FC<DefineMeetingTaskProps> = ({ data, setTrigger }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [messageApi, contextHolder] = message.useMessage();
    const [SelectedDate, setSelectedDate] = useState<Date | string>(
        data?.tasks_deadline_at ? new Date(data?.tasks_deadline_at) : new Date()
    );
    const [taskId, setTaskId] = useState<string | null | number>(null);
    const { role } = useAppSelector((state) => state.profileReducer);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [updateDeadline, { isLoading }] = useUpdateTaskDateMutation();

    useEffect(() => {
        if (data.phase_tasks[0].date) {
            setSelectedDate(data.phase_tasks[0].date);
        }
    }, [data.phase_tasks[0].date]);

    const updateDeadlineFunc = async () => {
        try {
            const formData = {
                tender_id: data.id,
                task_id: taskId,
                data: { date: moment(SelectedDate).format('YYYY-MM-DD') }
            };
            await updateDeadline(formData).unwrap();
            await setOpenModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {contextHolder}
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'evaluate_4_publish_date') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.date) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task task ${elem.status !== 'pending_tr' ? 'green' : ''}`}
                                    >
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierReportCardPublishDateIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role === 'tender_representative' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setOpenModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if ((elem.status === 'pending_tr' || elem.status === 'need_resend_tr') && elem.date) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierReportCardPublishDateIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Date</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">
                                                    <img src={calendar} />
                                                    {moment(elem.date).format('YYYY-MM-DD')}
                                                </div>
                                            </div>

                                            <img
                                                src={edit}
                                                className="task-complete__edit"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setOpenModal(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {openModal && (
                <TaskDeadlineModal
                    title={'Rep. Card Publish Date'}
                    buttonText="Confirm"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    SelectedDate={SelectedDate}
                    setSelectedDate={setSelectedDate}
                    updateDeadlineFunc={updateDeadlineFunc}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};
