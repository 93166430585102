import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '../api/auth';
import { usersApi } from '../api/users';
import { setupListeners } from '@reduxjs/toolkit/query';
import authReducer from './auth/authSlice';
import profileReducer from './profile/profileSlice';
import { schoolsApi } from '../api/schools';
import { commonApi } from '../api/common';
import { profileApi } from '../api/profile';
import { tendersApi } from '../api/tenders';

export const store = configureStore({
    reducer: {
        [authReducer.name]: authReducer.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [schoolsApi.reducerPath]: schoolsApi.reducer,
        [commonApi.reducerPath]: commonApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [tendersApi.reducerPath]: tendersApi.reducer,
        profileReducer: profileReducer.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(usersApi.middleware)
            .concat(schoolsApi.middleware)
            .concat(commonApi.middleware)
            .concat(profileApi.middleware)
            .concat(tendersApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
