import React, { FC, useState, useRef, useEffect } from 'react';
import RoundedWhite from '../../../assets/RoundedWhite.svg';
import DefaultAvatar from '../../../assets/DefaultAvatar.svg';
import { Button, Form, Modal, Spin, message, Tooltip } from 'antd';
import { FloatInput } from '../../Fields/FloatInput';
import * as Yup from 'yup';
import { yupSync } from '../../../utils';
import { PhoneNumberInput } from '../../Fields/PhoneNumberInput';
import { FloatSelect } from '../../Fields/FloatSelect';
import { useAppSelector } from '../../../hooks';
import { useEditProfileMutation } from '../../../api/profile';
import { Loader } from '../../Loader/Loader';
import { setProfileInfo } from '../../../reducers/profile/profileSlice';
import { useDispatch } from 'react-redux';
import Edit from '../../Icons/Edit';
import { useLazyGetMeQuery } from '../../../api/users';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import './index.scss';
import { CustomErrorType } from '../../../api/types/global';
import { useLazyGetSchoolsQuery } from '../../../api/schools';

interface ProfileInfoProps {
    setType: React.Dispatch<React.SetStateAction<string | null>>;
    onOpen: () => void;
    open: boolean;
    disabled: boolean;
}

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('This is a required field'),
    last_name: Yup.string().required('This is a required field'),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('This is a required field'),
    school: Yup.object().required('This is a required field'),
    job: Yup.string().required('This is a required field'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field')
});

export const ProfileInfo: FC<ProfileInfoProps> = ({ setType, onOpen, open, disabled = false }) => {
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [fileAvatar, setFileAvatar] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [changed, setChanged] = useState<boolean>(false);
    const [fileErr, setFileErr] = useState('');
    const userData = useAppSelector((state: any) => state.profileReducer);
    const [role, setRole] = useState<string | null>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmModal, setConfirmModal] = useState(false);
    const dispatch = useDispatch();
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    const [trigger, setTrigger] = useState<boolean>(false);

    const [triggerGetMe, { data: dataMe, isSuccess: isSuccessMe }] = useLazyGetMeQuery();

    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);

    const [updateProfile, { data: updateData, isSuccess, error }] = useEditProfileMutation();

    useEffect(() => {
        if (isSuccessMe) {
            dispatch(
                setProfileInfo({
                    id: dataMe.data.id,
                    avatar: dataMe.data.avatar,
                    email: dataMe.data.email,
                    first_name: dataMe.data.first_name,
                    last_name: dataMe.data.last_name,
                    full_name: dataMe.data.full_name,
                    job_title: dataMe.data.job_title,
                    phone_number: dataMe.data.phone_number,
                    school_id: dataMe.data.school_id,
                    school: dataMe.data.school,
                    role: dataMe.data.role.name,
                    status: dataMe.data.status
                })
            );
            form.setFieldsValue({
                //@ts-ignore
                first_name: dataMe.data.first_name,
                last_name: dataMe.data.last_name,
                email: dataMe.data.email,
                school: dataMe.data.school,
                job: dataMe.data.job_title,
                phone_number: dataMe.data.phone_number
            });
            setRole(dataMe.data?.role?.name);
            setImageUrl(dataMe.data?.avatar);
        }
    }, [isSuccessMe, trigger]);

    useEffect(() => {
        if (!role) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [role]);

    useEffect(() => {
        if (!userData) return;
        form.setFieldsValue({
            //@ts-ignore
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            school: userData.school,
            job: userData.job_title,
            phone_number: userData.phone_number
        });
        setRole(userData?.role);
        setImageUrl(userData?.avatar);
    }, [userData]);

    const handleFileChange = (event: any) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg'];
        const maxFileSize = 5 * 1024 * 1024;
        const file = event.target.files[0];

        if (file && allowedTypes?.includes(file?.type) && file.size < maxFileSize) {
            setChanged(true);
            setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFileAvatar(event.target.files[0]);
            setFileErr('');
        } else {
            if (!allowedTypes?.includes(file?.type)) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            }
            if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            }
            event.target.value = '';
        }
    };

    const clickUpload = () => {
        fileInputRef?.current?.click();
    };

    useEffect(() => {
        if (isSuccess) {
            // @ts-ignore
            messageApi.success(updateData?.message);
        }
    }, [updateData, isSuccess]);

    const handleUpdate = async (dataForm: any) => {
        setLoading(true);
        try {
            await updateProfile(dataForm).unwrap();
            await triggerGetMe();
            await setTrigger((prev) => !prev);
            await setType(null);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!error) return;
        messageApi?.error((error as CustomErrorType)?.data?.message);
    }, [error]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const formData = new FormData();
                formData.append('_method', 'PUT');
                formData.append('first_name', values?.first_name.trim());
                formData.append('last_name', values?.last_name.trim());
                formData.append('email', values?.email.trim().toLowerCase());
                if (role === 'school_user') {
                    formData.append('phone_number', values?.phone_number);
                    formData.append('job_title', values?.job.trim());
                }
                if (!imageUrl) {
                    formData.append('reset_avatar', '1');
                } else {
                    if (fileAvatar) {
                        formData.append('avatar', fileAvatar);
                    }
                }
                handleUpdate(formData);
            })
            .catch((err) => {});
    };

    const handleClose = async () => {
        setLoading(true);
        try {
            await setType(null);
            await triggerGetMe();
            await setTrigger((prev) => !prev);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Spin spinning={loading} size="large">
                <div className="profile">
                    <div className="profile__block block">
                        <div className="block__title">Profile info</div>
                    </div>
                    <div className="profile__block block">
                        <div className="block__form form">
                            <div className="form__avatar-wrap">
                                <div className="form__addbtn">
                                    {imageUrl && open && (
                                        <img
                                            src={RoundedWhite}
                                            className="form__delete-avatar-btn"
                                            onClick={() => {
                                                setChanged(true);
                                                setImageUrl('');
                                                setFileAvatar('');
                                            }}
                                        />
                                    )}
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .heic"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />

                                    {imageUrl ? (
                                        <img src={imageUrl} alt="avatar" className="upload-image" />
                                    ) : (
                                        <img src={DefaultAvatar} />
                                    )}

                                    <div className="form__addtext" onClick={clickUpload}>
                                        {open && !imageUrl && 'Add Photo'}
                                        {imageUrl && open && 'Edit Photo'}
                                    </div>
                                </div>
                            </div>
                            {open && (
                                <div className="form__main main">
                                    {fileErr && <div className="main__file-error">{fileErr}</div>}

                                    <Form form={form} onFinish={() => {}} validateTrigger="onBlur">
                                        <div className="main__block">
                                            <div className="main__row">
                                                <Form.Item
                                                    className={`${
                                                        screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                    }`}
                                                    name="first_name"
                                                    rules={yupSync('first_name', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="First Name"
                                                        className="input"
                                                        maxLength={60}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className={`${
                                                        screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                    }`}
                                                    name="last_name"
                                                    rules={yupSync('last_name', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Last Name"
                                                        className="input"
                                                        maxLength={60}
                                                    />
                                                </Form.Item>
                                            </div>
                                            {role === 'school_user' && (
                                                <>
                                                    <Form.Item
                                                        className="input-wrapper"
                                                        name="phone_number"
                                                        rules={yupSync('phone_number', validationSchema, true)}
                                                    >
                                                        <PhoneNumberInput
                                                            floatLabel="Phone Number"
                                                            className="input"
                                                            maxLength={256}
                                                            value={userData?.phone_number}
                                                            placeholder={undefined}
                                                            required={false}
                                                            onChange={() => {}}
                                                            hasError={() => !!form.getFieldError('phone_number').length}
                                                            onBlur={() =>
                                                                yupSync('phone_number', validationSchema, true)
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className="input-wrapper"
                                                        name="job"
                                                        rules={yupSync('job', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Job Title"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )}
                                            <Form.Item
                                                className="input-wrapper"
                                                name="email"
                                                rules={yupSync('email', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Email"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                    disabled={false}
                                                />
                                            </Form.Item>
                                        </div>
                                        {role === 'school_user' && (
                                            <div className="main__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="school"
                                                    rules={yupSync('school', validationSchema, true)}
                                                >
                                                    <FloatSelect
                                                        search={''}
                                                        floatLabel="School"
                                                        className="input-select"
                                                        required={false}
                                                        err={false}
                                                        setSearch={() => {}}
                                                        options={[]}
                                                        onChange={() => {}}
                                                        value={''}
                                                        form={form}
                                                        hasError={() => !!form.getFieldError(['school']).length}
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            </div>
                                        )}
                                        <div className="main__block"></div>
                                    </Form>
                                    {open && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyItems: 'space-between',
                                                gap: '16px',
                                                width: '100%',
                                                margin: '40px 0 0 0'
                                            }}
                                        >
                                            <Button
                                                block
                                                key="back"
                                                onClick={() => {
                                                    if (form.isFieldsTouched() || changed) {
                                                        setConfirmModal(true);
                                                    } else {
                                                        handleClose();
                                                    }
                                                }}
                                                className="cancel"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                block
                                                key="submit"
                                                type="primary"
                                                onClick={() => handleFormSubmit()}
                                            >
                                                {loading ? <Loader /> : 'Save Changes'}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!open && (
                                <div className="form__main main">
                                    <div className="main__fullName">
                                        {userData?.full_name ? userData?.full_name : '-'}
                                        {(role === 'super_admin' ||
                                            role === 'tender_representative' ||
                                            role === 'onboarding_representative') && (
                                            <div
                                                className={`main__role ${
                                                    userData?.role && userData?.role === 'super_admin'
                                                        ? 'role-admin'
                                                        : userData?.role === 'tender_representative'
                                                        ? 'role-tr'
                                                        : userData?.role === 'onboarding_representative'
                                                        ? 'role-or'
                                                        : ''
                                                }`}
                                            >
                                                {userData?.role && userData?.role === 'super_admin'
                                                    ? 'Super Admin'
                                                    : userData?.role === 'tender_representative'
                                                    ? 'Tender Representative'
                                                    : userData?.role === 'onboarding_representative'
                                                    ? 'Onboarding Representative'
                                                    : ''}
                                            </div>
                                        )}
                                    </div>

                                    {role === 'school_user' && (
                                        <>
                                            <div className="main__item item">
                                                <div className="item__title">Phone Number</div>
                                                <div className="item__value">{userData?.phone_number}</div>
                                            </div>
                                            <div className="main__item item">
                                                <div className="item__title">Job title</div>
                                                <div className="item__value">{userData?.job_title}</div>
                                            </div>
                                        </>
                                    )}

                                    <div
                                        className="main__item item"
                                        style={role !== 'school_user' ? { border: 'none' } : {}}
                                    >
                                        <div className="item__title">Email</div>
                                        <div className="item__value">{userData?.email}</div>
                                    </div>
                                    {role === 'school_user' && (
                                        <>
                                            <div className="select-option value" style={{ margin: '24px 0' }}>
                                                <div className="select-option__img">
                                                    <img src={userData?.school?.preview} />
                                                </div>
                                                <div className="select-option__wrap">
                                                    <div className="select-option__name">{userData?.school.name}</div>
                                                    <div className="select-option__city">
                                                        {userData?.school.address}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <Tooltip
                                        title="To change the profile info, you need to finish editing the password"
                                        overlayClassName={` ${disabled ? 'custom-tooltip-disabled' : 'custom-tooltip'}`}
                                    >
                                        <Button
                                            onClick={() => onOpen()}
                                            icon={<Edit style={{ fontSize: '50px', marginTop: '0px' }} />}
                                            disabled={disabled}
                                            style={screenWidth < 834 ? { width: '100%' } : {}}
                                        >
                                            Edit Personal Info
                                        </Button>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="profile__block block"></div>
                </div>
            </Spin>
            <Modal
                className="confirmModal"
                centered
                open={confirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                handleClose();
                                setConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
