import { Button, Form, message } from 'antd';
import { useState } from 'react';
import logo from '../../../assets/logo.svg';
import emailIcon from '../../../assets/letter-mail-notification-green.svg';
import timerIcon from '../../../assets/clock-time-timer-fast.svg';
import arrow from '../../../assets/arrow-left.svg';
import * as Yup from 'yup';
import { yupSync } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useForgotPasswordMutation } from '../../../api/auth';
import CountdownTimer from '../../../components/CountdownTimer';
import '../index.scss';
import { FloatInput } from '../../../components/Fields/FloatInput';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import moment from 'moment';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter your email: e.g. email@domain.com').max(256).required('Email is required')
});

export const ForgotPassword = () => {
    const [form] = Form.useForm<{ email: string }>();
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [forgotPassword, { data, isSuccess, isLoading, originalArgs }] = useForgotPasswordMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const { handleKeyPress } = usePreventSpaceTyping();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await forgotPassword(values.email || originalArgs || '').unwrap();
            setIsButtonDisabled(true);
        } catch (e: any) {
            if (e?.status === 429) {
                navigate('/forgot-password/limit-exceeded');
            } else {
                // messageApi.error('Something went wrong, please try again later');
            }
        }
    };

    return (
        <>
            {contextHolder}
            <div className="auth">
                <div className="auth__logo-container">
                    <img src={logo} className="auth__logo" alt="logo" />
                </div>
                {isSuccess && data ? (
                    <div className="auth__form form forgot-form_success">
                        <img
                            src={emailIcon}
                            className="form__title_icon"
                            alt="email icon success"
                            style={{ width: '52px', height: '52px', padding: '11px' }}
                        />
                        <div className="form__title">Check your email</div>

                        <div className="form__subtitle">
                            <p>
                                If the email address{' '}
                                <span style={{ color: '#001965' }}>{`“${form
                                    .getFieldValue('email')
                                    ?.toLowerCase()}”`}</span>{' '}
                                is associated with an account, you will receive the email with the password recovery
                                link.
                            </p>
                            <p>
                                Click the link in the email to reset your password. If you can’t find the email, check
                                your spam folder.
                            </p>
                        </div>
                        <div className="form__timer">
                            {isButtonDisabled ? (
                                <>
                                    <img src={timerIcon} alt="timer" />
                                    <span>
                                        <CountdownTimer duration={60} callback={() => setIsButtonDisabled(false)} />
                                    </span>
                                </>
                            ) : (
                                <div style={{ height: '20px' }} />
                            )}
                        </div>
                        <Button
                            type="primary"
                            className={`${isButtonDisabled ? 'disabled' : ''}`}
                            onClick={handleSubmit}
                            disabled={isButtonDisabled}
                            loading={isLoading}
                            block
                        >
                            Resend Email
                        </Button>
                        <div className="auth__back" onClick={() => navigate('/login')}>
                            <img src={arrow} alt="back to Log in" /> Back to Log in
                        </div>
                    </div>
                ) : (
                    <div className="auth__form form forgot-form">
                        <div className="form__title">Reset your password</div>
                        <Form form={form} className="form">
                            <div className="form__subtitle">
                                <p>Type in your email and we'll send you a link to reset your password</p>
                            </div>
                            <Form.Item
                                className="input-wrapper"
                                rules={yupSync('email', validationSchema, true)}
                                name="email"
                            >
                                <FloatInput
                                    className="input"
                                    floatLabel="Email"
                                    maxLength={256}
                                    onKeyPress={handleKeyPress}
                                />
                            </Form.Item>
                            <Button
                                type="primary"
                                className="auth__send_email"
                                onClick={handleSubmit}
                                loading={isLoading}
                                block
                            >
                                Send Reset Email
                            </Button>
                            <div className="auth__back" onClick={() => navigate('/login')}>
                                <img src={arrow} alt="back icon" /> Back to Log in
                            </div>
                        </Form>
                    </div>
                )}
                <div className="auth__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
            </div>
        </>
    );
};
