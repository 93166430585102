import React from 'react';
import { Badge, BadgeProps } from 'antd';
import { colors } from '../../themes/colors';
import './index.scss';

enum EnumUserStatus {
    Active = 'active',
    Pending = 'pending'
}

type StatusMap = {
    [key in EnumUserStatus]: { label: BadgeProps['status']; color: BadgeProps['color']; text: BadgeProps['text'] };
};

const UserStatus = ({ status, text }: { status: EnumUserStatus; text: React.ReactNode }) => {
    const statusMap: StatusMap = {
        [EnumUserStatus.Active]: {
            label: 'success',
            color: colors.Primary.Dark,
            text: <span className="status_success">{text}</span>
        },
        [EnumUserStatus.Pending]: {
            label: 'default',
            color: colors.Texts_and_Icons.Label,
            text: <span className="status_pending">{text}</span>
        }
    };
    return <Badge status={statusMap[status].label} text={statusMap[status].text} color={statusMap[status].color} />;
};

export default UserStatus;
