import axios from 'axios';
import service from './service';

export interface PropsToGet {
    chat_id: number;
    page: number;
}

export interface PropsToSetToRead {
    chat_id: number;
    message_id: number;
}

export interface MessageFormData {
    append(name: string, value: string): void;
    message: string;
}

export interface PropsToSendMessage {
    data: MessageFormData;
    chat_id: number;
}

export interface MessageI {
    attachment: {
        collection_name: string;
        mime_type: string;
        name: string;
        size: number;
        uuid: string;
    } | null;
    created_at: Date;
    id: number;
    is_unread: boolean;
    message: string;
    type: null;
    user: { avatar_thumb: string; full_name: string; id: number; initials: string; role: string };
    user_id: number;
}

export interface MessagesResponse {
    data: {
        data: MessageI[];
        meta: {
            last_page: number;
        };
    };
}

export const getMessages = (params: PropsToGet): Promise<MessagesResponse> => {
    return service.get(`/chats/${params.chat_id}/messages${params.page ? `?page=${params.page}` : ''}`);
};

export const setToRead = (data: PropsToSetToRead, id: string) => {
    return axios({
        method: 'post',
        url: `${baseUrl}/chats/${data.chat_id}/messages/${data.message_id}/set-as-read`,
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            Accept: 'application/json',
            'X-Socket-ID': id
        }
    });
};

export const sendMassage = (data: PropsToSendMessage, id: string) => {
    return axios({
        method: 'post',
        url: `${baseUrl}/chats/${data.chat_id}/messages`,
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            Accept: 'application/json',
            'X-Socket-ID': id
        },
        data: data.data
    });
};

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.thehive.schooladvisor.co.za/api/v1';

export const downloadFile = (uuid: string) => {
    return axios({
        method: 'get',
        url: `${baseUrl}/file-manager/download-single/${uuid}`,
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            'Content-Type': 'application/json'
        }
    });
};

export const AuthSocket = (data: any, id: string) => {
    return axios({
        method: 'post',
        url: `${baseUrl}/broadcasting/auth`,
        headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}')?.authToken
            }`,
            Accept: 'application/json',
            'X-Socket-ID': id
        },
        data: data
    });
};
