import RoundedPin from '../../assets/RoundedPin.svg';
import defaultAvatar from '../../assets/DefaultAvatar.svg';
import { SendMessage } from '../Icons/SendMessage';
import { FloatInput } from '../Fields/FloatInput';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import {
    AuthSocket,
    MessageI,
    PropsToGet,
    PropsToSendMessage,
    downloadFile,
    getMessages,
    sendMassage,
    setToRead
} from '../../api/chat';
import './Messenger.scss';
import { useAppSelector } from '../../hooks';
import RoundedArrow from '../../assets/RoundedArrow.svg';

import unread from '../../assets/Readed=No.svg';
import read from '../../assets/Readed=Yes.svg';
import moment from 'moment';
import RecivedFileWhite from '../../assets/RecivedFileWhite.svg';
import RecivedFile from '../../assets/ReceivedFile.svg';
import { SendFileModal } from '../Modals/SendFileModal';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import settingIcon from '../../assets/settingsIcon.svg';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface Props {
    chatId: number;
    messageApi: MessageApi;
    tenderId: string;
    tenderData: tenderDataProps;
}

declare global {
    interface Window {
        Pusher: any;
    }
}

export const Messenger: FC<Props> = ({ chatId, messageApi, tenderId, tenderData }) => {
    const [data, setData] = useState<MessageI[]>([]);
    const [isOpenFileModal, setIsOpenFileModal] = useState(false);
    const [text, setText] = useState('');
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState<number>(1);
    const [hasScrolledPastThreshold, setHasScrolledPastThreshold] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [showScrollToBottomArrow, setShowScrollToBottomArrow] = useState(false);
    const [socketId, setSocketId] = useState('');
    const [echo, setEcho] = useState<any>(null);
    const [lastId, setLastId] = useState<number>();
    const [lastReadId, setLastReadId] = useState<number>();

    const { role } = useAppSelector((state) => state.profileReducer);

    window.Pusher = Pusher;

    useEffect(() => {
        if (role === 'onboarding_representative') return;
        if (!chatId) return;
        const echoInstance = new Echo({
            broadcaster: 'pusher',
            authEndpoint: `${process.env.REACT_APP_API_BASE_URL}/broadcasting/auth`,
            key: process.env.REACT_APP_PUSHER_APP_KEY,
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            encrypted: process.env.REACT_APP_PUSHER_APP_ENCRYPTED,
            wsHost: process.env.REACT_APP_PUSHER_APP_HOST,
            wsPort: process.env.REACT_APP_PUSHER_APP_PORT ?? 80,
            wssPort: process.env.REACT_APP_PUSHER_APP_PORT ?? 443,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            authorizer: (channel: any) => {
                return {
                    authorize: (socketId: string, callback: any) => {
                        const data = {
                            socket_id: socketId,
                            channel_name: channel.name,
                            'X-Socket-ID': socketId
                        };
                        AuthSocket(data, socketId).then((response) => {
                            callback(false, response.data);
                        });
                        setSocketId(socketId);
                    }
                };
            }
        });

        setEcho(echoInstance);
    }, [chatId]);

    useEffect(() => {
        if (echo) {
            echo.private(`chat.${chatId}`)
                .listen('.store_message', (messages: any) => {
                    if (messages?.message?.type === 2 && role === 'school_user') return;
                    setData((prevData) => [messages.message, ...prevData]);
                    const id: number = messages?.message?.id;
                    if (id && messages?.message?.user?.role !== role) {
                        setLastId(id);
                    }
                })
                .listen('.read_messages', (resault: any) => {
                    const id = resault?.last_message_id;
                    if (!id) return;
                    setLastReadId(id);
                });
        }
    }, [echo]);

    // get messages
    useEffect(() => {
        if (!tenderId) return;
        getMessagesFunc();
    }, [tenderId, page]);

    let sending = false;

    //send messages
    const send = async () => {
        if (sending || !text.trim()) return;

        sending = true;

        const messageData: any = new FormData();
        messageData.append('message', text);
        const dataToPost: PropsToSendMessage = {
            data: messageData,
            chat_id: +tenderId
        };
        try {
            const res = await sendMassage(dataToPost, socketId);
            setData((prevData) => [res.data.data, ...prevData]);
            await setText('');
        } catch {
        } finally {
            sending = false;
        }
    };

    //trigger for set to read
    useEffect(() => {
        if (lastId && socketId) {
            setToReadFunc(lastId);
        }
    }, [lastId, socketId]);

    //trigger for set read messages
    useEffect(() => {
        if (lastReadId) {
            const copy = [...data];
            if (!copy.length || !lastReadId) return;
            const newArray = copy.map((elem: any) => {
                if (elem.id <= lastReadId) {
                    return {
                        ...elem,
                        is_unread: false
                    };
                } else {
                    return elem;
                }
            });
            setData(newArray);
        }
    }, [lastReadId]);

    const setToReadFunc = async (id: number) => {
        try {
            const dataToPost = {
                chat_id: +tenderId,
                message_id: id
            };
            setToRead(dataToPost, socketId);
        } catch {}
    };

    const getMessagesFunc = async () => {
        if (role === 'onboarding_representative') return;
        if (lastPage < page) return;
        const messageData: PropsToGet = {
            page,
            chat_id: +tenderId
        };
        try {
            const messages = await getMessages(messageData);
            setData((prevData) => [...prevData, ...messages?.data?.data]);
            const lastPage: number = messages?.data?.meta?.last_page;
            setLastPage(lastPage);
            const id: number = messages?.data?.data?.[0]?.id;
            setLastId(id);
        } catch {}
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        setShowScrollToBottomArrow(false);

        const container = e.currentTarget;
        const containerTotalHeight = container.scrollHeight - 540;
        const isScrolledToTop = containerTotalHeight + container.scrollTop;
        const isScrollable = container.scrollHeight > container.clientHeight;

        if (container.scrollTop === 0) {
            setShowScrollToBottomArrow(false);
        } else {
            setShowScrollToBottomArrow(true);
        }

        if (isScrolledToTop < 150 && isScrollable && !hasScrolledPastThreshold) {
            setPage((prevPage) => prevPage + 1);
            setHasScrolledPastThreshold(true);
        } else if (isScrolledToTop >= 150) {
            setHasScrolledPastThreshold(false);
        }
    };

    const scrollToBottom = () => {
        const container = document.getElementById('messageContainer');
        if (container) {
            container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    const formatDate = (date: Date) => {
        const utcDate = moment.utc(date);

        const localDate = utcDate.local();

        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');

        if (localDate.isSame(today, 'day')) {
            return `Today, ${localDate.format('HH:mm')}`;
        } else if (localDate.isSame(yesterday, 'day')) {
            return `Yesterday, ${localDate.format('HH:mm')}`;
        } else {
            const currentYear = moment().year();
            const dateYear = localDate.year();

            if (currentYear !== dateYear) {
                return localDate.format('DD MMMM YYYY, HH:mm');
            } else {
                return localDate.format('DD MMMM, HH:mm');
            }
        }
    };

    const setRole = (name: string) => {
        if (name === 'super_admin') {
            return 'Admin';
        }
        if (name === 'tender_representative') {
            return 'Tender Rep.';
        }
        if (name === 'school_user') {
            return 'School';
        }
    };

    const classNameForRole = (name: string) => {
        if (name === 'super_admin') {
            return 'yellow';
        }
        if (name === 'tender_representative') {
            return 'green';
        }
        if (name === 'school_user') {
            return 'red';
        }
    };

    const downloadFunc = (uuid: string) => {
        downloadFile(uuid)
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                let blob = new Blob([response.data], {
                    type: response.headers['content-type']
                });

                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                let fileName = name.replace(/"/g, '').trim();
                link.download = fileName;
                link.target = '_blank';

                const el = document.body;
                el.appendChild(link);

                link.click();
                messageApi.success('File has been downloaded successfully');
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            send();
        }
    };

    return (
        <>
            <div className={`tendermain__messenger tendermain__block messenger `}>
                <div className="messenger__header">
                    <div className="messenger__title">Messenger</div>
                </div>
                {role === 'onboarding_representative' ? (
                    <div className="messenger__no-data">
                        <img src={settingIcon} />
                        This feature is in development
                    </div>
                ) : (
                    <>
                        <div className="messenger__main main" onScroll={handleScroll} id="messageContainer">
                            <div className="main__content" id="messageContainer">
                                {showScrollToBottomArrow && (
                                    <img
                                        src={RoundedArrow}
                                        className="scroll-to-bottom-arrow"
                                        onClick={scrollToBottom}
                                    />
                                )}

                                {data?.map((elem: MessageI, index: number) => {
                                    if (elem.user.role === role) {
                                        return (
                                            <div className="main__me me" key={index}>
                                                <div className="me__text">
                                                    {elem.attachment?.name && (
                                                        <div
                                                            className="me__file"
                                                            onClick={() => {
                                                                downloadFunc(
                                                                    elem?.attachment?.uuid ? elem?.attachment?.uuid : ''
                                                                );
                                                            }}
                                                        >
                                                            <img src={RecivedFileWhite} />
                                                            {elem.attachment?.name}
                                                        </div>
                                                    )}
                                                    {elem.message}
                                                    <div className="me__time">
                                                        {formatDate(elem.created_at)}{' '}
                                                        {elem.is_unread || elem.is_unread === null ? (
                                                            <img src={unread} />
                                                        ) : (
                                                            <img src={read} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="main__other other" key={index}>
                                                <div className="other__avatar">
                                                    <img
                                                        src={
                                                            elem.user.avatar_thumb
                                                                ? elem.user.avatar_thumb
                                                                : defaultAvatar
                                                        }
                                                    />
                                                </div>
                                                <div className="other__text">
                                                    <div className={classNameForRole(elem.user.role)}>
                                                        {elem.user.full_name} ({setRole(elem.user.role)})
                                                    </div>
                                                    {elem.attachment?.name && (
                                                        <div
                                                            className="me__file"
                                                            onClick={() => {
                                                                downloadFunc(
                                                                    elem?.attachment?.uuid ? elem?.attachment?.uuid : ''
                                                                );
                                                            }}
                                                        >
                                                            <img src={RecivedFile} />
                                                            {elem.attachment?.name}
                                                        </div>
                                                    )}
                                                    {elem.message}
                                                    <div className="other__time">{formatDate(elem.created_at)} </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        {tenderData?.status !== 'archive' && (
                            <div className="messenger__footer">
                                <img
                                    src={RoundedPin}
                                    className="messenger__image"
                                    onClick={() => setIsOpenFileModal(true)}
                                />
                                <div className="messenger__input">
                                    <FloatTextArea
                                        floatLabel="Type your message..."
                                        className="input"
                                        maxLength={1024}
                                        hasError={() => false}
                                        minRows={1}
                                        value={text}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div
                                    className={`messenger__send-btn ${text.trim() ? '' : 'disabled'}`}
                                    onClick={send}
                                    ref={inputRef}
                                >
                                    <SendMessage fill="white" />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>

            {isOpenFileModal && (
                <SendFileModal
                    openModal={isOpenFileModal}
                    setOpenModal={setIsOpenFileModal}
                    messageApi={messageApi}
                    chatId={chatId}
                    setData={setData}
                    socketId={socketId}
                    tenderId={+tenderId}
                />
            )}
        </>
    );
};
