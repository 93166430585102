import React from 'react';
import { Avatar } from 'antd';
import defaultImage from '../../assets/default-school.svg';
import './index.scss';

interface SchoolInfoProps {
    name: string;
    avatarUrl?: string;
    email?: string;
}

const SchoolInfo = ({ name, avatarUrl, email }: SchoolInfoProps) => {
    return (
        <div className="table_school__wrap">
            <Avatar
                size={40}
                src={avatarUrl || defaultImage}
                shape="square"
                style={{
                    color: 'white',
                    border: 'none',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600
                }}
            />

            <div className="table_school__data">
                <div className="table_school__name">{name || '-'}</div>
                {email && <div className="table_school__email">{email || '-'}</div>}
            </div>
        </div>
    );
};

export default SchoolInfo;
