import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { store } from './reducers/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { defaultTheme } from './themes/defaultTheme';
import { MobileNotYetScreen } from './pages/MobileNotYetScreen/MobileNotYetScreen';

let screenWidth = window.screen.width;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <ConfigProvider theme={defaultTheme}>{screenWidth < 1280 ? <MobileNotYetScreen /> : <App />}</ConfigProvider>
    </Provider>
);
