import { useState, FC, useEffect, SetStateAction } from 'react';
import {
    useApproveTaskMutation,
    useDeleteFileMutation,
    useSendActivitiesMutation,
    useSendTaskMutation,
    useUpdateTaskDeadlineMutation
} from '../../api/tenders';
import { Button, Modal, Tooltip, message } from 'antd';
import { useAppSelector } from '../../hooks';
import { Loader } from '../Loader/Loader';
import { taskProps, tenderDataProps } from '../../pages/TenderViewPage/types';
import { UploadFileModal } from '../Modals/UploadFileModal';
import { TaskDeadlineModal } from '../Modals/TaskDeadline';
import { DefineMeetingTask } from './DefinePhaseComponents/DefineMeetingTask';
import { DefineMandatTasks } from './DefinePhaseComponents/DefineMandatTasks';
import { DefineQuestionnaireTask } from './DefinePhaseComponents/DefineQuestionnaireTask';
import { DefineSupportingDocumentTask } from './DefinePhaseComponents/DefineSupportingDocumentTask';
import Cancel from '../../assets/Cancel.svg';

import arrow from '../../assets/arrow-right-white.svg';
import attention from '../../assets/attention.svg';
import file from '../../assets/document.svg';
import fileGrey from '../../assets/fileGrey.svg';

import moment from 'moment';
import './phases.scss';
import { CancelTender } from './CanceledPhaseComponents/CancelTender';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Canceled: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const { role } = useAppSelector((state: any) => state.profileReducer);
    const [taskId, setTaskId] = useState<string | null | number>(null);
    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        if (isLoadingSendTask) return;
        const formData = {
            id: data?.id
        };
        try {
            await sendTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };
    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    useEffect(() => {
        if (!data) return;
        data.phase_tasks.forEach((elem: taskProps) => {
            if (elem.action === 'define_phase_meeting') {
                setTaskId(elem.id);
            }
        });
    }, [data]);

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.map((elem: taskProps) => {
                            if (elem.action === 'define_phase_meeting') {
                                if (elem?.status === 'pending_tr' && !data?.phase_tasks[0].approved) {
                                    return (
                                        <div className="tasks__deadline-wrapper" key={elem.id}>
                                            <img src={attention} />
                                            {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                            {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                        </div>
                                    );
                                }
                            }
                            if (
                                elem.action === 'define_tender_mandate' &&
                                (elem?.status === 'pending_tr' || elem?.status === 'need_approve_tr')
                            ) {
                                return (
                                    <div className="tasks__deadline-wrapper" key={elem.id}>
                                        <img src={attention} />
                                        {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                        {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
                {role === 'school_user' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.map((elem: taskProps) => {
                            if (
                                elem.action === 'define_tender_mandate' &&
                                data.phase_tasks[0]?.status !== 'need_approve_tr'
                            ) {
                                return (
                                    <div className="tasks__deadline-wrapper" key={elem.id}>
                                        <img src={attention} />
                                        {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                        {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                {data.phase_tasks.length ? (
                    <div className="tasks__list">
                        <CancelTender data={data} role={role} setTaskId={setTaskId} setTrigger={setTrigger} />
                    </div>
                ) : (
                    <div className="noTasks">
                        <img src={Cancel} />
                        <div className="noTasks__title">Your tender has been officially cancelled</div>
                        <div className="noTasks__subtitle" style={{ width: '408px' }}>
                            If you think this is a mistake, please immediately inform us by leaving us a message in the
                            chat.
                        </div>
                    </div>
                )}
                {role === 'super_admin' &&
                    data.phase_tasks[0]?.action === 'canceled_tender' &&
                    data.phase_tasks[0]?.status !== 'completed' && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Button
                                className={`tasks__next-btn ${!data?.can_send_task ? 'disabled' : ''}`}
                                type="primary"
                                onClick={() => {
                                    if (data?.can_send_task) {
                                        sendTaskFunc();
                                    }
                                }}
                            >
                                {isLoadingSendTask ? (
                                    <Loader />
                                ) : (
                                    <div className="flex">
                                        Confirm <img src={arrow} style={{ margin: '0 0 0 10px' }} />
                                    </div>
                                )}
                            </Button>
                        </div>
                    )}
            </div>
        </>
    );
};
