import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ProfileInfoInterface {
    id: number | null;
    avatar: string | null;
    email: string | null;
    first_name: string | null;
    last_name: string | null;
    full_name: string | null;
    job_title: string | null;
    phone_number: number | null;
    school_id: number | null;
    school: any;
    role: string | null;
    status: string | null;
}

const initialState: ProfileInfoInterface = {
    id: null,
    avatar: null,
    email: null,
    first_name: null,
    last_name: null,
    full_name: null,
    job_title: null,
    phone_number: null,
    school_id: null,
    school: null,
    role: null,
    status: null
};

const profileSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setProfileInfo: (state, action) => {
            state.id = action.payload.id;
            state.avatar = action.payload.avatar;
            state.email = action.payload.email;
            state.first_name = action.payload.first_name;
            state.last_name = action.payload.last_name;
            state.job_title = action.payload.job_title;
            state.phone_number = action.payload.phone_number;
            state.school = action.payload.school;
            state.role = action.payload.role;
            state.full_name = action.payload.full_name;
        }
    }
});

export const selectAuthUser = (state: RootState) => state.auth;

export const { setProfileInfo } = profileSlice.actions;

export default profileSlice;
