import { Empty, message, Typography } from 'antd';
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { Key } from 'antd/es/table/interface';
import bag from '../../assets/suitcase-portfolio-1.svg';
import checkmarkcircle from '../../assets/checkmark-circle-1.svg';
import stopImage from '../../assets/stopImage.svg';
import pendingcancellation from '../../assets/pending_cancellation.svg';
import statusicon from '../../assets/statusicon.svg';

import NoResults from '../../components/Icons/NoResults';
import NoArchives from '../../assets/NoArchives.svg';
import { Table } from '../../components/Table';

import { CustomSelect } from '../../components/Fields/CustomSelect';
import { useLazyGetArchiveEAQuery, useLazyGetArchiveQuery } from '../../api/tenders';
import { useTableDataSourceWithHooks } from '../../components/Table/useTableDataPropsWithHooks';
import SchoolInfo from '../../components/SchoolInfo';
import moment from 'moment';
import './index.scss';

import { RangePickerInput } from '../../components/Fields/RangePickerInput';
import { useGetSchoolTypesQuery } from '../../api/common';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks';

interface ArchiveFilters {
    status: string | undefined;
    date_from: any;
    date_to: any;
    expense_area_id: string | undefined;
}

interface getArchiveParams {
    filter: ArchiveFilters;
    page?: number;
    per_page?: number;
    sort_field?: string;
    sort_direction?: string;
}

interface Archive {
    close_date: string;
    expense_area_name: string;
    id: number;
    number: string;
    setup_fee: number;
    school: { id: 1; name: string; image: string };
    tr: {
        email: string | undefined;
        name: string;
        avatar: string;
    };
    status: string;
}

export const PastTenders = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [expenseArea, setExpenseArea] = useState<any>([]);
    const [status, setStatus] = useState<any>([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [variables, setVariables] = useState<getArchiveParams>({
        filter: {
            status: undefined,
            date_from: null,
            date_to: null,
            expense_area_id: undefined
        },
        per_page: 20,
        page: 1
    });

    const [getArchive, { data, isLoading }] = useLazyGetArchiveQuery();
    const [getEA, { data: expenseData }] = useLazyGetArchiveEAQuery();
    const { data: dataStatuses } = useGetSchoolTypesQuery({});
    const { role } = useAppSelector((state) => state.profileReducer);

    useEffect(() => {
        getEA();
    }, []);

    useEffect(() => {
        getArchive(variables);
    }, [variables]);

    useEffect(() => {
        setVariables({
            ...variables,
            filter: {
                ...variables.filter,
                expense_area_id: expenseArea.value,
                status: status.value
            }
        });
    }, [expenseArea, status]);

    useEffect(() => {
        // if (endDate && startDate) {
        setVariables({
            ...variables,
            filter: {
                ...variables.filter,

                date_from: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                date_to: endDate ? moment(endDate).format('YYYY-MM-DD') : null
            }
        });
        // }
    }, [endDate, startDate]);

    function formatDate(dateString: string) {
        const date = moment(dateString, 'YYYY-MM-DD');
        const formattedDate = date.format('DD MMMM YYYY');
        return formattedDate;
    }

    function getUserColumns() {
        switch (role) {
            case 'super_admin':
            case 'tender_representative':
                return [
                    {
                        title: 'Tender ID',
                        width: 70,
                        sorter: false,
                        render: (
                            _: any,
                            record: {
                                number:
                                    | string
                                    | number
                                    | boolean
                                    | ReactElement<any, string | JSXElementConstructor<any>>
                                    | Iterable<ReactNode>
                                    | ReactPortal
                                    | null
                                    | undefined;
                            }
                        ) => <div className="table_school__name">{record?.number}</div>
                    },
                    {
                        title: 'School Name',
                        sorter: false,
                        width: 200,
                        render: (_: any, record: { school: { image: any; name: string } }) => (
                            <SchoolInfo avatarUrl={record?.school?.image || undefined} name={record?.school?.name} />
                        )
                    },
                    {
                        title: 'Tender Representative',
                        sorter: false,
                        width: 200,
                        render: (_: any, record: { tr: { avatar: any; name: string; email: string | undefined } }) => (
                            <SchoolInfo
                                avatarUrl={record?.tr?.avatar || undefined}
                                name={record?.tr?.name}
                                email={record?.tr?.email}
                            />
                        )
                    },
                    {
                        title: 'Expense area',
                        sorter: false,
                        width: 100,
                        render: (
                            _: any,
                            record: {
                                expense_area_name:
                                    | string
                                    | number
                                    | boolean
                                    | ReactElement<any, string | JSXElementConstructor<any>>
                                    | Iterable<ReactNode>
                                    | ReactPortal
                                    | null
                                    | undefined;
                            }
                        ) => <div className="table_school__name">{record?.expense_area_name}</div>
                    },
                    {
                        title: 'Close/Cancel Date',
                        sorter: false,
                        width: 80,
                        render: (_: any, record: { close_date: string }) => (
                            <div className="table_school__name">{formatDate(record?.close_date)}</div>
                        )
                    },
                    {
                        title: 'Set up fee amount',
                        sorter: false,
                        width: 80,
                        render: (_: any, record: { setup_fee: { toString: () => string } }) => (
                            <div className="table_school__name">
                                R {record?.setup_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                        )
                    },
                    {
                        title: 'Status',
                        sorter: false,
                        width: 130,
                        render: (_: any, record: { status: string }) => {
                            if (record?.status === 'closed') {
                                return (
                                    <div className="table_text green">
                                        <img src={checkmarkcircle} />
                                        Closed
                                    </div>
                                );
                            } else if (record?.status === 'cancelled') {
                                return (
                                    <div className="table_text red">
                                        <img src={stopImage} />
                                        Cancelled
                                    </div>
                                );
                            } else if (record?.status === 'pending_cancellation') {
                                return (
                                    <div className="table_text gray">
                                        <img src={pendingcancellation} />
                                        Pending cancellation
                                    </div>
                                );
                            }
                        }
                    }
                ];
            case 'school_user':
                return [
                    {
                        title: 'Tender ID',
                        width: 150,
                        sorter: false,
                        render: (
                            _: any,
                            record: {
                                number:
                                    | string
                                    | number
                                    | boolean
                                    | ReactElement<any, string | JSXElementConstructor<any>>
                                    | Iterable<ReactNode>
                                    | ReactPortal
                                    | null
                                    | undefined;
                            }
                        ) => <Typography.Text>{record?.number}</Typography.Text>
                    },

                    {
                        title: 'Expense area',
                        sorter: false,
                        width: 150,
                        render: (
                            _: any,
                            record: {
                                expense_area_name:
                                    | string
                                    | number
                                    | boolean
                                    | ReactElement<any, string | JSXElementConstructor<any>>
                                    | Iterable<ReactNode>
                                    | ReactPortal
                                    | null
                                    | undefined;
                            }
                        ) => <Typography.Text>{record?.expense_area_name}</Typography.Text>
                    },
                    {
                        title: 'Close/Cancel Date',
                        sorter: false,
                        width: 150,
                        render: (_: any, record: { close_date: string }) => (
                            <Typography.Text>{formatDate(record?.close_date)}</Typography.Text>
                        )
                    },

                    {
                        title: 'Status',
                        sorter: false,
                        width: 150,
                        render: (_: any, record: { status: string }) => {
                            if (record?.status === 'closed') {
                                return (
                                    <div className="table_text green">
                                        <img src={checkmarkcircle} />
                                        Closed
                                    </div>
                                );
                            } else if (record?.status === 'cancelled') {
                                return (
                                    <div className="table_text red">
                                        <img src={stopImage} />
                                        Cancelled
                                    </div>
                                );
                            } else if (record?.status === 'pending_cancellation') {
                                return (
                                    <div className="table_text gray">
                                        <img src={pendingcancellation} />
                                        Pending cancellation
                                    </div>
                                );
                            }
                        }
                    }
                ];
            default:
                return [];
        }
    }

    const { tableProps } = useTableDataSourceWithHooks<Archive>({
        columns: getUserColumns(),
        rowKey: 'id',
        data: {
            rows: data?.data,
            loading: isLoading,
            total: data?.meta?.total
        },
        paginationConfig: { defaultPageSize: 20, showSizeChanger: false, position: ['bottomCenter'] },
        onQueryVariableChange: (options) => {
            setVariables({
                ...variables,
                page: options?.page || 1,
                per_page: options?.limit || 20,
                sort_field: options?.sortField || variables.sort_field,
                sort_direction: options?.sortDirection || variables.sort_direction,
                filter: {
                    ...variables.filter
                }
            });
        }
    });

    return (
        <div className="admin-panel">
            <div className="admin-panel__nav nav">
                <div className="nav__title">Past Tenders</div>
                <div className="admin-panel__title">Past Tenders</div>

                <div className="admin-panel__table-container">
                    {contextHolder}
                    <div className="header-actions">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '300px', margin: '30px 0 35px 0', position: 'relative' }}>
                                <RangePickerInput
                                    onChange={setDateRange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholder={'Close/Cancel Date'}
                                    floatLabel={''}
                                    disabled={false}
                                    value={
                                        startDate || endDate
                                            ? `${startDate ? `${moment(startDate).format('DD MMMM YY')} -` : ''}  ${
                                                  endDate ? moment(endDate).format('DD MMMM YY') : ''
                                              } `
                                            : null
                                    }
                                    required={undefined}
                                />
                            </div>

                            <div style={{ width: '300px', margin: '30px 0 -16px 15px' }}>
                                <CustomSelect
                                    prefix={bag}
                                    isSearch={false}
                                    search={''}
                                    floatLabel="Expense Area"
                                    required={false}
                                    err={false}
                                    setSearch={() => {}}
                                    options={(expenseData?.data || []).map((elem: any) => {
                                        return { value: elem.id, name: elem.name };
                                    })}
                                    onChange={(value: any) => {
                                        setExpenseArea(value);
                                    }}
                                    value={expenseArea.name}
                                    form={''}
                                    disabled={false}
                                    hasError={() => false}
                                />
                            </div>

                            <div style={{ width: '300px', margin: '30px 0 -16px 15px' }}>
                                <CustomSelect
                                    prefix={statusicon}
                                    isSearch={false}
                                    search={''}
                                    floatLabel="Status"
                                    required={false}
                                    err={false}
                                    setSearch={() => {}}
                                    options={(dataStatuses?.data?.archive_statuses || []).map((elem: any) => {
                                        return { value: elem.key, name: elem.value };
                                    })}
                                    onChange={(value: any) => {
                                        setStatus(value);
                                    }}
                                    value={status.name}
                                    form={''}
                                    disabled={false}
                                    hasError={() => false}
                                />
                            </div>
                        </div>
                    </div>

                    <Table<Archive>
                        {...tableProps}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        scroll={{ y: '70vh' }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    navigate(`/tender/${record?.id}`);
                                }
                            };
                        }}
                        locale={{
                            emptyText:
                                variables.filter?.date_from ||
                                variables.filter?.date_to ||
                                variables.filter?.status ||
                                variables.filter?.expense_area_id ? (
                                    <Empty
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            gap: '24px',
                                            margin: '50px 0'
                                        }}
                                        image={<NoResults />}
                                        description={
                                            <div
                                                style={{
                                                    maxWidth: '390px'
                                                }}
                                            >
                                                <Typography.Title level={4}>No results found</Typography.Title>
                                                <Typography.Paragraph type="secondary">
                                                    Try adjusting your search to find what you are looking for
                                                </Typography.Paragraph>
                                            </div>
                                        }
                                    />
                                ) : (
                                    <Empty
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            gap: '0px',
                                            margin: '50px 0'
                                        }}
                                        image={<img src={NoArchives} />}
                                        description={
                                            <div
                                                style={{
                                                    maxWidth: '390px'
                                                }}
                                            >
                                                <Typography.Title level={4}>No past tenders yet</Typography.Title>
                                                <Typography.Paragraph type="secondary">
                                                    Once the tender is closed or cancelled, it will be displayed here
                                                </Typography.Paragraph>
                                            </div>
                                        }
                                    />
                                )
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
