import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './general';
import { KanbanI } from './types/common';

export const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery,
    endpoints: (builder) => ({
        getSchoolTypes: builder.query({
            query: () => ({
                url: '/common/reference-book',
                method: 'GET'
            })
        }),
        getKanban: builder.query<KanbanI, { search: string }>({
            query: (params) => ({
                url: '/tenders/kanban',
                method: 'GET',
                params
            })
        })
    })
});

export const { useGetSchoolTypesQuery, useLazyGetKanbanQuery } = commonApi;
