import React from 'react';
import arrow from '../../assets/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <h1>Not Found</h1>
            <div className="auth__back" onClick={() => navigate('/dashboard')}>
                <img src={arrow} alt="back icon" /> Back Home
            </div>
        </div>
    );
};
