import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const EditSvg = ({ fill }: { fill: string }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.9981 12.6678H3.58691"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.1665 8.49887H3.49707"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M14.335 4.3309H3.58691" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.167 15.0261C10.167 14.5839 10.3427 14.1598 10.6553 13.8471L14.6095 9.89292L14.6806 9.82179C14.9932 9.50891 15.4174 9.33301 15.8597 9.33301C16.302 9.33301 16.7262 9.50861 17.039 9.82137L18.0153 10.7977C18.6665 11.4489 18.6667 12.5047 18.0157 13.1561L17.9442 13.2277L13.9903 17.1815C13.6776 17.4942 13.2535 17.6699 12.8113 17.6699H11.0007C10.5402 17.6699 10.167 17.2966 10.167 16.8362V15.0261Z"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const Edit = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <EditSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default Edit;
