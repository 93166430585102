import { createApi } from '@reduxjs/toolkit/query/react';
import { DeleteUserBulkMutationParams, UserDsQueryParams, UserDsResponse } from './types/user';
import { baseQuery } from './general';

interface ResponsTypeEditUser {
    id: number;
    data: any;
}

type ArgsTypesEditUser = {
    id?: number;
    data?: any;
};

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery,
    // TODO: add types for endpoints
    endpoints: (builder) => ({
        getMe: builder.query<any, void>({
            query: () => ({
                url: `/auth/me`,
                method: 'GET'
            })
        }),
        getUser: builder.query({
            query: (id) => ({
                url: `/admin/users/${id}`,
                method: 'GET'
            })
        }),
        getSupplier: builder.query({
            query: (id) => ({
                url: `/admin/suppliers/${id}`,
                method: 'GET'
            })
        }),
        getSupplierStatuses: builder.query({
            query: (id) => ({
                url: `/admin/suppliers/${id}/statuses`,
                method: 'GET'
            })
        }),
        getSupplierDocuments: builder.query({
            query: (id) => ({
                url: `/admin/suppliers/${id}/documents`,
                method: 'GET'
            })
        }),
        getSupliersList: builder.query({
            query: (params) => ({
                url: `/admin/suppliers`,
                method: 'GET',
                params
            })
        }),
        chackVarification: builder.query({
            query: (signature) => ({
                url: `/supplier-companies/check-verification${signature ? signature : ''}`,
                method: 'GET'
            })
        }),
        chackUnvarification: builder.query({
            query: (signature) => ({
                url: `/supplier-companies/check-unverification${signature ? signature : ''}`,
                method: 'GET'
            })
        }),
        chackSign: builder.query({
            query: (signature) => ({
                url: `/supplier-companies/check-sign${signature ? signature : ''}`,
                method: 'GET'
            })
        }),

        getUserDs: builder.query<UserDsResponse, UserDsQueryParams>({
            query(params) {
                return {
                    url: '/admin/users',
                    method: 'GET',
                    params
                };
            },
            keepUnusedDataFor: 0
        }),
        createUser: builder.mutation({
            query: (data) => {
                return {
                    url: '/admin/users',
                    method: 'POST',
                    formData: true,
                    body: data
                };
            }
        }),
        updateStatusSupplier: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/suppliers/${data?.id}/statuses`,
                    method: 'POST',
                    body: data?.data
                };
            }
        }),
        submitVarification: builder.mutation({
            query: (data) => {
                return {
                    url: `/supplier-companies/submit-verification${data?.signature ? data?.signature : ''}`,
                    method: 'POST',
                    body: data?.data
                };
            }
        }),
        submitUnvarification: builder.mutation({
            query: (data) => {
                return {
                    url: `/supplier-companies/submit-unverification${data?.signature ? data?.signature : ''}`,
                    method: 'POST',
                    body: data?.data
                };
            }
        }),
        unverifySupplier: builder.mutation({
            query: (signature) => {
                return {
                    url: `/supplier-companies/unverify${signature ? signature : ''}`,
                    method: 'POST'
                };
            }
        }),
        SubmitSign: builder.mutation({
            query: (data) => {
                return {
                    url: `/supplier-companies/submit-sign${data?.signature ? data?.signature : ''}`,
                    method: 'POST',
                    body: { sign: true }
                };
            }
        }),
        requestDocuments: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/suppliers/${data.id}/documents/send-tpa`,
                    method: 'POST',
                    body: data?.data
                };
            }
        }),
        resendDocuments: builder.mutation({
            query: (id) => {
                return {
                    url: `/admin/suppliers/${id}/documents/resend-tpa`,
                    method: 'POST'
                };
            }
        }),
        uploadTpa: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/suppliers/${data?.id}/documents/upload-tpa`,
                    method: 'POST',
                    body: data.data
                };
            }
        }),
        rejectDocumentsSupplier: builder.mutation({
            query: (data) => {
                return {
                    url: `/admin/suppliers/${data?.id}/documents/reject`,
                    method: 'POST',
                    body: data?.data
                };
            }
        }),
        editUser: builder.mutation<ResponsTypeEditUser, ArgsTypesEditUser>({
            query: (data) => ({
                url: `/admin/users/${data.id}`,
                method: 'POST',
                formData: true,
                body: data.data
            })
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/admin/users/${id}`,
                method: 'DELETE'
            })
        }),
        deleteSupplier: builder.mutation({
            query: (id) => ({
                url: `/admin/suppliers/${id}`,
                method: 'DELETE'
            })
        }),
        deleteUserBulk: builder.mutation<any, DeleteUserBulkMutationParams>({
            query: (params) => ({
                url: '/admin/users/bulk-delete',
                method: 'Delete',
                params
            })
        }),
        deleteSupplierBulk: builder.mutation<any, DeleteUserBulkMutationParams>({
            query: (params) => ({
                url: '/admin/suppliers/bulk-delete',
                method: 'Delete',
                params
            })
        }),
        resendInvite: builder.mutation<ResponsTypeEditUser, ArgsTypesEditUser>({
            query: (id) => ({
                url: `/admin/users/${id}/resend-invite`,
                method: 'POST'
            })
        }),
        getInvatationData: builder.query({
            query: (token) => ({
                url: `/auth/invitation-data?token=${token}`,
                method: 'GET'
            })
        }),
        CheckSupplier: builder.query({
            query: (signature) => ({
                url: `/supplier-companies/tender-registration${signature ? `${signature}` : ''}`,
                method: 'GET'
            })
        }),
        addTenderToSupplier: builder.query({
            query: (data) => ({
                url: `/supplier-companies/tender-registration${data?.signature ? `${data?.signature}` : ''}`,
                method: 'POST',
                body: data?.data
            })
        }),
        signUp: builder.mutation({
            query: (data) => ({
                url: `/auth/sign-up?token=${data.token}`,
                method: 'POST',
                formData: true,
                body: data.data
            })
        }),
        createSupplier: builder.mutation({
            query: (data) => ({
                url: `/supplier-companies/registration${data?.step === 1 ? '' : '?step=submit'}`,
                method: 'POST',
                body: data.data
            })
        }),
        createSupplierForm: builder.mutation({
            query: (data) => ({
                url: `/admin/suppliers${data?.step === 1 ? '' : '?step=submit'}`,
                method: 'POST',
                body: data.data
            })
        }),
        updateSupplier: builder.mutation({
            query: (data) => ({
                url: `/admin/suppliers/${data.id}${data?.step === 1 ? '' : '?step=contact'}`,
                method: 'PUT',
                body: data.data
            })
        })
    })
});

export const {
    useLazyGetUserQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    useDeleteUserBulkMutation,
    useGetUserDsQuery,
    useResendInviteMutation,
    useLazyGetInvatationDataQuery,
    useSignUpMutation,
    useGetMeQuery,
    useLazyGetMeQuery,
    useLazyGetUserDsQuery,
    useCreateSupplierMutation,
    useGetSupliersListQuery,
    useDeleteSupplierBulkMutation,
    useDeleteSupplierMutation,
    useCreateSupplierFormMutation,
    useLazyGetSupplierQuery,
    useLazyGetSupplierStatusesQuery,
    useUpdateStatusSupplierMutation,
    useLazyGetSupplierDocumentsQuery,
    useUpdateSupplierMutation,
    useLazyChackVarificationQuery,
    useSubmitVarificationMutation,
    useLazyChackUnvarificationQuery,
    useSubmitUnvarificationMutation,
    useRequestDocumentsMutation,
    useResendDocumentsMutation,
    useUploadTpaMutation,
    useRejectDocumentsSupplierMutation,
    useUnverifySupplierMutation,
    useLazyChackSignQuery,
    useSubmitSignMutation,
    useLazyCheckSupplierQuery,
    useLazyAddTenderToSupplierQuery
} = usersApi;
