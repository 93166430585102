import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AuthState {
    id: string | null;
    name: string | null;
    email: string | null;
    authToken?: string;
}

const initialState: AuthState = JSON.parse(
    localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}'
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthUser: (state, action: PayloadAction<AuthState & { rememberMe?: boolean }>) => {
            const { id, email, name, authToken, rememberMe } = action.payload;
            if (rememberMe) {
                localStorage.setItem('authUser', JSON.stringify(action.payload));
            } else {
                sessionStorage.setItem('authUser', JSON.stringify(action.payload));
            }
            state.id = id;
            state.email = email;
            state.name = name;
            state.authToken = authToken;
        },
        logout: (state) => {
            localStorage.clear();
            sessionStorage.clear();
            state.id = null;
            state.email = null;
            state.name = null;
            state.authToken = undefined;
        }
    }
});

export const selectAuthUser = (state: RootState) => state.auth;

export const { setAuthUser, logout } = authSlice.actions;

export default authSlice;
