export const SendMessage = ({ fill, style }: { fill: string; style?: any }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M9.06909 9.99985H4.49146"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            style={style}
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.36672 3.02243L16.5318 9.10343C16.8716 9.27321 17.0862 9.62041 17.0862 10.0002C17.0862 10.38 16.8716 10.7272 16.5318 10.897L4.36672 16.978C4.0142 17.1543 3.59111 17.1078 3.28535 16.8591C2.97958 16.6103 2.84787 16.2056 2.94869 15.8246L4.4914 10.0002L2.94869 4.17588C2.84787 3.79485 2.97958 3.39009 3.28535 3.14138C3.59111 2.89267 4.0142 2.84613 4.36672 3.02243Z"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
