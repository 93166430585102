import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const ArrowDownSvg = ({ fill }: { fill: string }) => (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Interface, Essential/Arrow, Down">
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Path"
                        d="M6.005 2.25488V9.74488"
                        stroke={fill}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_2"
                        d="M3.01147 6.75342L5.99997 9.74142L8.98847 6.75342"
                        stroke={fill}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const ArrowDown = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => <ArrowDownSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default ArrowDown;
