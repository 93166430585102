import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { SupplierI, tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { useRejectSupplierMutation } from '../../api/tenders';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    selectedSupplier: SupplierI | null;
    data: any;
    taskId: number | null;
    messageApi: any;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('This is a required field')
        .test('not-only-spaces', 'This is a required field', (value) => {
            return value.trim() !== '';
        })
});

export const RejectSupplierModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    taskId,
    setTrigger,
    selectedSupplier,
    data,
    messageApi
}) => {
    const [rejectSupplier, { data: rejectedData, isLoading, isSuccess }] = useRejectSupplierMutation();

    const [form] = Form.useForm();

    useEffect(() => {
        if (isSuccess) {
            setOpenModal(false);
            setTrigger((prev: boolean) => !prev);
            messageApi?.success(rejectedData?.message);
        }
    }, [isSuccess]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const dataToPost = {
                    tenderId: data.id,
                    supplierId: taskId,
                    data: {
                        comment: values.comment
                    }
                };
                rejectSupplier(dataToPost);
            })
            .catch((err) => console.log('err', err));
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            key="submit"
                            type="primary"
                            onClick={() => {
                                if (isLoading) return;
                                handleFormSubmit();
                            }}
                        >
                            {isLoading ? <Loader /> : 'Confirm'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">
                            <div className="modal__nametitle">
                                Reject <b>{selectedSupplier?.name}</b>?
                            </div>
                        </div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    disabled={isLoading ? true : false}
                                    floatLabel={'Provide a reason for rejection'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};
