export const ClockIcon = ({ fill, style }: { fill: string; style?: any }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" style={style}>
        <path
            d="M10.8337 4.99792C14.2869 4.99792 17.0863 7.79731 17.0863 11.2505C17.0863 14.7037 14.2869 17.5031 10.8337 17.5031C7.38044 17.5031 4.58105 14.7037 4.58105 11.2505C4.58105 7.79731 7.38044 4.99792 10.8337 4.99792"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.8329 7.49896V10.8337"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M9.16602 2.49692H12.5007"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.5026 5.83161L16.6689 4.99792"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
