import { downloadFileFunc, downloadSingleFileFunc } from '../../api/axiosCruds';
import { tenderDataProps } from '../../pages/TenderViewPage/types';

export const downloadFunc = (
    uuid: string,
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>,
    messageApi: any
) => {
    downloadSingleFileFunc(uuid)
        .then((response: any) => {
            const stplitArray = response.headers['content-disposition'].split(';');
            const element = stplitArray.find((elem: string) => elem.includes('filename'));
            const name = element.split('=')[1];

            downloadPDF(response, name, setTrigger, messageApi);
        })
        .catch((err) => {
            messageApi.error('Downloading Error. Check your internet connection');
        });
};

const downloadPDF = (
    response: any,
    filename: any,
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>,
    messageApi: any
) => {
    let blob = new Blob([response.data], {
        type: response.headers['content-type']
    });
    if (filename.includes('pdf')) {
        let headerLine = response.headers['content-disposition'];

        if (headerLine) {
            let startFileNameIndex = headerLine.indexOf('"') + 1;
            let endFileNameIndex = headerLine.lastIndexOf('"');
            filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        }
    }
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let fileName = filename.replace(/"/g, '').trim();
    link.download = fileName;
    link.target = '_blank';

    const el = document.body;
    el.appendChild(link);

    link.click();
    setTrigger((prev: boolean) => !prev);
    messageApi.success('File has been downloaded successfully');
};
