import { FC, useState, useEffect } from 'react';
import { Button, Form, Modal, Spin } from 'antd';
import { FloatSelect } from '../Fields/FloatSelect';
import { FloatInput } from '../Fields/FloatInput';
import { Select } from '../Fields/Select';
import { useLazyGetSchoolsQuery } from '../../api/schools';
import { useGetUserDsQuery, useGetUserQuery, useLazyGetUserQuery, useLazyGetUserDsQuery } from '../../api/users';
import { UserDsQueryParams } from '../../api/types/user';
import {
    useCreateAreaMutation,
    useCreateTenderMutation,
    useDeleteAreaMutation,
    useLazyGetAreasQuery,
    useUpdateAreaMutation,
    useUpdateTenderMutation
} from '../../api/tenders';
import { ExpenseAreaSelect } from '../Fields/ExpenseAreaSelect';
import { yupSync } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { CustomErrorType } from '../../api/types/global';

import close from '../../assets/RoundedClose.svg';
import * as Yup from 'yup';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger?: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
    type: string;
    data?: any;
}

const validationSchema = Yup.object().shape({
    area: Yup.object().required('This is a required field'),
    school: Yup.object().required('This is a required field'),
    school_user: Yup.object().required('This is a required field'),
    tender_representative: Yup.object().required('This is a required field')
});

export const ModalTender: FC<ModalProps> = ({ openModal, setOpenModal, messageApi, type, data, setTrigger }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [searchSchools, setSearchSchools] = useState<string>('');
    const [searchUsers, setSearchUsers] = useState<string>('');
    const [searchTenders, setSearchTenders] = useState<string>('');
    const [variablesUsers, setVariablesUsers] = useState<UserDsQueryParams>({
        filter: { search: undefined, school_id: undefined, role: undefined },
        per_page: -1,
        page: 1
    });
    const [variablesTender, setVariablesTender] = useState<UserDsQueryParams>({
        filter: { search: undefined, role: ['tender_representative'] },
        per_page: -1,
        page: 1
    });
    const [school, setSchool] = useState('');
    const [newAreaValue, setNewAreaValue] = useState('');
    const [contractValue, setContractValue] = useState<any>(null);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const navigate = useNavigate();
    const [changed, setChanged] = useState(false);
    const [contactErr, setContactErr] = useState(false);

    const [getSchoolsTrigger, { data: dataSchools, isSuccess: getSchoolSuccess, isLoading: getSchoolsLoading }] =
        useLazyGetSchoolsQuery(); // get schools

    const [triggerSchoolUsers, { data: dataUsers, isSuccess: getUsersSuccess, isLoading: getUserssLoading }] =
        useLazyGetUserDsQuery(); // get school users

    const [triggerGetArea, { data: dataAreas, isSuccess: getAreasSuccess, isLoading: getAreassLoading }] =
        useLazyGetAreasQuery(); // get areas

    const {
        data: dataTender,
        isSuccess: getTenderSuccess,
        isLoading: getTendersLoading
    } = useGetUserDsQuery(variablesTender); //get tender users

    const [
        createArea,
        {
            data: areaData,
            isSuccess: createAreaSuccess,
            isLoading: createAreaLoading,
            originalArgs: createOriginalArgs,
            error: errorCreateArea
        }
    ] = useCreateAreaMutation(); //create new area

    const [
        updateArea,
        {
            data: updatedAreaData,
            isSuccess: updatedAreaSuccess,
            isLoading: updatedAreaLoading,
            originalArgs: updatedOriginalArgs,
            error: errorUpdatedArea,
            isError: isErrorUpdateArea
        }
    ] = useUpdateAreaMutation(); // edit area

    const [
        deleteArea,
        {
            data: deleteAreaData,
            isSuccess: deleteAreaSuccess,
            isLoading: deleteAreaLoading,
            originalArgs: deleteOriginalArgs,
            error: errordeleteArea,
            isError: isErrorDeleteArea
        }
    ] = useDeleteAreaMutation(); //delete area

    const [
        createTender,
        {
            data: tenderData,
            isSuccess: createTenderSuccess,
            isLoading: createTenderLoading,
            originalArgs: createTenderOriginalArgs,
            error: errorCreateTender,
            isError: isErrorTender
        }
    ] = useCreateTenderMutation(); //create tender

    const [
        updateTender,
        {
            data: updatedTenderData,
            isSuccess: updatedTenderSuccess,
            isLoading: updatedTenderLoading,
            error: errorUpdatedTender,
            isError: isErrorUpdateTender
        }
    ] = useUpdateTenderMutation(); // edit tender

    useEffect(() => {
        triggerGetArea();
    }, [createAreaSuccess, updatedAreaSuccess, deleteAreaSuccess]);

    useEffect(() => {
        if (createAreaSuccess) {
            messageApi.success(areaData?.message);
        }
    }, [createAreaSuccess]);

    useEffect(() => {
        if (deleteAreaSuccess) {
            messageApi.success(deleteAreaData?.message);
        }
        if (isErrorDeleteArea) {
            messageApi.error((errordeleteArea as CustomErrorType)?.data?.message);
        }
    }, [deleteAreaSuccess, isErrorDeleteArea]);

    useEffect(() => {
        if (updatedAreaSuccess) {
            messageApi.success(updatedAreaData?.message);
        }
    }, [updatedAreaSuccess, updatedAreaData]);

    useEffect(() => {
        if (!school) return;
        const params = {
            //@ts-ignore
            filter: { search: searchUsers ? searchUsers : undefined, school_id: school?.id, role: ['school_user'] },
            per_page: -1,
            page: 1
        };
        triggerSchoolUsers(params);
    }, [school, searchUsers]);

    useEffect(() => {
        if (searchSchools && searchSchools.length >= 2) {
            const params = {
                //@ts-ignore
                filter: { dropdown_search: searchSchools },
                page: 1
            };
            getSchoolsTrigger(params);
        }
    }, [searchSchools]);

    useEffect(() => {
        setVariablesTender((prev) => ({
            ...prev,
            filter: { ...prev.filter, search: searchTenders }
        }));
    }, [searchTenders]);

    useEffect(() => {
        if (!data) return;
        form.setFieldsValue({
            //@ts-ignore
            area: data?.expense_area,
            school: data?.school,
            school_user: data?.school_user,
            tender_representative: data?.tender_representative
        });
        setSchool(data?.school);
        setContractValue(data?.contract_value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }, [data, form]);

    const createAreaFunc = async () => {
        try {
            const name = {
                name: newAreaValue?.trim()
            };
            await createArea(name).unwrap();
        } catch {
        } finally {
        }
    };

    const editAreaFunc = async (id: number | string, name: string) => {
        try {
            const data = {
                id: id,
                data: { name: name?.trim() }
            };
            await updateArea(data).unwrap();
        } catch {
        } finally {
        }
    };

    const deleteAreaFunc = async (id: number) => {
        try {
            await deleteArea(id).unwrap();
        } catch {}
    };

    useEffect(() => {
        if (contractValue) {
            setContactErr(false);
        }
    }, [contractValue]);

    const handleFormSubmit = () => {
        let isValid = true;
        if (!contractValue) {
            setContactErr(true);
            isValid = false;
        }
        form.validateFields()
            .then((values: any) => {
                if (type === 'create') {
                    const data = {
                        expense_area_id: values.area.id,
                        school_id: values.school.id,
                        su_id: values.school_user.id,
                        tr_id: values.tender_representative.id,
                        contract_value: parseInt(contractValue?.replace(/,/g, ''), 10)
                    };
                    if (isValid) {
                        createTenderHandler(data);
                    }
                }
                if (type === 'edit') {
                    const data = {
                        expense_area_id: values.area.id,
                        su_id: values.school_user.id,
                        tr_id: values.tender_representative.id,
                        contract_value: parseInt(contractValue?.replace(/,/g, ''), 10)
                    };
                    if (isValid) {
                        editTenderHandler(data);
                    }
                }
            })
            .catch((error: any) => {
                error.errorFields.forEach((elem: any) => {});
            });
    };

    const successCreat = async () => {
        await messageApi.success(tenderData?.message || 'The tender has been created successfully');
        await navigate(`/tender/${tenderData.data.id}`);
    };

    useEffect(() => {
        if (createTenderSuccess) {
            successCreat();
        }
    }, [createTenderSuccess]);

    useEffect(() => {
        if (updatedTenderSuccess) {
            messageApi.success(updatedTenderData?.message || 'The tender has been created successfully');
            setOpenModal(false);
            if (type === 'edit') {
                //@ts-ignore
                setTrigger((prev) => !prev);
            }
        }
    }, [updatedTenderSuccess]);

    useEffect(() => {
        if (isErrorTender) {
            messageApi.error((errorCreateTender as CustomErrorType)?.data?.message || 'Something went wrong');
        }
    }, [isErrorTender]);

    useEffect(() => {
        if (isErrorUpdateTender) {
            messageApi.error((errorUpdatedTender as CustomErrorType)?.data?.message || 'Something went wrong');
        }
    }, [isErrorUpdateTender]);

    const createTenderHandler = async (res: any) => {
        setLoading(true);
        try {
            await createTender(res).unwrap();
            // refetch();
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const editTenderHandler = async (res: any) => {
        setLoading(true);
        try {
            const variables = {
                data: res,
                id: data.id
            };
            await updateTender(variables).unwrap();
            // refetch();
        } catch {
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched() || changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                        // refetch();
                    }
                }}
                closeIcon={<img src={close} />}
                // width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched() || changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                    // refetch();
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            key="submit"
                            type="primary"
                            onClick={() => handleFormSubmit()}
                            // loading={createUserLoading || loading}
                        >
                            {type === 'create' ? 'Create' : 'Save'}
                        </Button>
                    </div>
                }
            >
                <Spin spinning={loading} size="large">
                    <div className="modal">
                        <div className="modal__header" style={{ height: '102px' }}>
                            <div className="modal__title">{type === 'create' ? 'Create Tender' : 'Edit Tender'}</div>
                            {data?.number ? <div className="modal__id">ID {data?.number}</div> : ''}
                        </div>

                        <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                            <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                                All fields are mandatory
                            </div>
                            <Form form={form} onFinish={handleFormSubmit}>
                                <Form.Item
                                    className="input-wrapper"
                                    name="area"
                                    rules={yupSync('area', validationSchema, true)}
                                >
                                    <ExpenseAreaSelect
                                        setChanged={() => {}}
                                        floatLabel="Expense Area"
                                        className="input"
                                        required={false}
                                        options={dataAreas?.data}
                                        create={createAreaFunc}
                                        edit={editAreaFunc}
                                        deleteFunc={deleteAreaFunc}
                                        newAreaValue={newAreaValue}
                                        setNewAreaValue={setNewAreaValue}
                                        success={createAreaSuccess}
                                        successupdate={updatedAreaSuccess}
                                        error={false}
                                        errorTextUpdate={(errorUpdatedArea as CustomErrorType)?.data?.message}
                                        errorTextCreate={(errorCreateArea as CustomErrorType)?.data?.message}
                                        loading={createAreaLoading || updatedAreaLoading || deleteAreaLoading}
                                        hasError={() => !!form.getFieldError(['area']).length}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="input-wrapper"
                                    name="contract_value"
                                    // rules={yupSync('contract_value', validationSchema, true)}
                                >
                                    <FloatInput
                                        name="contract_value"
                                        floatLabel="Contract Value"
                                        className="input"
                                        maxLength={60}
                                        required={false}
                                        type="contractValue"
                                        value={contractValue}
                                        setContractValue={(value: any) => setContractValue(value)}
                                        contractValue={contractValue}
                                        setChanged={setChanged}
                                        error={contactErr}
                                        onBlur={() => {
                                            setContractValue(contractValue?.replace(/\./g, ''));
                                            if (!contractValue) {
                                                setContactErr(true);
                                            }
                                        }}
                                    />
                                    {contactErr && <div className="error-text">This is a reauired field</div>}
                                </Form.Item>
                                <Form.Item
                                    className="input-wrapper"
                                    name="school"
                                    rules={yupSync('school', validationSchema, true)}
                                    // style={height('school') ? { minHeight: '70px' } : { minHeight: '52px' }}
                                >
                                    <FloatSelect
                                        search={searchSchools}
                                        floatLabel="School"
                                        className="input-select"
                                        required={false}
                                        err={false}
                                        setSearch={setSearchSchools}
                                        options={dataSchools?.data}
                                        onChange={setSchool}
                                        value={school}
                                        form={form}
                                        hasError={() => !!form.getFieldError(['school']).length}
                                        disabled={type === 'edit' ? true : false}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="input-wrapper"
                                    name="school_user"
                                    rules={yupSync('school_user', validationSchema, true)}
                                    // style={height('school_user') ? { minHeight: '70px' } : { minHeight: '52px' }}
                                >
                                    <FloatSelect
                                        type={'user'}
                                        search={searchUsers}
                                        floatLabel="School User"
                                        className="input-select"
                                        required={false}
                                        err={false}
                                        setSearch={setSearchUsers}
                                        options={dataUsers?.data}
                                        onChange={() => {}}
                                        value={''}
                                        form={form}
                                        disabled={school ? false : true}
                                        hasError={() => !!form.getFieldError(['school_user']).length}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="input-wrapper"
                                    name="tender_representative"
                                    rules={yupSync('tender_representative', validationSchema, true)}
                                >
                                    <FloatSelect
                                        type={'tender'}
                                        search={searchTenders}
                                        floatLabel="Tender Representative"
                                        className="input-select"
                                        required={false}
                                        err={false}
                                        setSearch={setSearchTenders}
                                        options={dataTender?.data}
                                        onChange={() => {}}
                                        value={''}
                                        form={form}
                                        hasError={() => !!form.getFieldError(['tender_representative']).length}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Spin>
            </Modal>

            <Modal
                className="confirmModal"
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                                // refetch();
                            }}
                            // block
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
