import { ColumnType } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import objectPath from 'object-path';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';

type Prev = [never, 0, 1, 2, 3];

type Join<K, P> = K extends string | number
    ? P extends string | number
        ? `${K}${'' extends P ? '' : '.'}${P}`
        : never
    : never;

export type Paths<T, D extends number = 10> = [D] extends [never]
    ? never
    : T extends object
    ? {
          [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
      }[keyof T]
    : '';

export interface TableColumnType<T> extends Omit<ColumnType<T>, 'dataIndex'> {
    dataIndex?: Paths<T, 3>;
}

export enum Order_By {
    asc = 'asc',
    ascNullsFirst = 'asc_nulls_first',
    ascNullsLast = 'asc_nulls_last',
    desc = 'desc',
    descNullsFirst = 'desc_nulls_first',
    descNullsLast = 'desc_nulls_last'
}

type OrderBy = {
    [key: string]: OrderBy | Order_By;
};

/**
 * useTableDataSourceWithHooks hook. This table helper table function returns table props which can be passed directly
 * as a prop to Antd table.
 * @return tableProps
 */
export function useTableDataSourceWithHooks<TSingleRow = any, TWhere = any>({
    columns,
    rowKey,
    onQueryVariableChange,
    paginationConfig,
    data
}: {
    rowKey?: keyof TSingleRow;
    columns: TableColumnType<TSingleRow>[];
    onQueryVariableChange?: (options?: {
        limit?: number;
        sortDirection?: Order_By;
        sortField?: string;
        page?: number;
        // redundant
        offset?: number;
        orderBy?: OrderBy[];
        // fetchPolicy?: FetchPolicy;
    }) => void;
    paginationConfig?: false | TablePaginationConfig;
    data: {
        total?: number;
        rows?: TSingleRow[];
        loading?: boolean;
    };
}) {
    const [rows, setRows] = useState<TSingleRow[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [tableChangeProps, setTableChangeProps] = useState<{
        pagination?: TablePaginationConfig;
        sorter?: SorterResult<TSingleRow> | SorterResult<TSingleRow>[];
    }>({});

    useEffect(() => {
        if (data.rows) setRows(data.rows);
        if (data.total) setTotalRows(data.total);
    }, [data.rows, data.total]);

    const onTableChange = async (
        pagination?: TablePaginationConfig,
        filters?: Record<string, FilterValue | null>,
        sorter?: SorterResult<TSingleRow> | SorterResult<TSingleRow>[],
        extra?: TableCurrentDataSource<TSingleRow>
        // fetchPolicy?: FetchPolicy
    ) => {
        setTableChangeProps({
            pagination,
            sorter
        });
        // TODO: refactor this
        const orderBy: OrderBy[] = [];
        let sortDirection: Order_By | undefined = undefined;
        let sortField: string | undefined = undefined;

        if (sorter && Array.isArray(sorter)) {
            orderBy.push(
                ...sorter.map((value) => ({
                    [value.field as string]: value.order === 'ascend' ? Order_By.asc : Order_By.desc
                }))
            );
        } else if (sorter && sorter.field) {
            const path = sorter.field.toString().replace(/,/gim, '.');
            const dir = sorter.order === 'ascend' ? Order_By.asc : Order_By.desc;
            const sortByObj = {} as OrderBy;
            objectPath.set(sortByObj, path, dir);
            orderBy.push(sortByObj);

            sortDirection = dir;
            sortField = path;
        }

        onQueryVariableChange &&
            onQueryVariableChange({
                ...(pagination?.pageSize && { limit: pagination.pageSize }),
                ...(pagination?.current &&
                    pagination?.pageSize && { offset: (pagination.current - 1) * pagination.pageSize }),
                ...(pagination?.current && { page: pagination.current }),
                orderBy: orderBy as OrderBy[] | undefined,
                sortDirection,
                sortField
                // fetchPolicy: fetchPolicy
            });
    };

    useEffect(() => {
        onTableChange(tableChangeProps.pagination, {}, tableChangeProps.sorter, undefined);
    }, []);

    return {
        tableProps: {
            columns: columns.map((el) => {
                return {
                    ...el,
                    ...(el.dataIndex && { dataIndex: (el.dataIndex as string).split('.') })
                };
            }) as unknown as ColumnsType<TSingleRow>,
            dataSource: rows,
            loading: data.loading,
            onChange: onTableChange,
            rowKey,
            ...(paginationConfig === false && {
                pagination: false as false
            }),
            ...(paginationConfig !== false && {
                pagination: {
                    ...paginationConfig,
                    ...(totalRows && { total: totalRows })
                }
            })
        }
    };
}
