import MobileNotYet from '../../assets/MobileNotYet.svg';
import './index.scss';

export const MobileNotYetScreen = () => {
    return (
        <div className="MobileNotYetScreen">
            <img src={MobileNotYet} className="MobileNotYetScreen__image" />
            <div className="MobileNotYetScreen__title">The Hive isn’t available on mobile yet</div>
            <div className="MobileNotYetScreen__subtitle">
                To access the application, try us out on a larger screen.
            </div>
        </div>
    );
};
