import { Button, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { ModalTender } from '../../components/Modals/ModalTender';
import { PlusCircleOutlined } from '@ant-design/icons';
import InputSearch from '../../components/Fields/InputSearch';
import { useLazyGetKanbanQuery } from '../../api/common';
import { PhaseI } from '../../api/types/common';
import { KanbanItem } from './KanbanItem';
import { useAppSelector } from '../../hooks';
import { KanbanItemSU } from './KanbanItemSU';
import { useLazyGetTenderQuery } from '../../api/tenders';
import useDebounceSearch from '../../hooks/useDebounceSearch';
import { CancelTenderModal } from '../../components/Modals/CancelTenderModal';
import NoDataOnDashboard from '../../assets/NoDataOnDashboard.svg';
import './index.scss';

export const Dashboard = () => {
    const [isOpenCreateTenderModal, setIsOpenCreateTenderModal] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounceSearch(search);
    const [getKanban, { data, isLoading }] = useLazyGetKanbanQuery();
    const [isOpenEditTenderModal, setIsOpenEditTenderModal] = useState(false);
    const { role } = useAppSelector((state) => state.profileReducer);
    const [selectTender, setSelectTender] = useState<number | null>(null);
    const [getTender, { data: dataTender, isError: isErrorDataTender, error: errorDataTender }] =
        useLazyGetTenderQuery();
    const [trigger, setTrigger] = useState(false);
    const [openCancelTenderModal, setOpenCancelTenderModal] = useState(false);
    const [selectedTender, setSelectedTender] = useState<PhaseI | null>(null);
    const [openManage, setOpenManage] = useState<number | null>(null);

    useEffect(() => {
        if (selectTender) {
            getTender(selectTender);
        }
    }, [selectTender]);

    useEffect(() => {
        getKanban({ search: debouncedSearch });
    }, [trigger, debouncedSearch]);

    return (
        <>
            <Spin spinning={isLoading} size="large">
                <div className="dashboard">
                    {contextHolder}
                    <div
                        className={`dashboard__top-row top-row ${
                            role === 'school_user' || role === 'onboarding_representative' ? 'school_user' : ''
                        }`}
                    >
                        <div className="top-row__item">
                            <div className="top-row__title">Total tenders</div>
                            <div className="top-row__value">{data?.data?.total_count}</div>
                        </div>
                        <div className="top-row__item">
                            <div className="top-row__title">
                                {role === 'tender_representative' ? (
                                    'Value'
                                ) : (
                                    <>
                                        Contract <br /> Value
                                    </>
                                )}
                            </div>
                            <div className="top-row__value value">
                                R {data?.data?.contract_value_format.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} m
                            </div>
                        </div>
                        <div className="top-row__item">
                            <div className="top-row__title">Expense areas</div>
                            <div className="top-row__value">{data?.data?.expense_areas_count}</div>
                        </div>
                        <div className="top-row__item">
                            <div className="top-row__wrap">
                                <div className="on-track color"></div>
                                <div className="top-row__title">On-track</div>
                            </div>

                            <div className="top-row__value">{data?.data?.statuses?.on_track}</div>
                        </div>
                        <div className="top-row__item">
                            <div className="top-row__wrap">
                                <div className="late color"></div>
                                <div className="top-row__title">Late</div>
                            </div>
                            {role === 'school_user' || role === 'onboarding_representative' ? (
                                <div className="top-row__value">
                                    {data?.data?.statuses?.late && data?.data?.statuses?.follow_up
                                        ? data?.data?.statuses?.late + data?.data?.statuses?.follow_up
                                        : data?.data?.statuses?.late && !data?.data?.statuses?.follow_up
                                        ? data?.data?.statuses?.late
                                        : data?.data?.statuses?.follow_up}
                                    {}
                                </div>
                            ) : (
                                <div className="top-row__value">{data?.data?.statuses?.late}</div>
                            )}
                        </div>
                        {role === 'school_user' || role === 'onboarding_representative' ? (
                            <></>
                        ) : (
                            <div className="top-row__item">
                                <div className="top-row__wrap">
                                    <div className="follow-up color"></div>
                                    <div className="top-row__title">Follow-up</div>
                                </div>
                                <div className="top-row__value">{data?.data?.statuses?.follow_up}</div>
                            </div>
                        )}
                    </div>
                    <div className="dashboard__title">All Tenders</div>
                    {role && role !== 'school_user' && role !== 'onboarding_representative' && (
                        <div className="dashboard__filters">
                            <div className="dashboard__search">
                                <InputSearch handleChange={(value) => setSearch(value)} />
                            </div>
                            {role === 'super_admin' && (
                                <Button
                                    onClick={() => setIsOpenCreateTenderModal(true)}
                                    htmlType="submit"
                                    type="primary"
                                    // loading={loading}

                                    block
                                    icon={<PlusCircleOutlined />}
                                    className="dashboard__add"
                                >
                                    Add
                                </Button>
                            )}
                        </div>
                    )}

                    {role &&
                        role !== 'school_user' &&
                        role !== 'onboarding_representative' &&
                        !Array.isArray(data?.data?.kanban) && (
                            <>
                                {data?.data?.total_count ? (
                                    <div className="dashboard__kanban kanban">
                                        {role !== 'tender_representative' && (
                                            <div className="kanban__item">
                                                <div className="kanban__header">
                                                    Submit{' '}
                                                    <div className="kanban__number">
                                                        {' '}
                                                        {data?.data?.kanban?.submit?.length
                                                            ? data?.data?.kanban?.submit?.length
                                                            : 0}
                                                    </div>
                                                </div>
                                                {data?.data?.kanban?.submit?.map((elem: PhaseI) => {
                                                    return (
                                                        <KanbanItem
                                                            item={elem}
                                                            setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                            setSelectTender={setSelectTender}
                                                            setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                            setSelectedTender={setSelectedTender}
                                                            setOpenManage={setOpenManage}
                                                            openManage={openManage}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Define
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.define?.length
                                                        ? data?.data?.kanban?.define?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.define?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Design{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.design?.length
                                                        ? data?.data?.kanban?.design?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.design?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Launch{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.launch?.length
                                                        ? data?.data?.kanban?.launch?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.launch?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Evaluation Gate 1+2{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.evaluate_1_2?.length
                                                        ? data?.data?.kanban?.evaluate_1_2?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.evaluate_1_2?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Evaluation Gate 3{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.evaluate_3?.length
                                                        ? data?.data?.kanban?.evaluate_3?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.evaluate_3?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                Evaluation Gate 4{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.evaluate_4?.length
                                                        ? data?.data?.kanban?.evaluate_4?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.evaluate_4?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                        <div className="kanban__item">
                                            <div className="kanban__header">
                                                {' '}
                                                Award{' '}
                                                <div className="kanban__number">
                                                    {' '}
                                                    {data?.data?.kanban?.award?.length
                                                        ? data?.data?.kanban?.award?.length
                                                        : 0}
                                                </div>
                                            </div>
                                            {data?.data?.kanban?.award?.map((elem: PhaseI) => {
                                                return (
                                                    <KanbanItem
                                                        item={elem}
                                                        setIsOpenEditTenderModal={setIsOpenEditTenderModal}
                                                        setSelectTender={setSelectTender}
                                                        setOpenCancelTenderModal={setOpenCancelTenderModal}
                                                        setSelectedTender={setSelectedTender}
                                                        setOpenManage={setOpenManage}
                                                        openManage={openManage}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {isLoading ? (
                                            <></>
                                        ) : (
                                            <div className="noTasks">
                                                <img src={NoDataOnDashboard} />
                                                <div className="noTasks__title">No active tenders yet</div>
                                                <div className="noTasks__subtitle">
                                                    Сurrent tenders will be displayed here
                                                </div>
                                                {role && role === 'super_admin' && (
                                                    <Button
                                                        onClick={() => setIsOpenCreateTenderModal(true)}
                                                        htmlType="submit"
                                                        type="primary"
                                                        block
                                                        icon={<PlusCircleOutlined />}
                                                        className="dashboard__add nodata"
                                                        style={{ margin: '30px 0 0 0', width: '150px' }}
                                                    >
                                                        Add Tender
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    {role && (role === 'school_user' || role === 'onboarding_representative') && (
                        <div className="dashboard__wrapper">
                            {data?.data?.total_count ? (
                                Array.isArray(data?.data?.kanban) &&
                                data?.data?.kanban?.map((elem: PhaseI) => {
                                    return <KanbanItemSU item={elem} />;
                                })
                            ) : (
                                <>
                                    {isLoading ? (
                                        <></>
                                    ) : (
                                        <div className="noTasks">
                                            <img src={NoDataOnDashboard} />
                                            <div className="noTasks__title">No active tenders yet</div>
                                            <div className="noTasks__subtitle">
                                                Сurrent tenders will be displayed here
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {}
                </div>
            </Spin>
            {isOpenCreateTenderModal && (
                <ModalTender
                    type="create"
                    openModal={isOpenCreateTenderModal}
                    setOpenModal={setIsOpenCreateTenderModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                />
            )}
            {isOpenEditTenderModal && (
                <ModalTender
                    type="edit"
                    openModal={isOpenEditTenderModal}
                    setOpenModal={setIsOpenEditTenderModal}
                    messageApi={messageApi}
                    data={dataTender?.data}
                    setTrigger={setTrigger}
                />
            )}
            {openCancelTenderModal && (
                <CancelTenderModal
                    openModal={openCancelTenderModal}
                    setOpenModal={setOpenCancelTenderModal}
                    setTrigger={setTrigger}
                    selectedTender={selectedTender}
                />
            )}
        </>
    );
};
