import { SupplierI, taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, useEffect, useState } from 'react';
import plus from '../../../assets/Plus, Add.svg';
import SupplierListIcon from '../../../assets/SupplierListIcon.svg';
import { AddSupplierModal } from '../../Modals/AddSupplierModal';
import done from '../../../assets/Done, Check.svg';
import edit from '../../../assets/editAdminIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';

import deleteIcon from '../../../assets/DeleteIcon.svg';
import messageGrey from '../../../assets/messageGrey.svg';

import attantion from '../../../assets/point.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import plusdisable from '../../../assets/plus-disable.svg';
import stopImage from '../../../assets/stopImage.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';

import { Button, Modal, Tooltip } from 'antd';
import { useDeleteSupplierMutation, useShortlistSupplierMutation } from '../../../api/tenders';
import { RejectSupplierModal } from '../../Modals/RejectSupplierModal';
import { Loader } from '../../Loader/Loader';
import { RejectIncumbentSupplier } from '../../Modals/RejectIncumbentSupplier';
import { AwardSuppliserModal } from '../../Modals/AwardSuppliserModal';
import { downloadFunc } from '../features';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const SupplierList: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isCreateSupplierModal, setIsCreateSupplierModal] = useState(false);
    const [isRejectModal, setIsRejectModal] = useState(false);
    const [isRejectIncumbentModal, setIsRejectIncumbentModal] = useState(false);
    const [SupplierData, setSupplierData] = useState<SupplierI | null>(null);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierI | null>(null);
    const [shortlist, { data: shortlistData, isSuccess, isLoading: isLoadingShortList }] =
        useShortlistSupplierMutation();
    const [deleteSupplier, { data: deletedData, isSuccess: isSuccessDeleted, isLoading: isLoadingDelete }] =
        useDeleteSupplierMutation();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isSuppAwardModal, setIsSuppAwardModal] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setTrigger((prev: boolean) => !prev);
            messageApi?.success(shortlistData?.message);
        }
        if (isSuccessDeleted) {
            setTrigger((prev: boolean) => !prev);
            setIsDeleteModal(false);
            messageApi?.success(deletedData?.message);
            setSupplierData(null);
        }
    }, [isSuccess, isSuccessDeleted]);

    const shortlistFunc = async (id: number) => {
        try {
            const dataToPost = {
                tenderId: data.id,
                supplierId: id
            };
            shortlist(dataToPost);
        } catch {}
    };

    const deleteSupplierFunc = async () => {
        if (isLoadingDelete) return;
        try {
            const dataToPost = {
                tenderId: data.id,
                supplierId: SupplierData?.id
            };
            deleteSupplier(dataToPost);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length > 0 &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'evaluate_1_2_suppliers') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved && !data.suppliers.length) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role !== 'super_admin' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setIsCreateSupplierModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && !elem.approved && data.suppliers.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            {role !== 'super_admin' && (
                                                <img
                                                    src={plus}
                                                    className="task__plus"
                                                    style={{ margin: '0' }}
                                                    onClick={() => {
                                                        setSupplierData(null);
                                                        setIsCreateSupplierModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{elem.email}</div>
                                                            </div>
                                                            {role !== 'super_admin' && (
                                                                <div className="supplier__btn-wrapper">
                                                                    <img
                                                                        src={edit}
                                                                        className="supplier__btn"
                                                                        onClick={() => {
                                                                            setIsCreateSupplierModal(true);
                                                                            setSupplierData(elem);
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={deleteIcon}
                                                                        className="supplier__btn delete"
                                                                        onClick={() => {
                                                                            setSupplierData(elem);
                                                                            setIsDeleteModal(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && !elem.approved && data.suppliers.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{elem.email}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr' && data.suppliers.length) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {data?.phase_tasks?.[0].files.tender_representative?.[0]?.uuid &&
                                                role !== 'super_admin' ? (
                                                    <img
                                                        src={plus}
                                                        className="task__plus"
                                                        onClick={() => {
                                                            setIsCreateSupplierModal(true);
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        {role !== 'super_admin' && (
                                                            <Tooltip title="To add new suppliers, you need update Supplier Report Card first!">
                                                                <img src={plusdisable} />
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{elem.email}</div>
                                                            </div>
                                                            {elem.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted by School User
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.status === 'new' && role !== 'super_admin' && (
                                                                <div className="supplier__btn-wrapper">
                                                                    <img
                                                                        src={edit}
                                                                        className="supplier__btn"
                                                                        onClick={() => {
                                                                            setIsCreateSupplierModal(true);
                                                                            setSupplierData(elem);
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={deleteIcon}
                                                                        className="supplier__btn delete"
                                                                        onClick={() => {
                                                                            setSupplierData(elem);
                                                                            setIsDeleteModal(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {elem.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            if (elem.status === 'pending_su' && data.suppliers.length) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {elem.status === 'new' && (
                                                                <div className="task-complete__title-row">
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__decline ${
                                                                                data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? ''
                                                                                    : 'disable'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setTaskId(elem.id);
                                                                                    setSelectedSupplier(elem);
                                                                                    if (elem.incumbent) {
                                                                                        setIsRejectIncumbentModal(true);
                                                                                    } else {
                                                                                        setIsRejectModal(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </div>
                                                                    </Tooltip>

                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__approved ${
                                                                                !data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? 'disabled-blue-button'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (isLoadingShortList) return;
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    shortlistFunc(elem.id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Shortlist
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            {elem.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__approved ${
                                                                                !data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? 'disabled-blue-button'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (isLoadingShortList) return;
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    shortlistFunc(elem.id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Shortlist
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            {elem.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__decline ${
                                                                                data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? ''
                                                                                    : 'disable'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setTaskId(elem.id);
                                                                                    setSelectedSupplier(elem);
                                                                                    if (elem.incumbent) {
                                                                                        setIsRejectIncumbentModal(true);
                                                                                    } else {
                                                                                        setIsRejectModal(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </div>
                                                                    </Tooltip>

                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {elem.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {elem.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {elem.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                    if (elem.action === 'evaluate_3_suppliers') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' || elem.status === 'pending_su') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{elem.email}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({item.name_prefix}) {item.name}
                                                                    {item.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{item.email}</div>
                                                            </div>
                                                            {item.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted by School User
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.status === 'new' && role !== 'super_admin' && (
                                                                <div className="supplier__btn-wrapper">
                                                                    <img
                                                                        src={edit}
                                                                        className="supplier__btn"
                                                                        onClick={() => {
                                                                            setIsCreateSupplierModal(true);
                                                                            setSupplierData(item);
                                                                        }}
                                                                    />
                                                                    <img
                                                                        src={deleteIcon}
                                                                        className="supplier__btn delete"
                                                                        onClick={() => {
                                                                            setSupplierData(item);
                                                                            setIsDeleteModal(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {item.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            if (elem.status === 'pending_su') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({item.name_prefix}) {item.name}
                                                                    {item.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {item.status === 'new' && (
                                                                <div className="task-complete__title-row">
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__decline ${
                                                                                data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? ''
                                                                                    : 'disable'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setTaskId(item.id);
                                                                                    setSelectedSupplier(item);
                                                                                    if (item.incumbent) {
                                                                                        setIsRejectIncumbentModal(true);
                                                                                    } else {
                                                                                        setIsRejectModal(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </div>
                                                                    </Tooltip>

                                                                    <Tooltip
                                                                        title={
                                                                            'To Reject or Shortlist the suppliers, you need download Supplier Report Card first!'
                                                                        }
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__approved ${
                                                                                !data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? 'disabled-blue-button'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (isLoadingShortList) return;
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    shortlistFunc(item.id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Shortlist
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            {item.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__approved ${
                                                                                !data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? 'disabled-blue-button'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (isLoadingShortList) return;
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    shortlistFunc(item.id);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Shortlist
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            {item.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__decline ${
                                                                                data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? ''
                                                                                    : 'disable'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setTaskId(item.id);
                                                                                    setSelectedSupplier(item);
                                                                                    if (item.incumbent) {
                                                                                        setIsRejectIncumbentModal(true);
                                                                                    } else {
                                                                                        setIsRejectModal(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </div>
                                                                    </Tooltip>

                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {item.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {elem.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {elem.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Shortlisted
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {elem?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {elem.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                    if (elem.action === 'evaluate_4_suppliers') {
                        if (role !== 'school_user') {
                            if (
                                (elem.status === 'pending_tr' || elem.status === 'pending_su') &&
                                data?.suppliers[0].status !== 'shortlisted' &&
                                data?.suppliers[0].status !== 'rejected'
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((elem: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({elem.name_prefix}) {elem.name}
                                                                    {elem.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{elem.email}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' ||
                                (elem.status === 'pending_su' &&
                                    (data?.suppliers[0].status === 'shortlisted' ||
                                        data?.suppliers[0].status === 'rejected'))
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {elem.status === 'pending_su' && (
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI, index: number) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({item.name_prefix}) {item.name}
                                                                    {item.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="supplier__email">{item.email}</div>
                                                            </div>
                                                            {item.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded by School User
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item.status === 'shortlisted' && (
                                                            <div className="supplier__wrapper">
                                                                <div className="task-complete__info">
                                                                    <div className="task-complete__wrap column">
                                                                        <div className="task-complete__name">
                                                                            Received File
                                                                        </div>
                                                                        <div className="task-complete__value file">
                                                                            <img
                                                                                src={ReceivedFile}
                                                                                className="upload-download-icon"
                                                                            />

                                                                            {
                                                                                item.files.su_evaluate_4_awarded_file
                                                                                    ?.name
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div
                                                                            className={`task-complete__download-btn `}
                                                                            onClick={() => {
                                                                                downloadFunc(
                                                                                    item.files
                                                                                        .su_evaluate_4_awarded_file
                                                                                        ?.uuid,
                                                                                    setTrigger,
                                                                                    messageApi
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={downloadIcon}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            Download
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="supplier__wrapper">
                                                            {item.comments.su_rejected_comment && (
                                                                <>
                                                                    <div
                                                                        className="task-complete__wrap"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <div className="task-complete__value school-user">
                                                                            <img src={messageGrey} />
                                                                            {item.comments.su_rejected_comment}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {item.comments.su_awarded_comment && (
                                                                <>
                                                                    <div
                                                                        className="task-complete__wrap"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        <div className="task-complete__value school-user">
                                                                            <img src={messageGrey} />
                                                                            {item.comments.su_awarded_comment}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        } else {
                            if (elem.status === 'pending_su') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({item.name_prefix}) {item.name}
                                                                    {item.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {item.status === 'new' && (
                                                                <div className="task-complete__title-row">
                                                                    <Tooltip
                                                                        title="To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__decline ${
                                                                                data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? ''
                                                                                    : 'disable'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setTaskId(item.id);
                                                                                    setSelectedSupplier(item);

                                                                                    setIsRejectModal(true);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </div>
                                                                    </Tooltip>

                                                                    <Tooltip
                                                                        title={
                                                                            'To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!'
                                                                        }
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__approved ${
                                                                                !data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? 'disabled-blue-button'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (isLoadingShortList) return;
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setIsSuppAwardModal(true);
                                                                                    setSelectedSupplier(item);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Award
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            {item.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                    <Tooltip
                                                                        title={
                                                                            'To Reject or Aword the suppliers, you need download Supplier Report Card  and Letter of Intent template first!'
                                                                        }
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__approved ${
                                                                                !data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? 'disabled-blue-button'
                                                                                    : ''
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (isLoadingShortList) return;
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setIsSuppAwardModal(true);
                                                                                    setSelectedSupplier(item);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Award
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                            {item.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <Tooltip
                                                                        title="To Reject or Shortlist the suppliers, you need download Supplier Report Card first!"
                                                                        overlayClassName={
                                                                            !data?.phase_tasks?.[0]?.file_downloaded
                                                                                ? 'custom-tooltip-disabled'
                                                                                : 'custom-tooltip'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`task-complete__decline ${
                                                                                data?.phase_tasks?.[0]?.file_downloaded
                                                                                    ? ''
                                                                                    : 'disable'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    data?.phase_tasks?.[0]
                                                                                        ?.file_downloaded
                                                                                ) {
                                                                                    setSelectedSupplier(item);
                                                                                    setTaskId(item.id);
                                                                                    setIsRejectModal(true);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </div>
                                                                    </Tooltip>

                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__wrapper">
                                                            {item.status === 'shortlisted' && (
                                                                <div className="supplier__additional-info">
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Uploaded File
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            <img
                                                                                src={ReceivedFile}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            {
                                                                                item.files.su_evaluate_4_awarded_file
                                                                                    ?.name
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {item.comments.su_awarded_comment && (
                                                                        <div className="task-complete__info-wrap">
                                                                            <div className="task-complete__wrap">
                                                                                <div className="task-complete__name">
                                                                                    Comment
                                                                                </div>
                                                                            </div>
                                                                            <div className="task-complete__wrap">
                                                                                <div className="task-complete__value">
                                                                                    {item.comments.su_awarded_comment}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {item.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            data.can_send_task ? 'green' : ''
                                        }`}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>

                                        <div className="task-complete__supplier-list">
                                            {data?.suppliers.map((item: SupplierI) => {
                                                return (
                                                    <div className="task-complete__supplier supplier">
                                                        <div className="supplier__wrapper">
                                                            <div className="supplier__name-info">
                                                                <div className="supplier__name">
                                                                    ({item.name_prefix}) {item.name}
                                                                    {item.incumbent && (
                                                                        <div className="supplier__incumbent">
                                                                            <img src={attantion} />
                                                                            Incumbent Supplier
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {item.status === 'rejected' && (
                                                                <div className="task-complete__title-row">
                                                                    <div
                                                                        className="task-complete__decline-active"
                                                                        style={{ margin: '0 15px 0 0' }}
                                                                    >
                                                                        <img src={stopImage} />
                                                                        Rejected
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item.status === 'shortlisted' && (
                                                                <div className="task-complete__title-row">
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__wrapper">
                                                            {item.status === 'shortlisted' && (
                                                                <div className="supplier__additional-info">
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Uploaded File
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            <img
                                                                                src={ReceivedFile}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            {
                                                                                item.files.su_evaluate_4_awarded_file
                                                                                    ?.name
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {item.comments.su_awarded_comment && (
                                                                        <div className="task-complete__info-wrap">
                                                                            <div className="task-complete__wrap">
                                                                                <div className="task-complete__name">
                                                                                    Comment
                                                                                </div>
                                                                            </div>
                                                                            <div className="task-complete__wrap">
                                                                                <div className="task-complete__value">
                                                                                    {item.comments.su_awarded_comment}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item?.comments?.su_rejected_comment && (
                                                            <div
                                                                className="task-complete__info-wrap"
                                                                style={{ width: '100%' }}
                                                            >
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">Comment</div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {item.comments.su_rejected_comment}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {isCreateSupplierModal && (
                <AddSupplierModal
                    openModal={isCreateSupplierModal}
                    setOpenModal={setIsCreateSupplierModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                />
            )}
            {isRejectModal && (
                <RejectSupplierModal
                    openModal={isRejectModal}
                    setOpenModal={setIsRejectModal}
                    setTrigger={setTrigger}
                    selectedSupplier={selectedSupplier}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                />
            )}
            {isRejectIncumbentModal && (
                <RejectIncumbentSupplier
                    openModal={isRejectIncumbentModal}
                    setOpenModal={setIsRejectIncumbentModal}
                    setTrigger={setTrigger}
                    selectedSupplier={selectedSupplier}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setIsRejectModal={setIsRejectModal}
                />
            )}
            {isDeleteModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteSupplierFunc}>
                                {isLoadingDelete ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete <b>{SupplierData?.name}</b>?
                        </div>
                    </div>
                </Modal>
            )}

            {isSuppAwardModal && (
                <AwardSuppliserModal
                    openModal={isSuppAwardModal}
                    setOpenModal={setIsSuppAwardModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    selectedSupplier={selectedSupplier}
                    specialFormats={['doc', 'docx', 'pdf']}
                />
            )}
        </>
    );
};
