import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, SetStateAction, useState } from 'react';
import { useApproveTaskMutation, useResetActionMutation } from '../../../api/tenders';
import { Button, Modal, Tooltip } from 'antd';
import { Loader } from '../../Loader/Loader';
import { downloadFileFunc } from '../../../api/axiosCruds';

import messageGrey from '../../../assets/messageGrey.svg';
import stopImage from '../../../assets/stopImage.svg';
import edit from '../../../assets/editAdminIcon.svg';
import plus from '../../../assets/Plus, Add.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import СompanyВocumentationIcon from '../../../assets/СompanyВocumentationIcon.svg';
import done from '../../../assets/Done, Check.svg';

import { SuppliersFilesModal } from '../../Modals/SuppliersFilesModal';
import { RejectNegotiatedSupplyAgreement } from '../../Modals/RejectNegotiatedSupplyAgreement';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const CompanyDocumentation: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isModal, setIsModal] = useState(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [resetAction, { isLoading: isLoadingResetAction }] = useResetActionMutation();
    const [prevState, setPrevState] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [approveTask] = useApproveTaskMutation();
    const [isDeclineModal, setIsDeclineModal] = useState(false);

    const deleteFileFunc = async () => {
        try {
            const formData: any = {
                tenderId: data.id,
                taskId: taskId,
                data: { action: 'decline' }
            };

            await resetAction(formData).unwrap();

            await setIsDeleteFileModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        setLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                downloadPDF(response, name);
                setLoading(false);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'award_company_documentation') {
                        if (role !== 'school_user') {
                            if (
                                elem.status === 'pending_tr' &&
                                !elem.approved &&
                                !elem.declined &&
                                !elem.files.suppliers[0].file_name
                            ) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role !== 'super_admin' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem.files.suppliers[0].file_name) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>

                                        <>
                                            {elem?.files?.sent_su?.[0]?.name && (
                                                <div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Received File</div>
                                                    </div>{' '}
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.sent_su[0].name}
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                downloadFunc(elem.files.sent_su[0].uuid, elem.id);
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {elem.comments.school_user && (
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.school_user}
                                                    </div>
                                                </div>
                                            )}
                                        </>

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded Files</div>
                                                </div>
                                                {elem.files.suppliers.map((elem: any) => {
                                                    return (
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {elem.comments.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setPrevState(true);
                                                        setIsModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'pending_su' &&
                                !elem.approved &&
                                !elem.declined &&
                                elem.files.sent_suppliers.length
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>

                                        <>
                                            {elem?.files?.sent_su?.[0]?.name && (
                                                <div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Received File</div>
                                                    </div>{' '}
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.sent_su[0].name}
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                downloadFunc(elem.files.sent_su[0].uuid, elem.id);
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {elem.comments.school_user && (
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.school_user}
                                                    </div>
                                                </div>
                                            )}
                                        </>

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded Files</div>
                                                </div>
                                                {elem.files?.suppliers &&
                                                    elem.files?.suppliers?.map((elem: any) => {
                                                        return (
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    <div>
                                                                        <img
                                                                            src={ReceivedFile}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        {elem.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}

                                                {elem.comments.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>

                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Uploaded Files</div>
                                                </div>
                                                {elem.files.suppliers.map((elem: any) => {
                                                    return (
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {elem.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {elem.comments.tender_representative_sent && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments.tender_representative_sent}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={edit}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setPrevState(true);
                                                        setIsModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'completed') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Approved by SU
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Received Files</div>
                                                </div>
                                                {elem.files.sent_suppliers.map((item: any) => {
                                                    return (
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {item.name}

                                                                    <div
                                                                        className={`task-complete__download-btn `}
                                                                        onClick={() => {
                                                                            downloadFunc(item.file_uuid, elem.id);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={downloadIcon}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        Download
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">My Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments.tender_representative_sent}
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (
                                (elem.status === 'pending_su' ||
                                    elem.status === 'completed' ||
                                    elem.status === 'need_resend_tr') &&
                                !elem.approved &&
                                !elem.declined
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Received Files</div>
                                                </div>
                                                {elem.files.sent_suppliers.map((item: any) => {
                                                    return (
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {item.name}

                                                                    <div
                                                                        className={`task-complete__download-btn `}
                                                                        onClick={() => {
                                                                            downloadFunc(item.file_uuid, elem.id);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={downloadIcon}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        Download
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {elem.comments.tender_representative_sent && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value school-user">
                                                                <img src={messageGrey} />
                                                                {elem.comments.tender_representative_sent}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}

            {isModal && (
                <SuppliersFilesModal
                    openModal={isModal}
                    setOpenModal={setIsModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    name={'Company Documentation'}
                    specialFormats={['zip']}
                />
            )}
            {isDeclineModal && (
                <RejectNegotiatedSupplyAgreement
                    openModal={isDeclineModal}
                    setOpenModal={setIsDeclineModal}
                    setTrigger={setTrigger}
                    data={data}
                    taskId={taskId}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingResetAction ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
