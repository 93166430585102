import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';
import i from '../../assets/i.svg';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { useUploadTpaMutation } from '../../api/users';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    subtitle: string;
    note: string;
    file: UploadFile[];
    setFile: Dispatch<SetStateAction<UploadFile[]>>;
    type?: string;
    data?: any;
    setTrigger?: any;
}

interface FormDataProp {
    file: UploadFile;
}

const validationSchema = Yup.object().shape({
    file: Yup.array().of(Yup.object().shape({})).required('')
});

export const UploadFileSupplier: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    title,
    subtitle,
    note,
    file,
    setFile,
    type,
    data,
    setTrigger
}) => {
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [fileErr, setFileErr] = useState('');
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [localFile, setLocalFile] = useState<UploadFile[]>([]);
    const [uploadTpaFile, { isLoading: isLoadingTpa, isSuccess }] = useUploadTpaMutation();

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            message.success('The file has been uploaded successfully');
            setTrigger();
            setOpenModal(false);
        }
    }, [isSuccess]);

    const handleFormSubmit = () => {
        if (type === 'uploadTpaFile') {
            if (localFile?.length) {
                const formData = new FormData();
                const file: any = localFile?.[0]?.originFileObj;
                formData.append('file', file);
                const fileData = {
                    id: data?.id,
                    data: formData
                };
                uploadTpaFile(fileData);
            } else {
                setFileErr('You can upload files in the following formats: pdf with a maximum size of 100MB');
            }
        } else {
            if (localFile?.length) {
                setFile(localFile);
                setOpenModal(false);
            } else {
                setFileErr('You can upload files in the following formats: pdf with a maximum size of 100MB');
            }
        }
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoadingTpa ? <Loader /> : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">{title}</div>
                    </div>
                    {note && (
                        <div className="modal__note">
                            <img src={i} />
                            <div>{note}</div>
                        </div>
                    )}

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            {subtitle}
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="file" rules={yupSync('file', validationSchema, true)}>
                                <FilePicker
                                    disabled={isLoadingTpa ? true : false}
                                    fileList={localFile}
                                    onChange={setLocalFile}
                                    error={fileErr}
                                    setError={setFileErr}
                                    specialFormats={['pdf']}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
