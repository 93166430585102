import { useState, FC, useEffect, SetStateAction } from 'react';
import { useSendTaskMutation } from '../../api/tenders';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { useAppSelector } from '../../hooks';
import { Button, Tooltip, message } from 'antd';
import { TenderClosingDate } from './LaunchPhaseComponents/TenderClosingDate';

import arrow from '../../assets/arrow-right-white.svg';
import attention from '../../assets/attention.svg';

import moment from 'moment';
import './phases.scss';
import { SupplierListNew } from './DesignPhaseComponents/SupplierListNew';
import { RegistrationClosingDate } from './LaunchPhaseComponents/RegistrationClosingDate';

interface PropsPhase {
    data: tenderDataProps;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LaunchPhase: FC<PropsPhase> = ({ data, setTrigger, setConfirmModal }) => {
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);

    const [
        sendTask,
        { data: dataSendTask, isError: isErrorSendTask, isSuccess: isSuccessSendTask, isLoading: isLoadingSendTask }
    ] = useSendTaskMutation();

    const [messageApi, contextHolder] = message.useMessage();

    const sendTaskFunc = async () => {
        if (
            data?.phase_tasks[0].action === 'launch_closing_date' ||
            data?.phase_tasks?.[0]?.action === 'launch_registration_closing_date'
        ) {
            let formData = {};

            formData = {
                id: data?.id
            };
            try {
                await sendTask(formData).unwrap();
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    useEffect(() => {
        if (isErrorSendTask) {
            messageApi.error('Something went wrong, please try again');
        }
        if (isSuccessSendTask) {
            messageApi.success(dataSendTask.message);
        }
    }, [isErrorSendTask, isSuccessSendTask]);

    const today = new Date();
    const RegistrationClosing = data?.tasks_deadline_at ? new Date(data?.tasks_deadline_at) : null;

    return (
        <>
            {contextHolder}
            <div className={`tendermain__block tasks`}>
                {role !== 'school_user' && (
                    <div className="tasks__header define">
                        <div className="count-wrapper">
                            Task Centre
                            {role !== 'onboarding_representative' && (
                                <div className="count">{data?.phase_task_count}</div>
                            )}
                        </div>
                        <div className="tasks__deadline-wrapper">
                            <img src={attention} />
                            {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                            {moment(data?.phase_deadline_at).format('YYYY-MM-DD')}
                        </div>
                    </div>
                )}
                {role === 'school_user' && (
                    <div className="tasks__header design">
                        <div className="count-wrapper">
                            Task Centre
                            <div className="count">{data?.phase_task_count}</div>
                        </div>
                        {data?.phase_tasks?.[0]?.action === 'design_document' &&
                            data?.phase_tasks?.[0]?.status === 'pending_su' && (
                                <div className="tasks__deadline-wrapper">
                                    <img src={attention} />
                                    {screenWidth > 500 ? 'Task' : ''} Deadline -{' '}
                                    {moment(data?.tasks_deadline_at).format('YYYY-MM-DD')}
                                </div>
                            )}
                    </div>
                )}

                <div className="tasks__list">
                    <TenderClosingDate data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                    <RegistrationClosingDate data={data} role={role} messageApi={messageApi} setTrigger={setTrigger} />
                    <SupplierListNew data={data} role={role} setTrigger={setTrigger} messageApi={messageApi} />
                </div>
                {role === 'tender_representative' &&
                    !data.can_update_phase &&
                    data?.phase_tasks?.[0]?.action === 'launch_closing_date' &&
                    !data?.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Notify School <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    !data.can_update_phase &&
                    data?.phase_tasks?.[0]?.action === 'launch_closing_date' &&
                    data?.can_update_phase && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Next Phase <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    !data.can_update_phase &&
                    data?.phase_tasks?.[0]?.action === 'launch_registration_closing_date' &&
                    ((RegistrationClosing && RegistrationClosing > today) || !RegistrationClosing) && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    style={{ width: '180px' }}
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Notify Suppliers <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' &&
                    !data.can_update_phase &&
                    data?.phase_tasks?.[0]?.action === 'launch_registration_closing_date' &&
                    RegistrationClosing &&
                    RegistrationClosing <= today && (
                        <div className="tasks__btn-wrap">
                            <div></div>

                            <Tooltip
                                title="To send this task, you need to complete all the Task Activities"
                                overlayClassName={` ${
                                    !data?.can_send_task ? 'custom-tooltip-disabled' : 'custom-tooltip'
                                }`}
                            >
                                <Button
                                    className={`tasks__next-btn ${data?.can_send_task ? '' : 'disabled'}`}
                                    type="primary"
                                    style={{ width: '190px' }}
                                    onClick={() => {
                                        if (!data?.can_send_task) {
                                            return;
                                        } else {
                                            if (!isLoadingSendTask) {
                                                sendTaskFunc();
                                            }
                                        }
                                    }}
                                >
                                    Close Registration <img src={arrow} />
                                </Button>
                            </Tooltip>
                        </div>
                    )}
                {role === 'tender_representative' && data.can_update_phase && (
                    <div className="tasks__btn-wrap">
                        <div></div>
                        <Button
                            className={`tasks__next-btn`}
                            type="primary"
                            onClick={() => {
                                setConfirmModal(true);
                            }}
                        >
                            Next Phase <img src={arrow} />
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};
