import { FC, useEffect, useState } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { SupplierI, tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';
import i from '../../assets/i.svg';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';
import { useRejectSupplierMutation } from '../../api/tenders';
import { useRejectDocumentsSupplierMutation } from '../../api/users';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: any;
    data: any;
    type: string;
}

const validationSchema = Yup.object().shape({
    comment: Yup.string()
        .required('This is a required field')
        .test('not-only-spaces', 'This is a required field', (value) => {
            return value.trim() !== '';
        })
});

export const RejectDocSuppModal: FC<ModalProps> = ({ openModal, setOpenModal, setTrigger, data, type }) => {
    const [rejectDocs, { data: rejectedData, isLoading, isSuccess }] = useRejectDocumentsSupplierMutation();

    const [form] = Form.useForm();

    useEffect(() => {
        if (isSuccess) {
            setOpenModal(false);
            setTrigger();
            message?.success(rejectedData?.message);
        }
    }, [isSuccess]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const dataToPost = {
                    id: data.id,

                    data: {
                        comment: values.comment
                    }
                };
                rejectDocs(dataToPost);
            })
            .catch((err) => console.log('err', err));
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    setOpenModal(false);
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            block
                            key="submit"
                            type="primary"
                            onClick={() => {
                                if (isLoading) return;
                                handleFormSubmit();
                            }}
                        >
                            {isLoading ? <Loader /> : <>{type === 'reject' ? 'Yes, Reject' : 'Yes, Request'}</>}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">
                            {type === 'reject' ? 'Reject' : 'Request'} Company Documents?
                        </div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        {type === 'reject' && (
                            <div className="main__subtitle">
                                Are you sure, you want to Reject All Company Documents?
                            </div>
                        )}
                        {type === 'reject' && (
                            <div className="modal__note" style={{ margin: '20px 0' }}>
                                <img src={i} />
                                <div>{'After rejection all documents will be deleted from the system'}</div>
                            </div>
                        )}

                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="comment" rules={yupSync('comment', validationSchema, true)}>
                                <FloatTextArea
                                    disabled={isLoading ? true : false}
                                    floatLabel={
                                        type === 'reject' ? 'Provide a reason for rejection' : 'Provide a reason'
                                    }
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                    required
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};
