import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from './routes';
import { useAppDispatch } from './hooks';
import { setAuthUser } from './reducers/auth/authSlice';

function App() {
    // TODO: config antd theme
    const router = createBrowserRouter(routes);
    const dispatch = useAppDispatch();
    const authUser = JSON.parse(localStorage.getItem('authUser') || sessionStorage.getItem('authUser') || '{}');

    useEffect(() => {
        dispatch(setAuthUser(authUser));
    }, []);

    return <RouterProvider router={router} />;
}

export default App;
