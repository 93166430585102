import close from '../../assets/RoundedClose.svg';
import { Button, message, Modal } from 'antd';
import React, { useEffect } from 'react';
import { useDeleteSchoolBulkMutation } from '../../api/schools';

interface ModalDeleteSchoolProps {
    open: boolean;
    id: number | number[] | null;
    onClose: () => void;
    onDelete: () => void;
    onDeleteBulk: (ids: number[]) => void;
    isDeleting: boolean;
    name?: string;
}

const ModalDeleteSchool = ({ open, onClose, id, name, onDelete, onDeleteBulk, isDeleting }: ModalDeleteSchoolProps) => {
    const [
        deleteUserBulkCheck,
        { isLoading: isDeletingBulkCheck, isError: isDeletingBulkCheckError, error: deletingBulkCheckError }
    ] = useDeleteSchoolBulkMutation();
    const [messageApi, contextHolder] = message.useMessage();

    const deleteCheck = async () => {
        try {
            await deleteUserBulkCheck({ ids: id as number[], action: 'check' }).unwrap();
        } catch (e: any) {
            if (e?.status === 422) {
            } else {
                messageApi.error('Something went wrong, please try again later');
            }
        }
    };

    useEffect(() => {
        if (Array.isArray(id)) {
            deleteCheck();
        }
    }, [open, id]);

    return (
        <>
            {contextHolder}
            <Modal
                centered
                open={open}
                closeIcon={<img src={close} alt={'close'} />}
                width={600}
                onCancel={() => onClose()}
                footer={
                    <div
                        style={{
                            display: 'flex',
                            justifyItems: 'space-between',
                            gap: '16px',
                            width: '100%'
                        }}
                    >
                        <Button key="back" className="cancel" onClick={() => onClose()} block>
                            Cancel
                        </Button>
                        <Button
                            className="quit"
                            onClick={() => {
                                Array.isArray(id) && id.length > 1 ? onDeleteBulk(id) : onDelete();
                            }}
                            loading={isDeleting || isDeletingBulkCheck}
                            block
                        >
                            Delete
                        </Button>
                    </div>
                }
            >
                <div className="modal__title">Delete user?</div>
                {name && (
                    <div className="modal-confirm modal-confirm__delete">
                        Are you sure you want to delete <strong>{`${name}`}</strong>?
                    </div>
                )}
                {isDeletingBulkCheckError && (
                    <div className="modal-confirm modal-confirm__delete">
                        The system will delete schools <strong>except</strong> the ones:
                        {/*@ts-ignore*/}
                        {deletingBulkCheckError?.data?.excluded.map((item) => (
                            <p>{item}</p>
                        ))}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ModalDeleteSchool;
