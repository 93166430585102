import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logoForHeader.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import { Button, Checkbox, Divider, Form, Tooltip } from 'antd';
import general from '../../assets/generalIcon.svg';
import supplierCreated from '../../assets/supplierCreated.svg';
import { useLazyAddTenderToSupplierQuery, useLazyCheckSupplierQuery } from '../../api/users';
import { FloatInput } from '../../components/Fields/FloatInput';
import { yupSync } from '../../utils';
import { PhoneNumberInput } from '../../components/Fields/PhoneNumberInput';
import * as Yup from 'yup';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { Loader } from '../../components/Loader/Loader';
import i from '../../assets/i.svg';
import Plus from '../../assets/Plus, Add.svg';
import '../SignUp/index.scss';
import Delete from '../../components/Icons/Delete';
import { FloatTextArea } from '../../components/Fields/FloatTextArea';
import EmailIcon from '../../assets/EmailIcon.svg';

interface ReferenceI {
    entity_name: string;
    contact_name: string;
    position_title: string;
    email: string;
    phone_number: string;
}

const validationSchema = Yup.object().shape({
    registrar_name: Yup.string().required('This is a required field'),
    name: Yup.string().required('This is a required field'),
    comment: Yup.string()
        .required('This is a required field')
        .test('comment', 'This is a required field', (value) => {
            const passwordRegex = /[^\s]/;
            return passwordRegex.test(value);
        }),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email field is required'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field')
});

export const EmailConfirmation = () => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    return (
        <>
            <div className="email_confirmation">
                <div className="email_confirmation__header header">
                    {screenWidth > 600 && <img src={logo} className="header__logo" />}
                    {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
                </div>
                <div className="email_confirmation__wrapper">
                    <img src={EmailIcon} />
                    <div className="email_confirmation__title">Email address confirmed</div>
                    <div className="email_confirmation__subtitle">
                        You have successfully confirmed your email address, and your company has been added to the
                        supplier list for this tender.
                    </div>
                    <div className="email_confirmation__add-text">
                        Going forward, you will receive periodic notifications to keep you informed of important updates
                        and next steps.
                    </div>
                </div>
            </div>
        </>
    );
};
