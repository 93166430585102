import complete from '../../assets/complete.svg';

export const AllTasksCompleted = () => {
    return (
        <div className="all_task_completed">
            <img src={complete} />
            <span>
                <b>All Your Tasks Are Complete!</b> No further action is required. <br />
                Feel free to review your previous tasks for reference. If you need to get in touch, just drop us a
                message.{' '}
            </span>
        </div>
    );
};
