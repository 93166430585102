import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../../assets/logo.svg';
import { message, Checkbox, Form, Button } from 'antd';
import { setAuthUser } from '../../../reducers/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import * as Yup from 'yup';
import { useLoginMutation } from '../../../api/auth';
import { FloatInput } from '../../../components/Fields/FloatInput';
import '../index.scss';
import { yupSync } from '../../../utils';
import { usePreventSpaceTyping } from '../../../hooks/usePreventSpaceTyping';
import { Loader } from '../../../components/Loader/Loader';
import { selectAuthUser } from '../../../reducers/auth/authSlice';
import moment from 'moment';

interface FormData {
    email: string;
    password: string;
    rememberMe?: boolean;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email is required'),
    password: Yup.string().required('Password field is required')
});

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch();
    const [login, { data, isSuccess, isLoading, originalArgs }] = useLoginMutation();
    const { handleKeyPress } = usePreventSpaceTyping();
    const [searchParams] = useSearchParams();
    const { authToken } = useAppSelector(selectAuthUser);

    useEffect(() => {
        if (authToken) {
            navigate('/dashboard');
        }
    }, [authToken]);

    useEffect(() => {
        if (!searchParams.get('status')) return;
        messageApi.success(
            <span style={{ width: '200px' }}>
                Awesome! The email has been changed. Don't forget to use the new email <br />
                {decodeURIComponent(searchParams.get('email') || '')} during Log in
            </span>
        );
    }, [searchParams]);

    useEffect(() => {
        if (isSuccess) {
            const user = data?.data;
            dispatch(
                setAuthUser({
                    id: '#user_id',
                    name: '#user_name',
                    email: originalArgs?.email,
                    authToken: user?.access_token,
                    rememberMe: originalArgs?.rememberMe
                })
            );
            navigate('/dashboard');
        }
    }, [isSuccess]);

    const handleSubmit = async () => {
        if (isLoading) return;
        try {
            const values = form.getFieldsValue();
            await login({
                email: values.email?.toLowerCase(),
                password: values.password,
                rememberMe: values.rememberMe
            }).unwrap();
        } catch (error: any) {
            if (error?.status === 401) {
                messageApi.error('The email/password combination is incorrect');
            } else {
                messageApi.error('Something went wrong, please try again later');
            }
        }
    };

    return (
        <>
            {contextHolder}
            <div className="auth">
                <div className="auth__logo-container">
                    <img src={logo} className="auth__logo" alt="logo" />
                </div>
                <div className="auth__form form">
                    <div className="form__title">Log in</div>
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            className="input-wrapper"
                            name="email"
                            rules={yupSync('email', validationSchema, true)}
                        >
                            <FloatInput
                                floatLabel="Email"
                                className="input"
                                maxLength={256}
                                onKeyPress={handleKeyPress}
                            />
                        </Form.Item>
                        <Form.Item
                            className="input-wrapper"
                            name="password"
                            rules={yupSync('password', validationSchema, true)}
                        >
                            <FloatInput
                                floatLabel="Password"
                                type="password"
                                className="input password"
                                onKeyPress={handleKeyPress}
                            />
                        </Form.Item>
                        <Form.Item className="auth__remember remember" name="rememberMe" valuePropName="checked">
                            <Checkbox className="checkbox">
                                <div className="remember__title">Remember me</div>
                            </Checkbox>
                        </Form.Item>

                        <Button type="primary" htmlType="submit" block>
                            {isLoading ? <Loader /> : 'Log In'}
                        </Button>
                    </Form>
                    <div className="auth__forgot" onClick={() => navigate('/forgot-password')}>
                        Forgot password?
                    </div>
                </div>
                <div className="auth__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
            </div>
        </>
    );
};
