import React from 'react';
import { Avatar } from 'antd';
import { colors } from '../../themes/colors';
import './index.scss';
import DefaultAvatar from '../../assets/DefaultAvatar.svg';

interface UserInfoProps {
    fullName: string;
    email: string;
    avatarUrl?: string;
    fullNameFallback?: string;
}

const UserInfo = ({ fullName, email, avatarUrl, fullNameFallback }: UserInfoProps) => {
    return (
        <div className="table_user__wrap">
            <Avatar
                size={40}
                src={avatarUrl ? avatarUrl : DefaultAvatar}
                style={{
                    color: 'white',
                    backgroundColor: colors.Texts_and_Icons.Secondary,
                    border: 'none',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600
                }}
            ></Avatar>
            <div className="table_user__data">
                <div className="table_user__name">{fullName || '-'}</div>
                <div className="table_user__email">{email}</div>
            </div>
        </div>
    );
};

export default UserInfo;
