import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from '../reducers/store';
import { stringify } from 'qs';

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.thehive.schooladvisor.co.za/api/v1';

const _baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.authToken;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        return headers;
    },
    paramsSerializer: (params) => {
        return stringify(params, { indices: false, arrayFormat: 'brackets', encode: false });
    }
});

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    let result = await _baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        api.dispatch({ type: 'auth/logout' });
    }
    return result;
};
