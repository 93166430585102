const colors = {
    Primary: {
        Dark: '#001965',
        Lite: '#4D5E93',
        White: '#E2E2E2'
    },
    Secondary: {
        Accent: '#E64A0D',
        Negative: '#E80A0F',
        Warning: '#E3A11F',
        Success: '#098E1D'
    },
    Texts_and_Icons: {
        Primary: '#001965',
        Secondary: '#4D5E93',
        Label: '#808CB2',
        Disabled: '#B3BAD1',
        White: '#E2E2E2',
        Accent: '#E64A0D',
        Accent_Disabled: '#F2A486'
    },
    Backgrounds: {
        Primary: '#FFFFFF',
        Secondary: '#F5F7FA',
        Tertiary: '#EAEFF5',
        Additional: '#C1D7EC',
        Hover: '#FAFBFC',
        Error: '#FDE6E7',
        Warning: '#FCF6E9',
        Success: '#E6F4E8'
    },
    Borders_and_Lines: {
        Primary: '#E6E8F0',
        Hover: '#B3BAD1',
        Secondary_or_Disabled: '#CCD1E0',
        Accent: '#001965',
        Error: '#EDB9B3'
    },
    Buttons: {
        Accent: '#E64A0D',
        Accent_Hover: '#E85C25',
        Accent_Pressed: '#EA6C3B',
        Accent_Disabled: '#F8C9B6',
        Main: '#001965',
        Main_Hover: '#334784',
        Main_Pressed: '#4D5E93',
        Main_Disabled: '#CCD1E0',
        Danger: '#E80A0F',
        Danger_Hover: '#EC3B3F',
        Danger_Pressed: '#EF5357',
        Danger_Disabled: '#FACECF'
    },
    Statuses: {
        New_Lead: '#D9F4E9',
        Calling: '#EAF1FE',
        Appointment: '#BED8FA',
        Door_Knock: '#FFE9D1',
        Dead_Lead: '#FFDBDB',
        Customer: '#EEE9FD',
        No_Show: '#EEEEEE',
        Exhaused: '#FFD1E1',
        Follow_Up: '#F9D1FF',
        Older: '#E2E2E2'
    }
};

export { colors };
