import { useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import type { SelectProps } from 'antd';
import './index.scss';
import { useClickOutside } from '../../hooks/useClickOutSide';
import search from '../../assets/Search.svg';
import schoolImage from '../../assets/schoolImage.svg';
import arrow from '../../assets/arrow.svg';
import close from '../../assets/Close.svg';
import noResult from '../../assets/noResult.svg';

interface OptionType {
    key?: string;
    name?: string;
    id?: number | string;
    value?: string;
}

interface FloatSelectProps extends SelectProps {
    required: boolean;
    floatLabel: string;
    hasError: () => boolean;
    setSearch?: React.Dispatch<React.SetStateAction<string>>;
    options: any;
    onChange?: any;
    search?: string;
    setChanged: React.Dispatch<React.SetStateAction<boolean>>;
    disabled?: boolean;
    image?: any;
}

export const Select = (props: FloatSelectProps) => {
    const [openSelect, setOpenSelect] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    let {
        floatLabel,
        placeholder,
        value,
        required,
        hasError,
        setSearch,
        options,
        onChange,
        search,
        setChanged,
        disabled,
        image,
        ...restProps
    } = props;
    if (!placeholder) placeholder = floatLabel;

    useClickOutside(ref, () => setOpenSelect(false));

    const isOccupied = openSelect || value;

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    <img src={value ? '' : arrow} className={'image'} />;

    return (
        <div className="float-label" ref={ref}>
            <div
                className={`${openSelect ? 'customSelect' : 'input-select'} ${hasError() && !value ? 'err' : ''} ${
                    disabled ? 'disable' : ''
                }`}
                onClick={() => {
                    if (disabled) return;
                    if (!openSelect) {
                        setOpenSelect(true);
                    }
                }}
            >
                <img
                    src={value ? close : arrow}
                    className={'image'}
                    onClick={(e) => {
                        if (disabled) return;
                        e.stopPropagation();
                        if (value) {
                            onChange(null);
                            setChanged(true);
                            setOpenSelect(false);
                        } else {
                            setOpenSelect((prev) => !prev);
                        }
                    }}
                />
                <label className={`${labelClass} ${image && !value ? 'with-image' : ''}`}>
                    {image && !value ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={image} style={{ margin: '0 8px 0 0' }} /> {isOccupied ? floatLabel : placeholder}{' '}
                            {required && <span className="red">*</span>}
                        </div>
                    ) : (
                        <>
                            {isOccupied ? floatLabel : placeholder} {required && <span className="red">*</span>}
                        </>
                    )}
                </label>

                {value && (
                    <div className={`select-option result`}>
                        <div className="select-option__wrap">
                            {image && value && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={image} style={{ margin: '0 8px 0 0' }} />
                                    <div className="select-option__name">{value.value ? value.value : value.name}</div>
                                </div>
                            )}
                            {!image && value && (
                                <div className="select-option__name">{value.value ? value.value : value.name}</div>
                            )}
                        </div>
                    </div>
                )}

                {openSelect && (
                    <>
                        <div className="customSelect__options">
                            {options?.length ? (
                                options?.map((elem: any) => {
                                    return (
                                        <div
                                            key={elem.id}
                                            className="select-option"
                                            onClick={() => {
                                                setOpenSelect(false);
                                                onChange(elem);
                                            }}
                                        >
                                            <div className="select-option__wrap">
                                                <div className="select-option__name">
                                                    {elem.value ? elem.value : elem.name}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="customSelect__no-result">
                                    <img src={noResult} />
                                    <div>No results found</div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
