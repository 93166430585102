import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ResetPasswordMutationParams } from './types/auth';

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api-dev.thehive.schooladvisor.co.za/api/v1';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Accept', 'application/json');
            return headers;
        }
    }),
    // TODO: add types for endpoints
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                body: credentials
            })
        }),
        forgotPassword: builder.mutation<string, string>({
            query: (email) => ({
                url: '/auth/forgot-password',
                method: 'POST',
                body: {
                    email
                }
            })
        }),
        resetPassword: builder.mutation<string, ResetPasswordMutationParams>({
            query: (credentials) => ({
                url: '/auth/reset-password',
                method: 'PUT',
                body: credentials
            })
        })
    })
});

export const { useLoginMutation, useForgotPasswordMutation, useResetPasswordMutation } = authApi;
