import { useState, useEffect } from 'react';
import logo from '../../assets/logo.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import Greate from '../../assets/Greate.svg';
import '../ExpiredLink/index.scss';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

export const EmailVarification = () => {
    const [searchParams] = useSearchParams();
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);

    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    return (
        <div className="home">
            <div className="home__logo-container">
                {screenWidth > 600 && <img src={logo} className="home__logo" />}
                {screenWidth <= 600 && <img src={logoPhone} className="home__logo" />}
            </div>
            <div className="home__main">
                <img src={Greate} className="home__image" />
                <div className="home__title">Great news!</div>
                <h3 className="home__subtitle">You are one step closer to changing your email on the Hive </h3>
                <div className="home__notif">
                    Now you just need to verify your new email. For that follow the link we sent you to{' '}
                    <b>{decodeURIComponent(searchParams.get('email') || '')}</b>
                </div>
            </div>
            <div className="home__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
        </div>
    );
};
