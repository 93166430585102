import React, { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logoForHeader.svg';
import logoPhone from '../../assets/logo-width-to600.svg';
import { Breadcrumb, Button, Checkbox, Form, message } from 'antd';
import arrowRight from '../../assets/arrow-right.svg';
import activeArrow from '../../assets/arrow-right-active.svg';
import general from '../../assets/generalIcon.svg';
import password from '../../assets/PasswordIcon.svg';
import ContactInfoIcon from '../../assets/ContactInfoIcon.svg';
import supplierCreated from '../../assets/supplierCreated.svg';
import './index.scss';
import DefaultAvatar from '../../assets/DefaultAvatar.svg';
import RoundedWhite from '../../assets/RoundedWhite.svg';
import { useCreateSupplierMutation, useLazyGetInvatationDataQuery, useSignUpMutation } from '../../api/users';
import { FloatInput } from '../../components/Fields/FloatInput';
import { yupSync } from '../../utils';
import { PhoneNumberInput } from '../../components/Fields/PhoneNumberInput';
import { FloatSelect } from '../../components/Fields/FloatSelect';
import * as Yup from 'yup';
import { useAppDispatch } from '../../hooks';
import { setAuthUser } from '../../reducers/auth/authSlice';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { Loader } from '../../components/Loader/Loader';
import { CustomErrorType } from '../../api/types/global';
import i from '../../assets/i.svg';
import { CustomMultiSelect } from '../../components/Fields/CustomMultiSelect';
import { useLazyGetAreasQuery } from '../../api/tenders';
import { useGetSchoolTypesQuery } from '../../api/common';

const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required('This is a required field'),
    first_name: Yup.string().required('This is a required field'),
    name: Yup.string().required('This is a required field'),
    trading_name: Yup.string(),
    website: Yup.string()
        .required('This is a required field')
        .matches(/^(https?:\/\/)/, 'Oops! Your link is not correct'),
    last_name: Yup.string().required('This is a required field'),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('This is a required field'),
    secondary_email: Yup.string().email('Enter your email: e.g. email@domain.com'),
    school: Yup.object().required('This is a required field'),
    b_bbee: Yup.object().required('This is a required field'),
    annual_revenue: Yup.object().required('This is a required field'),
    employees_number: Yup.object().required('This is a required field'),
    job: Yup.string().required('This is a required field'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field'),
    registration_num: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field'),

    password: Yup.string()
        .required('This is a required field')
        .test('password-requirements', '', (value) => {
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
            return passwordRegex.test(value);
        }),
    confirmPassword: Yup.string().required('This is a required field')
});

export const SignUp = () => {
    const [getData, { data, isError }] = useLazyGetInvatationDataQuery();
    const location = useLocation();
    const isSupplier = location.pathname.includes('supplier');

    const [
        signUp,
        { data: signUpUserData, isSuccess: signUpUserSuccess, isLoading: signUpUserLoading, error: errorsignUpUser }
    ] = useSignUpMutation();

    const [createSupplier, { isSuccess, isError: isErrorCreateSupplier, isLoading: isLoadingCreateSupplier }] =
        useCreateSupplierMutation();

    const types = {};

    const { data: dataSchoolTypes } = useGetSchoolTypesQuery(types);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [step, setStep] = useState('general');
    const [imageUrl, setImageUrl] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [form] = Form.useForm();
    const [fileAvatar, setFileAvatar] = useState('');
    const [changed, setChanged] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [formDataFormFirstStep, setFormDataFormFirstStep] = useState<any>();
    const dispatch = useAppDispatch();
    const [passwordErr, setPasswordErr] = useState(false);
    const [fileErr, setFileErr] = useState('');
    const { handleKeyPress } = usePreventSpaceTyping();

    const [selectedArea, setSelectedArea] = useState<any[]>([]);
    const [filtredAreas, setFiltredAreas] = useState<any[]>([]);

    const [selectedProvinnces, setSelectedProvinnces] = useState<any[]>([]);
    const [filtredProvinnces, setFiltredProvinnces] = useState<any[]>([]);

    const [selectedEmployees, setSelectedEmployees] = useState<any>();
    const [selectedRevenue, setSelectedRevenue] = useState<any>();
    const [selectedBbee, setSelectedBbee] = useState<any>();
    const [touched, setTouched] = useState({
        areas: false,
        provinces: false,
        employees: false,
        revenue: false,
        bbee: false
    });
    const [errors, setError] = useState({
        expense_areas: false,
        provinces: false,
        employees: false,
        revenue: false,
        bbee: false,
        agreement: false,
        registration_num: false
    });
    const [checkbox, setCheckbox] = useState(false);
    const [supplierData, setSupplierData] = useState<any>();

    useEffect(() => {
        if (isErrorCreateSupplier) {
            setError((prev) => ({ ...prev, registration_num: true }));
        }
    }, [isErrorCreateSupplier]);

    useEffect(() => {
        if (isSuccess) {
            setStep('contact_info');
            if (step === 'contact_info') {
                setStep('supplier_created');
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        if (dataSchoolTypes?.data?.expense_areas) {
            setFiltredAreas(dataSchoolTypes?.data?.expense_areas);
        }
    }, [dataSchoolTypes?.data?.expense_areas]);

    useEffect(() => {
        if (dataSchoolTypes?.data?.provinces) {
            setFiltredProvinnces(dataSchoolTypes?.data?.provinces);
        }
    }, [dataSchoolTypes?.data?.provinces]);

    const deleteItem = (
        id: string | number,
        setSelectedItems: Dispatch<SetStateAction<any[]>>,
        selectedItem: any[]
    ) => {
        const areas = [...selectedItem];
        const filteredArray: any = areas.filter((item) => item.id !== id);
        setSelectedItems(filteredArray);
    };

    const selectItem = (
        area: any,
        setFiltredArray: Dispatch<SetStateAction<any[]>>,
        setSelectedItems: Dispatch<SetStateAction<any[]>>,
        selectedItem: any[],
        data: any[]
    ) => {
        if (area.id === 'selectedAll') {
            const ids = data?.map((elem: any) => elem);
            setFiltredArray([]);
            setSelectedItems(ids);
        } else {
            const newSelectedAreas = [...selectedItem, area];
            setSelectedItems(newSelectedAreas);
        }
    };

    useEffect(() => {
        if (checkbox) {
            setError((prev) => ({ ...prev, agreement: false }));
        }
        if (!selectedArea.length && touched.areas) {
            setError((prev) => ({ ...prev, expense_areas: true }));
        } else {
            setError((prev) => ({ ...prev, expense_areas: false }));
        }
        if (!selectedEmployees && touched.employees) {
            setError((prev) => ({ ...prev, employees: true }));
        } else {
            setError((prev) => ({ ...prev, employees: false }));
        }
        if (!selectedRevenue && touched.revenue) {
            setError((prev) => ({ ...prev, revenue: true }));
        } else {
            setError((prev) => ({ ...prev, revenue: false }));
        }
        if (!selectedBbee && touched.bbee) {
            setError((prev) => ({ ...prev, bbee: true }));
        } else {
            setError((prev) => ({ ...prev, bbee: false }));
        }
        if (!selectedProvinnces.length && touched.provinces) {
            setError((prev) => ({ ...prev, provinces: true }));
        } else {
            setError((prev) => ({ ...prev, provinces: false }));
        }

        if (!dataSchoolTypes?.data?.expense_areas) return;
        const areas = [...dataSchoolTypes?.data?.expense_areas];
        const filteredArray: any = areas.filter(
            (item) =>
                !selectedArea.some((secondItem) => {
                    return secondItem.id === item.id;
                })
        );
        setFiltredAreas(filteredArray);
    }, [
        selectedArea,
        dataSchoolTypes?.data?.expense_areas,
        selectedEmployees,
        selectedProvinnces,
        selectedRevenue,
        selectedBbee,
        touched,
        checkbox
    ]);

    useEffect(() => {
        if (!dataSchoolTypes?.data?.provinces) return;
        const provinces = [...dataSchoolTypes?.data?.provinces];
        const filteredArray: any = provinces.filter(
            (item) =>
                !selectedProvinnces.some((secondItem) => {
                    return secondItem.id === item.id;
                })
        );
        setFiltredProvinnces(filteredArray);
    }, [selectedProvinnces, dataSchoolTypes?.data?.provinces]);

    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }

    window.addEventListener('resize', handleResize);

    useEffect(() => {
        if (isError) {
            navigate('/expired');
        }
    }, [isError]);

    useEffect(() => {
        if (!data) return;

        if (data?.data?.role?.title === 'School User') {
            form.setFieldsValue({
                //@ts-ignore
                first_name: data.data.first_name ? data.data.first_name : '',
                last_name: data.data.last_name ? data.data.last_name : '',
                email: data.data.email,
                phone_number: data.data.phone_number ? data.data.phone_number : '',
                school: data.data.school,
                job: data.data.job_title ? data.data.job_title : ''
            });
        }
        if (
            data?.data?.role?.title === 'Tender Representative' ||
            data?.data?.role?.title === 'Super Admin' ||
            data?.data?.role?.title === 'Onboarding Representative'
        ) {
            form.setFieldsValue({
                //@ts-ignore
                first_name: data.data.first_name ? data.data.first_name : '',
                last_name: data.data.last_name ? data.data.last_name : '',
                email: data.data.email
            });
        }
        setImageUrl(data.data.avatar);
    }, [data]);

    const getInvitationData = async () => {
        try {
            await getData(searchParams.get('token'));
        } catch {
            navigate('/expired');
        }
    };

    useEffect(() => {
        if (!searchParams.get('token')) {
            // navigate('/expired');
        } else {
            getInvitationData();
        }
    }, [searchParams]);

    const handleFileChange = (event: any) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg'];
        const maxFileSize = 5 * 1024 * 1024;
        const file = event.target.files[0];

        if (file && allowedTypes?.includes(file.type) && file.size < maxFileSize) {
            setChanged(true);
            setImageUrl(URL.createObjectURL(event.target.files[0]));
            setFileAvatar(event.target.files[0]);
            setFileErr('');
        } else {
            if (!allowedTypes?.includes(file.type)) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            }
            if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            }
            event.target.value = '';
        }
    };

    const clickUpload = () => {
        fileInputRef?.current?.click();
    };
    const validationHandler = async (params: any) => {
        const signUpData = {
            token: searchParams.get('token'),
            data: params
        };
        try {
            await signUp(signUpData);

            setStep('password');
        } catch {
            messageApi.error('Something went wrong!');
            // navigate('/expired');
        }
    };

    useEffect(() => {
        if (signUpUserSuccess && step === 'password' && signUpUserData) {
            dispatch(
                setAuthUser({
                    authToken: signUpUserData.data?.access_token,
                    id: null,
                    name: null,
                    email: null
                })
            );
            navigate('/dashboard');
        }
    }, [signUpUserSuccess, signUpUserData]);

    const signUpHandler = async (params: any) => {
        const signUpData = {
            token: searchParams.get('token'),
            data: params
        };
        try {
            await signUp(signUpData).unwrap();
            await messageApi.success('You have successfully registered');
        } catch {}
    };

    useEffect(() => {
        if (errorsignUpUser) {
            if ((errorsignUpUser as CustomErrorType)?.data?.message === 'The Password format is invalid') {
                messageApi.error('The Password format is invalid');
            }
        }
    }, [errorsignUpUser]);

    const handleSubmit = () => {
        let isValid = true;
        const errorObj = { ...errors };
        if (isSupplier) {
            if (step === 'contact_info' && !checkbox) {
                errorObj.agreement = true;
                isValid = false;
            }
            if (selectedArea.length === 0) {
                errorObj.expense_areas = true;
                isValid = false;
            }
            if (selectedProvinnces.length === 0) {
                errorObj.provinces = true;
                isValid = false;
            }
            if (!selectedEmployees) {
                errorObj.employees = true;
                isValid = false;
            }
            if (!selectedRevenue) {
                errorObj.revenue = true;
                isValid = false;
            }
            if (!selectedBbee) {
                errorObj.bbee = true;
                isValid = false;
            }
            setError(errorObj);
        }
        form.validateFields()
            .then((values: any) => {
                if (!isValid) return;
                if (step === 'general') {
                    if (isSupplier) {
                        const data = {
                            step: 1,
                            data: {
                                company: {
                                    name: values.name,
                                    trading_name: values.trading_name,
                                    registration_num: values.registration_num,
                                    website: values.website,
                                    expense_areas: selectedArea?.map((elem: any) => elem.id),
                                    provinces: selectedProvinnces?.map((elem: any) => elem.id),
                                    employees_number: selectedEmployees?.id,
                                    b_bbee: selectedBbee?.id,
                                    annual_revenue: selectedRevenue?.id
                                }
                            }
                        };

                        setSupplierData({
                            name: values.name,
                            trading_name: values.trading_name,
                            registration_num: values.registration_num,
                            website: values.website
                        });

                        createSupplier(data);
                    } else {
                        const formData = new FormData();
                        formData.append('step', step);
                        formData.append('first_name', values.first_name.trim());
                        formData.append('last_name', values.last_name.trim());
                        formData.append('role', data.data.role.name);

                        if (data?.data?.role?.title === 'School User') {
                            formData.append('phone_number', values.phone_number);
                            formData.append('job_title', values.job.trim());
                        }

                        if (changed && fileAvatar) {
                            formData.append('avatar', fileAvatar);
                        }
                        if (changed && !fileAvatar) {
                            formData.append('reset_avatar', '1');
                        }

                        const object: any = {
                            first_name: values.first_name.trim(),
                            last_name: values.last_name.trim(),
                            role: data.data.role.name
                        };

                        if (data?.data?.role?.title === 'School User') {
                            object.phone_number = values.phone_number;
                            object.job_title = values.job.trim();
                        }

                        if (changed && fileAvatar) {
                            object.avatar = fileAvatar;
                        }
                        if (changed && !fileAvatar) {
                            object.reset_avatar = 1;
                        }
                        setFormDataFormFirstStep(object);
                        validationHandler(formData);
                    }
                }
                if (step === 'password') {
                    const formData = new FormData();
                    formData.append('password', values.password);
                    formData.append('password_confirmation', values.confirmPassword);
                    formData.append('step', step);
                    formData.append('first_name', formDataFormFirstStep?.first_name);
                    formData.append('last_name', formDataFormFirstStep?.last_name);
                    formData.append('role', data.data.role.name);
                    if (data?.data?.role?.title === 'School User') {
                        formData.append('phone_number', formDataFormFirstStep?.phone_number);
                        formData.append('job_title', formDataFormFirstStep?.job_title);
                    }

                    if (formDataFormFirstStep.avatar) {
                        formData.append('avatar', formDataFormFirstStep.avatar);
                    }
                    if (!formDataFormFirstStep.avatar && !imageUrl) {
                        formData.append('reset_avatar', '1');
                    }
                    signUpHandler(formData);
                }
                if (step === 'contact_info') {
                    const data = {
                        step: 2,
                        data: {
                            company: {
                                name: supplierData.name,
                                trading_name: supplierData.trading_name,
                                registration_num: supplierData.registration_num,
                                website: supplierData.website,
                                expense_areas: selectedArea?.map((elem: any) => elem.id),
                                provinces: selectedProvinnces?.map((elem: any) => elem.id),
                                employees_number: selectedEmployees?.id,
                                b_bbee: selectedBbee?.id,
                                annual_revenue: selectedRevenue?.id
                            },
                            contact: {
                                name: values.contact_name,
                                job_title: values.job,
                                email: values.email,
                                secondary_email: values.secondary_email,
                                phone_number: values.phone_number
                            },
                            agreement: checkbox
                        }
                    };
                    createSupplier(data);
                }
            })
            .catch((errors) => {
                errors?.errorFields?.map((elem: any) => {
                    if (elem.name[0] === 'password') {
                        if (elem.errors[0] === '') {
                            setPasswordErr(true);
                        } else {
                            setPasswordErr(false);
                        }
                    }
                });
                const element = errors?.errorFields?.find((elem: any) => elem.name[0] === 'password');
                if (!element) {
                    setPasswordErr(false);
                }
            });
    };

    return (
        <>
            {contextHolder}
            <div className="signup">
                <div className="signup__header header">
                    {screenWidth > 600 && <img src={logo} className="header__logo" />}
                    {screenWidth <= 600 && <img src={logoPhone} className="header__logo" />}
                    {step === 'supplier_created' ? (
                        <></>
                    ) : (
                        <div className="header__breadcrumb">
                            <Breadcrumb
                                items={
                                    isSupplier
                                        ? [
                                              {
                                                  title: (
                                                      <div
                                                          className={`header__item active`}
                                                          onClick={() => setStep('general')}
                                                          style={{ cursor: 'pointer' }}
                                                      >
                                                          <span>Company Info</span>
                                                      </div>
                                                  )
                                              },
                                              {
                                                  title: (
                                                      <div
                                                          className={`header__item ${
                                                              step === 'contact_info' ? 'active' : ''
                                                          }`}
                                                      >
                                                          <img
                                                              src={step === 'contact_info' ? activeArrow : arrowRight}
                                                              style={{ margin: '0 10px 0 0' }}
                                                          />
                                                          <span>Contact Info</span>
                                                      </div>
                                                  )
                                              },
                                              {
                                                  title: (
                                                      <div className="header__item">
                                                          <img src={arrowRight} />
                                                          <span style={{ visibility: 'hidden' }}>ssegeg</span>
                                                      </div>
                                                  )
                                              }
                                          ]
                                        : [
                                              {
                                                  title: (
                                                      <div
                                                          className={`header__item active`}
                                                          onClick={() => setStep('general')}
                                                          style={{ cursor: 'pointer' }}
                                                      >
                                                          <span>Personal Info</span>
                                                      </div>
                                                  )
                                              },
                                              {
                                                  title: (
                                                      <div
                                                          className={`header__item ${
                                                              step === 'password' ? 'active' : ''
                                                          }`}
                                                      >
                                                          <img
                                                              src={step === 'password' ? activeArrow : arrowRight}
                                                              style={{ margin: '0 10px 0 0' }}
                                                          />
                                                          <span>Password</span>
                                                      </div>
                                                  )
                                              },
                                              {
                                                  title: (
                                                      <div className="header__item">
                                                          <img src={arrowRight} />
                                                          <span style={{ visibility: 'hidden' }}>ssegeg</span>
                                                      </div>
                                                  )
                                              }
                                          ]
                                }
                            />
                        </div>
                    )}
                </div>
                <div className="signup__main main">
                    {step === 'general' && (
                        <div className="main__block">
                            <div className="main__form form">
                                {isSupplier ? (
                                    <div className="form__title">
                                        Supplier Registration for
                                        <br /> Tender Notifications
                                    </div>
                                ) : (
                                    <div className="form__title">
                                        Welcome to the Hive! <br />
                                        Your tender collaboration space!
                                    </div>
                                )}
                                {isSupplier ? (
                                    <div className="form__subtitle">Please fill in General Data</div>
                                ) : (
                                    <div className="form__subtitle">Please fill in personal data</div>
                                )}

                                {!isSupplier && (
                                    <div className="form__addbtn">
                                        {imageUrl && (
                                            <img
                                                src={RoundedWhite}
                                                className="form__delete-avatar-btn"
                                                onClick={() => {
                                                    setChanged(true);
                                                    setImageUrl('');
                                                }}
                                            />
                                        )}
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .heic"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                        />

                                        {imageUrl ? (
                                            <img src={imageUrl} alt="avatar" className="upload-image" />
                                        ) : (
                                            <img src={DefaultAvatar} />
                                        )}

                                        <div className="form__addtext" onClick={clickUpload}>
                                            {imageUrl ? 'Edit Photo' : 'Add Photo'}
                                        </div>
                                    </div>
                                )}
                                {fileErr && <div className="fields__file-error">{fileErr}</div>}
                                {isSupplier && (
                                    <div className="form__note">
                                        <img src={i} />
                                        <div>
                                            If you have separate legal entities operating in different expense areas
                                            (e.g., Cleaning, Security), please complete a form for each relevant entity.
                                        </div>
                                    </div>
                                )}

                                {data?.data?.role?.title === 'School User' && (
                                    <div className="signup__fields fields">
                                        <div className="fields__subtitle">All fields are mandatory</div>
                                        <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                            <div className="fields__block">
                                                <div className="fields__row">
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="first_name"
                                                        rules={yupSync('first_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="First Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="last_name"
                                                        rules={yupSync('last_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Last Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="email"
                                                    rules={yupSync('email', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Email"
                                                        className="input"
                                                        maxLength={60}
                                                        required={true}
                                                        disabled={true}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="phone_number"
                                                    rules={yupSync('phone_number', validationSchema, true)}
                                                >
                                                    <PhoneNumberInput
                                                        floatLabel="Phone Number"
                                                        className="input"
                                                        maxLength={256}
                                                        value={data?.data?.phone_number}
                                                        placeholder={undefined}
                                                        required={undefined}
                                                        onChange={() => {}}
                                                        hasError={() => !!form.getFieldError('phone_number').length}
                                                        onBlur={() => yupSync('phone_number', validationSchema, true)}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="school"
                                                    rules={yupSync('school', validationSchema, true)}
                                                >
                                                    <FloatSelect
                                                        search={''}
                                                        floatLabel="School"
                                                        className="input-select"
                                                        required={true}
                                                        err={false}
                                                        setSearch={() => {}}
                                                        options={[]}
                                                        onChange={''}
                                                        value={data?.data?.school}
                                                        form={form}
                                                        disabled={true}
                                                        hasError={() => false}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="job"
                                                    rules={yupSync('job', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Job Title"
                                                        className="input"
                                                        maxLength={60}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <Button
                                                className="fields__btn"
                                                type="primary"
                                                onClick={handleSubmit}
                                                loading={signUpUserLoading}
                                            >
                                                {signUpUserLoading ? <Loader /> : 'Continue'}
                                            </Button>
                                        </Form>
                                    </div>
                                )}

                                {(data?.data?.role?.title === 'Tender Representative' ||
                                    data?.data?.role?.title === 'Super Admin' ||
                                    data?.data?.role?.title === 'Onboarding Representative') && (
                                    <div className="signup__fields fields">
                                        <div
                                            className={
                                                data?.data.role?.name === 'super_admin'
                                                    ? 'role-admin'
                                                    : data?.data.role?.name === 'tender_representative'
                                                    ? 'role-tr'
                                                    : 'role-or'
                                            }
                                            style={
                                                data?.data?.role?.title === 'Super Admin'
                                                    ? { background: '#001965', color: 'white', width: '100px' }
                                                    : {}
                                            }
                                        >
                                            {data?.data?.role?.title}
                                        </div>
                                        <div className="fields__subtitle">All fields are mandatory</div>
                                        <Form form={form} onFinish={handleSubmit}>
                                            <div className="fields__block">
                                                <div className="fields__row">
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="first_name"
                                                        rules={yupSync('first_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="First Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={`${
                                                            screenWidth > 600 ? 'small-input-wrapper' : 'input-wrapper'
                                                        }`}
                                                        name="last_name"
                                                        rules={yupSync('last_name', validationSchema, true)}
                                                    >
                                                        <FloatInput
                                                            floatLabel="Last Name"
                                                            className="input"
                                                            maxLength={60}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="email"
                                                    rules={yupSync('email', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Email"
                                                        className="input"
                                                        maxLength={60}
                                                        required={true}
                                                        disabled={true}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <Button className="fields__btn" type="primary" onClick={handleSubmit}>
                                                {signUpUserLoading ? <Loader /> : 'Continue'}
                                            </Button>
                                        </Form>
                                    </div>
                                )}
                                {isSupplier && (
                                    <div className="signup__fields fields">
                                        <div className="fields__row">
                                            <div className="fields__title">General Company Info</div>
                                        </div>
                                        <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="name"
                                                    rules={yupSync('name', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Full Name of Legal Entity"
                                                        className="input"
                                                        maxLength={128}
                                                        required={true}
                                                        onBlur={() => yupSync('name', validationSchema, true)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="trading_name"
                                                    rules={yupSync('trading_name', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Trading Name"
                                                        className="input"
                                                        maxLength={128}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="registration_num"
                                                    rules={yupSync('registration_num', validationSchema, true)}
                                                >
                                                    <PhoneNumberInput
                                                        floatLabel="Company Registration Number"
                                                        className="input"
                                                        maxLength={256}
                                                        value={data?.data?.registration_num}
                                                        placeholder={undefined}
                                                        required={true}
                                                        onChange={() =>
                                                            setError((prev) => ({ ...prev, registration_num: false }))
                                                        }
                                                        hasError={() => !!form.getFieldError('registration_num').length}
                                                        onBlur={() =>
                                                            yupSync('registration_num', validationSchema, true)
                                                        }
                                                        mask={'9999/999999/99'}
                                                        error={errors.registration_num}
                                                    />
                                                </Form.Item>
                                                {errors.registration_num && (
                                                    <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                        Oops! It looks like that Registration Number is already
                                                        registered
                                                    </div>
                                                )}

                                                <Form.Item
                                                    className="input-wrapper"
                                                    name="website"
                                                    rules={yupSync('website', validationSchema, true)}
                                                >
                                                    <FloatInput
                                                        floatLabel="Website"
                                                        className="input"
                                                        maxLength={1024}
                                                        required={true}
                                                        disabled={false}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="input-wrapper-multiselect"
                                                    rules={yupSync('expense_areas', validationSchema, true)}
                                                >
                                                    <CustomMultiSelect
                                                        required={true}
                                                        floatLabel={'Expense Area/s'}
                                                        err={false}
                                                        options={filtredAreas}
                                                        onChange={(item: any) => {
                                                            selectItem(
                                                                item,
                                                                setFiltredAreas,
                                                                setSelectedArea,
                                                                selectedArea,
                                                                dataSchoolTypes?.data?.expense_areas
                                                            );
                                                        }}
                                                        hasError={() => errors.expense_areas}
                                                        prefix={undefined}
                                                        value={selectedArea}
                                                        deleteItem={(id: string | number) =>
                                                            deleteItem(id, setSelectedArea, selectedArea)
                                                        }
                                                        deleteAllItems={setSelectedArea}
                                                        setTouched={(value) =>
                                                            setTouched((prev) => ({ ...prev, areas: value }))
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                            {errors.expense_areas && (
                                                <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                    This is a required field
                                                </div>
                                            )}
                                            <div className="fields__subtitle" style={{ margin: '8px 0 16px 0' }}>
                                                Select the expense areas you would like to receive tender notifications
                                                for
                                            </div>
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper-multiselect"
                                                    rules={yupSync('provinces', validationSchema, true)}
                                                >
                                                    <CustomMultiSelect
                                                        required={true}
                                                        floatLabel={'Provincial Coverage'}
                                                        err={false}
                                                        options={filtredProvinnces}
                                                        onChange={(item: any) =>
                                                            selectItem(
                                                                item,
                                                                setFiltredProvinnces,
                                                                setSelectedProvinnces,
                                                                selectedProvinnces,
                                                                dataSchoolTypes?.data?.provinces
                                                            )
                                                        }
                                                        hasError={() => errors.provinces}
                                                        prefix={undefined}
                                                        value={selectedProvinnces}
                                                        deleteItem={(id: string | number) =>
                                                            deleteItem(id, setSelectedProvinnces, selectedProvinnces)
                                                        }
                                                        deleteAllItems={setSelectedProvinnces}
                                                        setTouched={(value) =>
                                                            setTouched((prev) => ({ ...prev, provinces: value }))
                                                        }
                                                    />
                                                </Form.Item>
                                                {errors.provinces && (
                                                    <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                        This is a required field
                                                    </div>
                                                )}
                                            </div>
                                            <div className="fields__block">
                                                <Form.Item
                                                    className="input-wrapper"
                                                    rules={yupSync('employees_number', validationSchema, true)}
                                                >
                                                    <FloatSelect
                                                        type="common"
                                                        required={true}
                                                        floatLabel={'# of Employees'}
                                                        err={false}
                                                        className="input-select"
                                                        setSearch={() => {}}
                                                        options={dataSchoolTypes?.data?.sc_employees_number}
                                                        onChange={setSelectedEmployees}
                                                        value={selectedEmployees}
                                                        search={''}
                                                        form={form}
                                                        hasError={() => errors.employees}
                                                        setTouched={(value) =>
                                                            setTouched((prev) => ({ ...prev, employees: value }))
                                                        }
                                                    />
                                                </Form.Item>
                                                {errors.employees && (
                                                    <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                        This is a required field
                                                    </div>
                                                )}
                                            </div>
                                            <div className="fields__block">
                                                <div className="fields__row">
                                                    <Form.Item
                                                        className="small-input-wrapper"
                                                        rules={yupSync('annual_revenue', validationSchema, true)}
                                                    >
                                                        <FloatSelect
                                                            type="common"
                                                            required={true}
                                                            floatLabel={'Annual Revenue'}
                                                            err={false}
                                                            className="input-select"
                                                            setSearch={() => {}}
                                                            options={dataSchoolTypes?.data?.sc_annual_revenue}
                                                            onChange={setSelectedRevenue}
                                                            value={selectedRevenue}
                                                            search={''}
                                                            form={form}
                                                            hasError={() => errors.revenue}
                                                            setTouched={(value) =>
                                                                setTouched((prev) => ({ ...prev, revenue: value }))
                                                            }
                                                        />
                                                        {errors.revenue && (
                                                            <div
                                                                className="error-text"
                                                                style={{ margin: '33px 0 -55px 0' }}
                                                            >
                                                                This is a required field
                                                            </div>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        className="small-input-wrapper"
                                                        rules={yupSync('b_bbee', validationSchema, true)}
                                                    >
                                                        <FloatSelect
                                                            type="common"
                                                            required={true}
                                                            floatLabel={'B-BBEE'}
                                                            err={false}
                                                            className="input-select"
                                                            setSearch={() => {}}
                                                            options={dataSchoolTypes?.data?.sc_b_bbee}
                                                            onChange={setSelectedBbee}
                                                            value={selectedBbee}
                                                            search={''}
                                                            form={form}
                                                            hasError={() => errors.bbee}
                                                            setTouched={(value) =>
                                                                setTouched((prev) => ({ ...prev, bbee: value }))
                                                            }
                                                        />
                                                        {errors.bbee && (
                                                            <div
                                                                className="error-text"
                                                                style={{ margin: '33px 0 -55px 0' }}
                                                            >
                                                                This is a required field
                                                            </div>
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <Button
                                                className="fields__btn"
                                                type="primary"
                                                onClick={handleSubmit}
                                                loading={signUpUserLoading}
                                            >
                                                {isLoadingCreateSupplier ? <Loader /> : 'Continue'}
                                            </Button>
                                        </Form>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {step === 'password' && (
                        <div className="main__block">
                            <div className="main__form form password">
                                <div className="form__title">Set up your password</div>

                                <div className="signup__fields fields">
                                    <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                        <div className="fields__block">
                                            <Form.Item
                                                className="input-wrapper"
                                                name="password"
                                                rules={yupSync('password', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    type="password"
                                                    floatLabel="Password (>8 characters)"
                                                    className="input"
                                                    maxLength={32}
                                                    onBlur={() => {
                                                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
                                                        const res = passwordRegex.test(form.getFieldValue('password'));
                                                        if (!res && form.getFieldValue('password')) {
                                                            setPasswordErr(true);
                                                        } else {
                                                            setPasswordErr(false);
                                                        }
                                                    }}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                            <div
                                                className="fields__label"
                                                style={
                                                    passwordErr ? { color: '#e80a0f', margin: '-24px 0 16px 0' } : {}
                                                }
                                            >
                                                {`The password should contain numbers and at least one upper case and lower case letter (>8 characters)`}
                                            </div>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="confirmPassword"
                                                rules={[
                                                    ...yupSync('confirmPassword', validationSchema),
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error('Oops! Your passwords do not match')
                                                            );
                                                        }
                                                    })
                                                ]}
                                                dependencies={['password']}
                                            >
                                                <FloatInput
                                                    type="password"
                                                    floatLabel="Repeat Password (>8 characters)"
                                                    className="input"
                                                    maxLength={32}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                        </div>

                                        <Button className="fields__btn" type="primary" onClick={handleSubmit}>
                                            {signUpUserLoading ? <Loader /> : 'Create Account'}
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    )}

                    {step === 'contact_info' && (
                        <div className="main__block">
                            <div className="main__form form">
                                <div className="form__title" style={{ margin: '0 0 40px 0' }}>
                                    Contact Info
                                </div>
                                <div className="signup__fields fields">
                                    <div className="fields__row">
                                        <div className="fields__title">Primary Contact</div>
                                    </div>
                                    <Form form={form} onFinish={handleSubmit} validateTrigger="onBlur">
                                        <div className="fields__block">
                                            <Form.Item
                                                className={`${screenWidth > 600 ? 'input-wrapper' : 'input-wrapper'}`}
                                                name="contact_name"
                                                rules={yupSync('contact_name', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Contact Name"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="job"
                                                rules={yupSync('job', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Job Title"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="phone_number"
                                                rules={yupSync('phone_number', validationSchema, true)}
                                            >
                                                <PhoneNumberInput
                                                    floatLabel="Contact Phone number"
                                                    className="input"
                                                    maxLength={256}
                                                    value={data?.data?.phone_number}
                                                    placeholder={undefined}
                                                    required={undefined}
                                                    onChange={() => {}}
                                                    hasError={() => !!form.getFieldError('phone_number').length}
                                                    onBlur={() => yupSync('phone_number', validationSchema, true)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="email"
                                                rules={yupSync('email', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Primary Email"
                                                    className="input"
                                                    maxLength={60}
                                                    required={true}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="input-wrapper"
                                                name="secondary_email"
                                                rules={yupSync('secondary_email', validationSchema, true)}
                                            >
                                                <FloatInput
                                                    floatLabel="Secondary Email"
                                                    className="input"
                                                    maxLength={60}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form__note">
                                            <img src={i} />
                                            <div>
                                                New tender opportunities will be sent to both your primary and secondary
                                                email addresses. To ensure you don't miss out on any opportunities, we
                                                recommend providing a secondary email address that is continuously
                                                monitored.
                                            </div>
                                        </div>
                                        <Form.Item className="input-wrapper" name="agreement">
                                            <Checkbox checked={checkbox} onChange={() => setCheckbox((prev) => !prev)}>
                                                <div className="checkbox_text">
                                                    I agree to SchoolAdvisor the{' '}
                                                    <a
                                                        href="https://schooladvisor.co.za/privacy-notice"
                                                        target="_blank"
                                                        style={{
                                                            color: 'rgb(19 47 130)',
                                                            fontWeight: '600',
                                                            textDecoration: 'underline'
                                                        }}
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </div>
                                            </Checkbox>
                                        </Form.Item>
                                        {errors.agreement && (
                                            <div className="error-text" style={{ margin: '-10px 0 10px 0' }}>
                                                You need to agree to the SchoolAdvisor Privacy Policy
                                            </div>
                                        )}
                                    </Form>
                                </div>
                                <Button
                                    className="fields__btn"
                                    type="primary"
                                    onClick={handleSubmit}
                                    loading={signUpUserLoading}
                                >
                                    {isLoadingCreateSupplier ? <Loader /> : 'Submit Form'}
                                </Button>
                            </div>
                        </div>
                    )}
                    {step === 'supplier_created' && (
                        <div className="main__block">
                            <div className="main__form form">
                                <div className="form__title" style={{ margin: '0 0 40px 0' }}>
                                    Your registration request is in!{' '}
                                </div>
                                <div className="signup__fields fields">
                                    <div className="fields__row">
                                        <div className="fields__title">
                                            To complete the registration, please get the <br /> following documents
                                            ready:
                                        </div>
                                    </div>
                                    <ul>
                                        <li className="signup__li">Financial/Management Accounts</li>
                                        <li className="signup__li">B-BBEE Certificate/Affidavit</li>
                                        <li className="signup__li">SARS Letter of Good Standing</li>
                                        <li className="signup__li">Company Registration Certificate</li>
                                    </ul>
                                    <div className="signup__addtext">We’ll reach out within 72 hours</div>
                                </div>
                                <Button
                                    className="fields__btn"
                                    type="primary"
                                    onClick={() => (window.location.href = 'https://schooladvisor.co.za')}
                                    loading={signUpUserLoading}
                                >
                                    {signUpUserLoading ? <Loader /> : 'Back Home'}
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className={`main__block ${screenWidth < 1280 ? 'image' : ''}`}>
                        <div className={step === 'general' ? 'general' : 'password'}>
                            {step === 'general' && <img src={general} className="image__item" />}
                            {step === 'password' && <img src={password} className="image__item" />}
                            {step === 'contact_info' && <img src={ContactInfoIcon} className="image__item" />}
                            {step === 'supplier_created' && <img src={supplierCreated} className="image__item" />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
