import { FC } from 'react';

import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';

import SupplierReportCardPublishDateIcon from '../../../assets/Supplier Report Card Publish Date.svg';
import calendar from '../../../assets/calendar-chedule.svg';
import messageGrey from '../../../assets/messageGrey.svg';

import { useAppSelector } from '../../../hooks';
import MultiAwardIcon from '../../../assets/MultiAward.svg';
import moment from 'moment';

interface DefineMeetingTaskProps {
    data: tenderDataProps;
}

export const MultiAward: FC<DefineMeetingTaskProps> = ({ data }) => {
    const { role } = useAppSelector((state) => state.profileReducer);

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'evaluate_4_multi_award') {
                        if (role !== 'school_user') {
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={MultiAwardIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value school-user">
                                                <img src={messageGrey} />
                                                {elem.comments.school_user}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}
        </>
    );
};
