import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowUpSvg = ({ fill }: { fill: string }) => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Interface, Essential/Arrow, Up">
            <g id="Group">
                <g id="Group_2">
                    <path
                        id="Path"
                        d="M10.0083 3.75781V16.2495"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Path_2"
                        d="M5.01917 8.76138L10 3.74805L14.9808 8.76138"
                        stroke={fill}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const ArrowUp = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={() => <ArrowUpSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default ArrowUp;
