import emailIcon from '../../../assets/letter-mail-notification-red.svg';
import './index.scss';
import arrow from '../../../assets/arrow-left.svg';
import logo from '../../../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export const LimitExceeded = () => {
    const navigate = useNavigate();

    return (
        <div className="login auth">
            <div className="auth__logo-container">
                <img src={logo} className="auth__logo" alt="logo" />
            </div>
            <div className="login__form form forgot-form_fail">
                <img src={emailIcon} className="form__title_icon_fail" alt="email icon success" />
                <div className="form__title">Limit Exceeded</div>

                <div className="form__subtitle">
                    <p>You have exceeded the number of verification attempts. Please try again in 24 hours.</p>
                </div>
                <div className="login__back" onClick={() => navigate('/login')}>
                    <img src={arrow} alt="back to Log in" /> Back to Log in
                </div>
            </div>
            <div className="login__footer">© copyright 2016 - {moment().format('YYYY')} SchoolAdvisor</div>
        </div>
    );
};
