import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, SetStateAction, useEffect, useState } from 'react';
import plus from '../../../assets/Plus, Add.svg';
import copyImage from '../../../assets/copyImage.svg';
import SupplierListIcon from '../../../assets/SupplierListIcon.svg';
import { Button, message, Modal, Switch, Tooltip } from 'antd';
import i from '../../../assets/i.svg';
import {
    useDeleteSupplierInviteMutation,
    useShortlistSupplierMutation,
    useUpdateIncumbentMutation
} from '../../../api/tenders';
import { Loader } from '../../Loader/Loader';
import { AddSuppliersModal } from '../../Modals/AddSuppliersModal';
import Confirmed from '../../../assets/Confirmed.svg';
import Unconfirmed from '../../../assets/Unconfirmed.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import Unverified from '../../../assets/Unverified.svg';
import Verified from '../../../assets/Verified.svg';
import editIcon from '../../../assets/editAdminIcon.svg';
import { EditSupplierModal } from '../../Modals/EditSupplierModal';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const SupplierListNew: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [supplierModal, setSupplierModal] = useState(false);
    const [SupplierData, setSupplierData] = useState<any>(null);
    const [taskId, setTaskId] = useState<number | null>(null);

    const [deleteSupplier, { data: deletedData, isSuccess: isSuccessDeleted, isLoading: isLoadingDelete }] =
        useDeleteSupplierInviteMutation();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [updateIncumbent, { isSuccess: isSuccessUpdateIncumbent }] = useUpdateIncumbentMutation();

    useEffect(() => {
        if (isSuccessDeleted) {
            setTrigger((prev) => !prev);
            message.success(deletedData?.message);
            setIsDeleteModal(false);
        }
    }, [isSuccessDeleted]);

    const copyAllEmails = () => {
        const emailsArray: any = [];
        data?.invited_suppliers?.items.forEach((elem) => {
            if (elem?.manager?.email) {
                emailsArray.push(elem.manager.email);
            }
        });

        navigator.clipboard.writeText(emailsArray.join(' '));
        message.success('Supplier emails copied to clipboard successfully');
    };

    useEffect(() => {
        if (isSuccessUpdateIncumbent) {
            setTrigger((prev: boolean) => !prev);
            // messageApi.success('Supplier has been incumbent successfully')
        }
    }, [isSuccessUpdateIncumbent]);

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    const deleteSupplierFunc = async () => {
        if (isLoadingDelete) return;
        try {
            const dataToPost = {
                tender_id: data.id,
                invite_id: taskId
            };
            deleteSupplier(dataToPost);
        } catch {}
    };

    const classNameOfCompletedSuppliersCounter = (number: number) => {
        if (number === 0) {
            return 'grey';
        }
        if (number >= 1 && number <= 5) {
            return 'red';
        }
        if (number >= 6 && number <= 9) {
            return 'yellow';
        }
        if (number >= 10) {
            return 'green';
        }
    };
    return (
        <>
            {data?.phase_tasks.length > 0 &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'design_suppliers' || elem.action === 'launch_suppliers') {
                        if (role === 'onboarding_representative') {
                            if (data.invited_suppliers.items.length < 1) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>

                                        <img
                                            src={plus}
                                            className="task__plus"
                                            onClick={() => {
                                                setSupplierModal(true);
                                            }}
                                        />
                                    </div>
                                );
                            }
                            if (data.invited_suppliers.items.length >= 1) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                                <div
                                                    className={`task-complete__i-wrap ${classNameOfCompletedSuppliersCounter(
                                                        data.invited_suppliers.completed_registration
                                                    )}`}
                                                >
                                                    {data.invited_suppliers.completed_registration}
                                                    <Tooltip
                                                        title={
                                                            <div className="tooltip-light">
                                                                <div className="tooltip-light__row">
                                                                    <div>Only registered</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.only_registered}
                                                                    </div>
                                                                </div>
                                                                <div className="tooltip-light__row">
                                                                    <div>Completed registration</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.completed_registration}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        className={'tooltip-white'}
                                                    >
                                                        <img src={i} className="task-complete__i" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setSupplierModal(true);
                                                }}
                                            />
                                        </div>

                                        {data.invited_suppliers.items.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header">
                                                        <img src={statusImage(elem.company.status_name)} />{' '}
                                                        {elem.company.status_name}
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">{elem.company.name}</div>
                                                            <div className="supplier__subtitle">
                                                                {elem.company.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager?.name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager?.name ? elem.manager.name : ''}
                                                                    <div
                                                                        className={`supplier__status ${
                                                                            elem.status_name === 'Unconfirmed'
                                                                                ? 'Unconfirmed'
                                                                                : 'Confirmed'
                                                                        }`}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                elem.status_name === 'Unconfirmed'
                                                                                    ? Unconfirmed
                                                                                    : Confirmed
                                                                            }
                                                                        />{' '}
                                                                        {elem.status_name}
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem.manager?.email ? elem.manager?.email : ''}{' '}
                                                                    {elem.manager?.phone_number
                                                                        ? elem.manager?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        <img
                                                            src={deleteIcon}
                                                            className="supplier__delete"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setSupplierData(elem);
                                                                setIsDeleteModal(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                        if (role === 'tender_representative' || role === 'super_admin') {
                            if (data.invited_suppliers.items.length < 1) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                    </div>
                                );
                            }
                            if (data.invited_suppliers.items.length >= 1) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem?.name}
                                                <div
                                                    className={`task-complete__i-wrap ${classNameOfCompletedSuppliersCounter(
                                                        data.invited_suppliers.completed_registration
                                                    )}`}
                                                >
                                                    {data.invited_suppliers.completed_registration}
                                                    <Tooltip
                                                        title={
                                                            <div className="tooltip-light">
                                                                <div className="tooltip-light__row">
                                                                    <div>Only registered</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.only_registered}
                                                                    </div>
                                                                </div>
                                                                <div className="tooltip-light__row">
                                                                    <div>Completed registration</div>
                                                                    <div className="tooltip-light__number">
                                                                        {data.invited_suppliers.completed_registration}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        className={'tooltip-white'}
                                                    >
                                                        <img src={i} className="task-complete__i" />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="task__copyemails" onClick={copyAllEmails}>
                                                <img src={copyImage} />
                                                Copy all Emails
                                            </div>
                                        </div>

                                        {data.invited_suppliers.items.map((elem) => {
                                            return (
                                                <div className="task-complete__supplier supplier">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img src={statusImage(elem.company.status_name)} />{' '}
                                                            {elem.company.status_name}
                                                        </div>
                                                        <div className="supplier__wrapper switcher">
                                                            Incumbent
                                                            <Switch
                                                                checked={elem.incumbent}
                                                                size="small"
                                                                className="custom-switch"
                                                                onChange={() => {
                                                                    const dataToPost = {
                                                                        tender_id: data.id,
                                                                        invite_id: elem.id
                                                                    };
                                                                    updateIncumbent(dataToPost);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="supplier__row">
                                                        <div className="supplier__wrap first">
                                                            <div className="supplier__title">{elem.company.name}</div>
                                                            <div className="supplier__subtitle">
                                                                {elem.company.trading_name}
                                                            </div>
                                                        </div>
                                                        {elem.manager?.name ? (
                                                            <div className="supplier__wrap">
                                                                <div className="supplier__title">
                                                                    {elem.manager?.name ? elem.manager.name : ''}
                                                                    <div
                                                                        className={`supplier__status ${
                                                                            elem.status_name === 'Unconfirmed'
                                                                                ? 'Unconfirmed'
                                                                                : 'Confirmed'
                                                                        }`}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                elem.status_name === 'Unconfirmed'
                                                                                    ? Unconfirmed
                                                                                    : Confirmed
                                                                            }
                                                                        />{' '}
                                                                        {elem.status_name}
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {elem.manager?.email ? elem.manager?.email : ''}{' '}
                                                                    {elem.manager?.phone_number
                                                                        ? elem.manager?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        {elem.manager?.name && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__delete"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setSupplierData(elem);
                                                                    setIsEditModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                    }
                })}

            {supplierModal && (
                <AddSuppliersModal
                    openModal={supplierModal}
                    setOpenModal={setSupplierModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    data={data}
                />
            )}
            {isEditModal && (
                <EditSupplierModal
                    openModal={isEditModal}
                    setOpenModal={setIsEditModal}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                />
            )}

            {isDeleteModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteSupplierFunc}>
                                {isLoadingDelete ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__title">Remove supplier from the tender?</div>
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to remove the <b>{SupplierData?.company?.name}</b> from the tender
                            supplier list?{' '}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
