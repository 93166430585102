import { useEffect, useRef, useState } from 'react';
import { Breadcrumb, message, Spin, Tooltip } from 'antd';
import { useLazyGetTenderQuery, useMoveToNextPhaseMutation } from '../../api/tenders';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClickOutside } from '../../hooks/useClickOutSide';
import { ModalTender } from '../../components/Modals/ModalTender';
import { SubmitPhase } from '../../components/Phases/SubmitPhase';
import { Messenger } from '../../components/Messenger/Messenger';
import { DefinePhase } from '../../components/Phases/DefinePhase';
import { useAppSelector } from '../../hooks';
import { DesignPhase } from '../../components/Phases/DesignPhase';
import { LaunchPhase } from '../../components/Phases/LaunchPhase';
import { Evaluate12 } from '../../components/Phases/Evaluate12';

import RoundedArrow from '../../assets/RoundedArrow.svg';
import onTrack from '../../assets/onTrack.svg';
import dots from '../../assets/dots.svg';
import edit from '../../assets/editAdminIcon.svg';
import CancelBtn from '../../assets/CancelBtn.svg';
import tenderSubmit from '../../assets/tenderSubmit.svg';
import tenderDesign from '../../assets/tenderDesign.svg';
import tenderDefine from '../../assets/tenderDefine.svg';
import tenderLaunch from '../../assets/tenderLaunch.svg';
import Evaluate1 from '../../assets/Evaluate1+2.svg';
import Evaluate3 from '../../assets/Evaluate3.svg';
import AwardIcon from '../../assets/AwardIcon.svg';
import cancelTenderIcon from '../../assets/cancelTenderIcon.svg';

import School from '../../assets/School.svg';
import defaultAvatar from '../../assets/DefaultAvatar.svg';
import Contract from '../../assets/Contract.svg';
import flag from '../../assets/Flag, Circle.svg';
import clock from '../../assets/clock-time-cross.svg';
import arrow from '../../assets/Arrow, Right.svg';
import defaultSchoolImage from '../../assets/default-school.svg';

import './TenderViewPage.scss';
import { EvaluatePhase3 } from '../../components/Phases/EvaluatePhase3';
import { EvaluatePhase4 } from '../../components/Phases/EvaluatePhase4';
import { AwardPhase } from '../../components/Phases/AwardPhase';
import { NextPhaseModal } from '../../components/Modals/NextPhaseModal';
import { Canceled } from '../../components/Phases/Canceled';
import { CancelTenderModal } from '../../components/Modals/CancelTenderModal';

interface BreadcrumbItem {
    title: JSX.Element;
}

export const TenderViewPage = () => {
    const [getTender, { data: dataTender, isError: isErrorDataTender, error: errorDataTender }] =
        useLazyGetTenderQuery();
    const [moveToNextPhaseMutation, { isLoading }] = useMoveToNextPhaseMutation();
    const [openManage, setOpenManage] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, () => setOpenManage(false));
    const [isOpenEditTenderModal, setIsOpenEditTenderModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [trigger, setTrigger] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [confirmModal, setConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [screenShown, setScreenShown] = useState('main');
    const [breadcrumbs, setBreadscrumbs] = useState<BreadcrumbItem[]>([]);
    const { role } = useAppSelector((state) => state.profileReducer);
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [openCancelTenderModal, setOpenCancelTenderModal] = useState(false);

    const nameOfNextPhase = () => {
        if (dataTender.data.phase_name === 'Submit') {
            return 'Define';
        }
        if (dataTender.data.phase_name === 'Define') {
            return 'Design';
        }
        if (dataTender.data.phase_name === 'Design') {
            return 'Launch';
        }
        if (dataTender.data.phase_name === 'Launch') {
            return 'Evaluation Gate 1 + 2';
        }
        if (dataTender.data.phase_name === 'Evaluation Gate 1+2') {
            return 'Evaluation Gate 3';
        }
        if (dataTender.data.phase_name === 'Evaluation Gate 3') {
            return 'Evaluation Gate 4';
        }
        if (dataTender.data.phase_name === 'Evaluation Gate 4') {
            return 'Award';
        }
        if (dataTender.data.phase_name === 'Award') {
            return 'Close';
        }
    };

    useEffect(() => {
        if (isErrorDataTender) {
            if (errorDataTender) {
                navigate('/not-found');
            }
        }
    }, [isErrorDataTender, errorDataTender]);

    useEffect(() => {
        if (screenShown === 'main') {
            setBreadscrumbs([
                {
                    title: <a href="/dashboard">Dashboard</a>
                },
                {
                    title: (
                        <div style={{ color: '#001965' }} className="name-breadscrumbs">
                            {dataTender?.data?.name}
                        </div>
                    )
                }
            ]);
        }
        if (screenShown === 'tasks') {
            setBreadscrumbs([
                {
                    title: <a href="/dashboard">Dashboard</a>
                },
                {
                    title: (
                        <div onClick={() => setScreenShown('main')} className="name-breadscrumbs">
                            {dataTender?.data?.name}
                        </div>
                    )
                },
                {
                    title: (
                        <div onClick={(e) => e.stopPropagation()} style={{ color: '#001965' }}>
                            Task Centre
                        </div>
                    )
                }
            ]);
        }
        if (screenShown === 'messenger') {
            setBreadscrumbs([
                {
                    title: <a href="/dashboard">Dashboard</a>
                },
                {
                    title: (
                        <div onClick={() => setScreenShown('main')} className="name-breadscrumbs">
                            {dataTender?.data?.name}
                        </div>
                    )
                },
                {
                    title: (
                        <div onClick={(e) => e.stopPropagation()} style={{ color: '#001965' }}>
                            Messenger
                        </div>
                    )
                }
            ]);
        }
    }, [screenShown, dataTender]);

    useEffect(() => {
        if (!location) return;
        getTenderFunc();
    }, [location, trigger]);

    const getTenderFunc = async () => {
        setLoading(true);
        try {
            const id = location.pathname.split('/')[2];
            await getTender(id);
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const moveFunc = async () => {
        try {
            await moveToNextPhaseMutation(dataTender?.data?.id).unwrap();
            const id = location.pathname.split('/')[2];
            await getTender(id);
            setConfirmModal(false);
        } catch {}
    };

    const isToolTip = (className: string) => {
        const element = document.querySelector(className);

        if (element) {
            const isTextOverflowing = element.scrollWidth > element.clientWidth;

            if (isTextOverflowing) {
                return true;
            }
        }
    };

    return (
        <>
            {contextHolder}
            <Spin spinning={loading}>
                {screenShown === 'main' && (
                    <div className="tender">
                        <div className="breadcrumb-wrap">
                            {screenWidth < 834 && <img src={arrow} onClick={() => navigate('/dashboard')} />}

                            <Breadcrumb className="breadcrumb" items={breadcrumbs} />
                        </div>
                        <div className="tender__head head">
                            <div className="head__mainwrap">
                                {screenWidth > 834 && (
                                    <img
                                        src={RoundedArrow}
                                        onClick={() => navigate('/dashboard')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                                <div className="head__titlewrap">
                                    <div className="head__title">{dataTender?.data?.name}</div>
                                    <div className="head__id">
                                        <span style={{ color: '#808CB2' }}>ID</span> {dataTender?.data?.number}
                                        {dataTender?.data?.phase_name === 'Closed' ? (
                                            <></>
                                        ) : (
                                            <>
                                                {dataTender?.data?.phase_task_status === 'on_track' && (
                                                    <div className="head__status on-track">
                                                        <img src={onTrack} />
                                                        {dataTender?.data?.phase_task_status_name}
                                                    </div>
                                                )}
                                                {dataTender?.data?.phase_task_status === 'follow_up' &&
                                                    role !== 'school_user' && (
                                                        <div className="head__status follow_up">
                                                            <img src={flag} />
                                                            {dataTender?.data?.phase_task_status_name}
                                                        </div>
                                                    )}
                                                {dataTender?.data?.phase_task_status === 'late' && (
                                                    <div className="head__status late">
                                                        <img src={clock} />
                                                        Late
                                                    </div>
                                                )}
                                                {role === 'school_user' &&
                                                    dataTender?.data?.phase_task_status === 'follow_up' && (
                                                        <div className="head__status late">
                                                            <img src={clock} />
                                                            Late
                                                        </div>
                                                    )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {(role === 'tender_representative' && dataTender?.data?.phase === 'canceled') ||
                            dataTender?.data?.status === 'archive' ? (
                                <></>
                            ) : (
                                <div ref={ref} style={{ position: 'relative' }}>
                                    <div
                                        className="head__manage"
                                        style={openManage ? { color: '#4D5E93' } : {}}
                                        onClick={() => setOpenManage((prev) => !prev)}
                                    >
                                        {role !== 'school_user' && (
                                            <>
                                                Manage <img src={dots} />
                                            </>
                                        )}
                                    </div>
                                    {openManage && (
                                        <div className="head__openManage openManage">
                                            {role === 'super_admin' && (
                                                <div
                                                    className="openManage__item"
                                                    onClick={() => setIsOpenEditTenderModal(true)}
                                                >
                                                    <img src={edit} />
                                                    Edit Tender
                                                </div>
                                            )}
                                            {dataTender?.data?.phase === 'canceled' ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className="openManage__item cancel-tender"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setOpenCancelTenderModal(true);
                                                    }}
                                                >
                                                    <img src={CancelBtn} />
                                                    Cancel Tender
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="tender__tendermain tendermain">
                            <div className="tendermain__row">
                                <div className="tendermain__row-row">
                                    <Tooltip
                                        title={
                                            role === 'school_user'
                                                ? dataTender?.data?.phase_name === 'Submit'
                                                    ? 'Your tender has been logged. Once you have signed our terms of service and submitted your school deposit for payment, we will be on our way to support you with your tender.'
                                                    : dataTender?.data?.phase_name === 'Define'
                                                    ? 'In this phase, we will collaborate with you to define your requirements and evaluation criteria.'
                                                    : dataTender?.data?.phase_name === 'Design'
                                                    ? 'During this phase, we design your custom tender package.'
                                                    : dataTender?.data?.phase_name === 'Launch'
                                                    ? 'Your tender has been launched, and suppliers are busy preparing their proposals.'
                                                    : dataTender?.data?.phase_name === 'Evaluation Gate 1+2'
                                                    ? 'In this phase, you will evaluate suppliers to assess their credibility and capability, and shortlist the suppliers for the next evaluation round.'
                                                    : dataTender?.data?.phase_name === 'Evaluation Gate 3'
                                                    ? 'In this phase, you will evaluate suppliers to assess their compatibility and which to shortlist for the next evaluation round.'
                                                    : dataTender?.data?.phase_name === 'Evaluation Gate 4'
                                                    ? 'During this phase, you will evaluate the suppliers` competitiveness and select the one you would like to appoint.'
                                                    : dataTender?.data?.phase_name === 'Award'
                                                    ? 'Almost done! During this phase, we will finalise the supply agreement with your appointed supplier(s). This is the final step before you can begin your partnership with your new supplier(s).'
                                                    : ''
                                                : ''
                                        }
                                    >
                                        <div
                                            className="tendermain__card card"
                                            style={role === 'school_user' ? { cursor: 'pointer' } : {}}
                                        >
                                            <div className="card__title">Current Phase</div>
                                            <div className="card__subtitle">
                                                {dataTender?.data?.phase_name === 'Submit' && (
                                                    <img src={tenderSubmit} />
                                                )}
                                                {dataTender?.data?.phase_name === 'Define' && (
                                                    <img src={tenderDefine} />
                                                )}
                                                {dataTender?.data?.phase_name === 'Design' && (
                                                    <img src={tenderDesign} />
                                                )}
                                                {dataTender?.data?.phase_name === 'Launch' && (
                                                    <img src={tenderLaunch} />
                                                )}
                                                {dataTender?.data?.phase_name === 'Evaluation Gate 1+2' && (
                                                    <img src={Evaluate1} />
                                                )}
                                                {dataTender?.data?.phase_name === 'Evaluation Gate 3' && (
                                                    <img src={Evaluate3} />
                                                )}
                                                {dataTender?.data?.phase_name === 'Evaluation Gate 4' && (
                                                    <img src={Evaluate3} />
                                                )}
                                                {(dataTender?.data?.phase_name === 'Award' ||
                                                    dataTender?.data?.phase_name === 'Closed') && (
                                                    <img src={AwardIcon} />
                                                )}
                                                {dataTender?.data?.phase === 'canceled' && (
                                                    <img src={cancelTenderIcon} />
                                                )}

                                                <div className="card__name"> {dataTender?.data?.phase_name}</div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                    <div className="tendermain__card card">
                                        <div className="card__title">Expense Area</div>
                                        <div className="card__subtitle">
                                            <img src={School} />
                                            <div className="card__name expense-area">
                                                {dataTender?.data?.expense_area?.name}
                                            </div>
                                            {isToolTip('.expense-area') && (
                                                <div className="hover-content">
                                                    {dataTender?.data?.expense_area?.name}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="tendermain__card card">
                                        <div className="card__title">Contract Value</div>
                                        <Tooltip title="This is an estimation of the tender contract value, calculated based on a 3-year contract term.">
                                            <div className="card__subtitle">
                                                <img src={Contract} />
                                                <div className="card__name">
                                                    R {dataTender?.data?.contract_value_format}m
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className="tendermain__row-row">
                                    <div className="tendermain__card card">
                                        <div className="card__title">School</div>
                                        <div className="card__subtitle">
                                            <img
                                                src={
                                                    dataTender?.data?.school?.preview
                                                        ? dataTender?.data?.school?.preview
                                                        : defaultSchoolImage
                                                }
                                                className="card__images"
                                            />
                                            <div className="card__info">
                                                <div className="card__name school-name">
                                                    {dataTender?.data?.school?.name}
                                                </div>
                                                {isToolTip('.school-name') && (
                                                    <div className="hover-content">
                                                        {dataTender?.data?.school?.name}
                                                    </div>
                                                )}
                                                <div className="card__additional-info school-info">
                                                    {dataTender?.data?.school?.city}{' '}
                                                    {dataTender?.data?.school?.province?.name}
                                                </div>
                                                {isToolTip('.school-info') && (
                                                    <div className="hover-content">
                                                        {dataTender?.data?.school?.city}{' '}
                                                        {dataTender?.data?.school?.province?.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tendermain__card card">
                                        <div className="card__title">School User</div>
                                        <div className="card__subtitle">
                                            <img
                                                src={
                                                    dataTender?.data?.school_user?.avatar
                                                        ? dataTender?.data?.school_user?.avatar
                                                        : defaultAvatar
                                                }
                                                className="card__images"
                                            />
                                            <div className="card__info">
                                                <div className="card__name school-user-name">
                                                    {dataTender?.data?.school_user?.first_name}{' '}
                                                    {dataTender?.data?.school_user?.last_name}
                                                </div>
                                                {isToolTip('.school-user-name') && (
                                                    <div className="hover-content">
                                                        {dataTender?.data?.school_user?.first_name}{' '}
                                                        {dataTender?.data?.school_user?.last_name}
                                                    </div>
                                                )}
                                                <div className="card__additional-info school-user-info">
                                                    {dataTender?.data?.school_user?.email}
                                                </div>
                                                {isToolTip('.school-user-info') && (
                                                    <div className="hover-content">
                                                        {dataTender?.data?.school_user?.email}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tendermain__card card">
                                        <div className="card__title tenderrep">
                                            {screenWidth >= 500 ? 'Tender Representative' : 'Tender Rep.'}
                                        </div>
                                        <div className="card__subtitle">
                                            <img
                                                src={
                                                    dataTender?.data?.tender_representative?.avatar
                                                        ? dataTender?.data?.tender_representative?.avatar
                                                        : defaultAvatar
                                                }
                                                className="card__images"
                                            />
                                            <div className="card__info">
                                                <div className="card__name tr-name">
                                                    {dataTender?.data?.tender_representative?.first_name}{' '}
                                                    {dataTender?.data?.tender_representative?.last_name}
                                                </div>
                                                {isToolTip('.tr-name') && (
                                                    <div className="hover-content">
                                                        {dataTender?.data?.tender_representative?.first_name}{' '}
                                                        {dataTender?.data?.tender_representative?.last_name}
                                                    </div>
                                                )}
                                                <div className="card__additional-info tr-info">
                                                    {' '}
                                                    {dataTender?.data?.tender_representative?.email}
                                                </div>
                                                {isToolTip('.tr-info') && (
                                                    <div className="hover-content">
                                                        {dataTender?.data?.tender_representative?.email}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tendermain__row">
                                {screenWidth >= 834 ? (
                                    <>
                                        {dataTender?.data?.phase === 'submit' && (
                                            <SubmitPhase
                                                data={dataTender?.data}
                                                setConfirmModal={setConfirmModal}
                                                setTrigger={setTrigger}
                                                messageApi={messageApi}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'define' && (
                                            <DefinePhase
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setLoading={setLoading}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'design' && (
                                            <DesignPhase
                                                data={dataTender?.data}
                                                trigger={trigger}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'launch' && (
                                            <LaunchPhase
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'evaluate_1_2' && (
                                            <Evaluate12
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'evaluate_3' && (
                                            <EvaluatePhase3
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'evaluate_4' && (
                                            <EvaluatePhase4
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'award' && (
                                            <AwardPhase
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'closed' && (
                                            <AwardPhase
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}
                                        {dataTender?.data?.phase === 'canceled' && (
                                            <Canceled
                                                data={dataTender?.data}
                                                setTrigger={setTrigger}
                                                setConfirmModal={setConfirmModal}
                                            />
                                        )}

                                        <Messenger
                                            chatId={dataTender?.data?.chat?.uuid}
                                            messageApi={messageApi}
                                            tenderId={dataTender?.data?.chat?.id}
                                            tenderData={dataTender?.data}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className="tendermain__closeblock"
                                            onClick={() => setScreenShown('messenger')}
                                        >
                                            <div className="count-wrapper">
                                                Messenger<div className="count">1</div>
                                            </div>
                                            <img src={arrow} className="tendermain__arrow" />
                                        </div>
                                        <div
                                            className="tendermain__closeblock"
                                            onClick={() => {
                                                setScreenShown('tasks');
                                            }}
                                        >
                                            <div className="count-wrapper">
                                                Task Center
                                                <div className="count">{dataTender?.data?.phase_tasks?.length}</div>
                                            </div>
                                            <img src={arrow} className="tendermain__arrow" />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {screenShown === 'tasks' && (
                    <div className="wrapper-for-phone">
                        <div className="breadcrumb-wrap">
                            <img src={arrow} onClick={() => setScreenShown('main')} />
                            <Breadcrumb className="breadcrumb" items={breadcrumbs} />
                        </div>
                        {dataTender?.data?.phase === 'submit' && (
                            <SubmitPhase
                                data={dataTender?.data}
                                setConfirmModal={setConfirmModal}
                                setTrigger={setTrigger}
                                messageApi={messageApi}
                            />
                        )}
                        {dataTender?.data?.phase === 'define' && (
                            <DefinePhase
                                data={dataTender?.data}
                                setTrigger={setTrigger}
                                setLoading={setLoading}
                                setConfirmModal={setConfirmModal}
                            />
                        )}
                    </div>
                )}
                {screenShown === 'messenger' && (
                    <div className="wrapper-for-phone">
                        <div className="breadcrumb-wrap">
                            <img src={arrow} onClick={() => setScreenShown('msin')} />
                            <Breadcrumb className="breadcrumb" items={breadcrumbs} />
                        </div>
                        <Messenger
                            chatId={dataTender?.data?.chat?.uuid}
                            messageApi={messageApi}
                            tenderId={dataTender?.data?.chat?.id}
                            tenderData={dataTender?.data}
                        />
                    </div>
                )}
            </Spin>
            {isOpenEditTenderModal && (
                <ModalTender
                    type="edit"
                    openModal={isOpenEditTenderModal}
                    setOpenModal={setIsOpenEditTenderModal}
                    messageApi={messageApi}
                    data={dataTender?.data}
                    setTrigger={setTrigger}
                />
            )}

            {confirmModal && (
                <NextPhaseModal
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    isLoading={isLoading}
                    moveFunc={moveFunc}
                    nameOfNextPhase={nameOfNextPhase}
                    dataTender={dataTender?.data}
                />
            )}
            {openCancelTenderModal && (
                <CancelTenderModal
                    openModal={openCancelTenderModal}
                    setOpenModal={setOpenCancelTenderModal}
                    setTrigger={setTrigger}
                    selectedTender={dataTender?.data}
                />
            )}
        </>
    );
};
