import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const DashboardSvg = ({ fill }: { fill: string }) => (
    <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.8333 2.50016H2.16663"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18 2.5V12.4017C18 13.3767 17.1608 14.1667 16.125 14.1667H4.875C3.83917 14.1667 3 13.3767 3 12.4017V2.5"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.3334 14.1665L13 17.4998"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.66667 14.1665L8 17.4998"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8333 6.6665L11.0558 9.444L9.38913 7.77734L7.16663 9.99984"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const DashboardIcon = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <DashboardSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default DashboardIcon;
