import { useState } from 'react';
import { ProfileInfo } from '../../components/Profile/ProfileInfo';
import './index.scss';
import { ChangePassword } from '../../components/Profile/ChangePassword';

export const ManageProfile = () => {
    const [currentKey, setCurrentKey] = useState<string | null>(null);

    const handleClose = () => {
        setCurrentKey(null);
    };
    return (
        <div className="profilePage">
            <div className="profilePage__title">Manage Profile</div>
            <div>
                <ProfileInfo
                    setType={setCurrentKey}
                    onOpen={() => setCurrentKey('general')}
                    open={currentKey === 'general'}
                    disabled={(currentKey !== null || currentKey === 'password') && currentKey !== 'general'}
                />
            </div>
            <div className="profilePage__block">
                <ChangePassword
                    open={currentKey === 'password'}
                    onClose={handleClose}
                    onOpen={() => setCurrentKey('password')}
                    disabled={currentKey !== null && currentKey !== 'password'}
                />
            </div>
        </div>
    );
};
