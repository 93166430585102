export const PeopleIcon = ({ fill, style }: { fill: string; style?: any }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={style}>
        <path
            d="M12.6797 8.68991C14.1683 8.63577 15.3498 7.41829 15.3594 5.92876C15.3364 4.47153 14.1369 3.30856 12.6797 3.33054"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.85644 8.68991C9.34502 8.63578 10.5266 7.4183 10.5361 5.92876C10.5131 4.47153 9.31369 3.30856 7.85644 3.33054C6.39919 3.30856 5.19976 4.47153 5.17676 5.92876C5.18593 7.41845 6.3677 8.63614 7.85644 8.68991V8.68991Z"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M13.3349 16.6694V15.0021C13.3349 13.1604 11.8419 11.6674 10.0002 11.6674H5.83179C3.99008 11.6674 2.49707 13.1604 2.49707 15.0021V16.6694"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M17.503 16.6694V14.1684C17.503 12.7871 16.3832 11.6674 15.002 11.6674"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
