import { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { yupSync } from '../../utils';
import { FloatTextArea } from '../Fields/FloatTextArea';
import { Loader } from '../Loader/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import { tenderDataProps } from '../../pages/TenderViewPage/types';
import { FilePicker } from '../Fields/FilePicker';
import { UpdateTaskFile, createAbortController } from '../../api/axiosCruds';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';

interface MessageApi {
    success: (message: string) => void;
    error: (message: string) => void;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi?: MessageApi;
    type?: string;
    data?: tenderDataProps;
    taskId?: number | null | string;
    title: string;
    subtitle: string | ReactNode;
    submitButtonText?: string;
    specialFormats?: string[];
}

interface FormDataProp {
    comment: string | null;
    file: UploadFile;
}

const validationSchema = Yup.object().shape({
    file: Yup.array().of(Yup.object().shape({})).required('')
});

export const UploadFileModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    type,
    data,
    setTrigger,
    taskId,
    title,
    subtitle,
    submitButtonText,
    specialFormats
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [file, setFile] = useState<UploadFile[]>([]);
    const [fileErr, setFileErr] = useState('');
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const cancelRequest = () => {
        if (abortController) {
            abortController.abort();
        }
    };

    interface postData {
        ids: { tender_id: number | undefined; task_id: number | undefined | string | null };
        data: any;
    }

    const updateTaskFunc = async (prop: FormDataProp) => {
        setIsLoading(true);
        try {
            const abortController = createAbortController();
            if (prop.file && (!fileErr || fileErr === 'Upload failed. Check your internet connection')) {
                const contentFile: any = prop?.file?.originFileObj;
                const fileData = new FormData();

                const file: File = contentFile;
                const fileName: string = prop.file.name;
                const comment: string = prop.comment ? prop.comment : '';
                const method: string = 'PUT';

                fileData.append('file', file, fileName);
                fileData.append('comment', comment);
                fileData.append('_method', method);

                const datatoPost: postData = {
                    ids: { tender_id: data?.id, task_id: taskId },
                    data: fileData
                };
                setFileErr('');

                if (abortController) {
                    abortController.abort();
                }

                const newAbortController = createAbortController();
                setAbortController(newAbortController);

                const signal = newAbortController.signal;

                await UpdateTaskFile(datatoPost, signal);
                await setTrigger((prev: boolean) => !prev);
                setOpenModal(false);
                await messageApi?.success('The task has been updated successfully');
            }
        } catch {
            setFileErr('Upload failed. Check your internet connection');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = () => {
        if (file.length) {
            form.validateFields()
                .then((values: any) => {
                    const formData = {
                        file: file[0],
                        comment: values.comment ? values.comment.trim() : null,
                        _method: 'PUT'
                    };
                    if (isLoading) return;

                    updateTaskFunc(formData);
                })
                .catch(() => {
                    setFileErr('You can upload files in the following formats: doc, pdf with a maximum size of 100MB');
                });
        } else {
            setFileErr('You can upload files in the following formats: doc, pdf with a maximum size of 100MB');
        }
    };

    return (
        <>
            <Modal
                className="modal-modal"
                destroyOnClose
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched()) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched()) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : submitButtonText ? submitButtonText : 'Save'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header meeting" style={{ height: '88px' }}>
                        <div className="modal__title">{title}</div>
                    </div>

                    <div className="modal__main main" style={{ padding: '24px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            {subtitle}
                        </div>
                        <Form form={form} onFinish={handleFormSubmit}>
                            <Form.Item name="file" rules={yupSync('file', validationSchema, true)}>
                                <FilePicker
                                    disabled={isLoading ? true : false}
                                    fileList={file}
                                    onChange={setFile}
                                    error={fileErr}
                                    setError={setFileErr}
                                    specialFormats={specialFormats}
                                />
                            </Form.Item>
                            <Form.Item name="comment">
                                <FloatTextArea
                                    disabled={isLoading ? true : false}
                                    floatLabel={'Comment'}
                                    maxLength={255}
                                    hasError={() => !!form.getFieldError(['comment']).length}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                cancelRequest();
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
