import { Schema } from 'yup';
import { FormRule } from 'antd';

export const yupSync = (path: string | string[], validationSchema: Schema, required = true): FormRule[] => {
    if (Array.isArray(path)) {
        const combinedName = path.join('.');

        return [
            {
                async validator(_, value: string) {
                    await validationSchema.validateAt(combinedName, value);
                },

                required
            }
        ];
    }

    return [
        {
            async validator(_, value: string) {
                await validationSchema.validateAt(path, { [path]: value });
            },
            required
        }
    ];
};
