import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
    duration: number;
    callback?: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ duration, callback }) => {
    const [remainingTime, setRemainingTime] = useState(duration);

    useEffect(() => {
        if (remainingTime <= 0) {
            callback && callback();
            return;
        }

        const timer = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [remainingTime]);

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return <span>{formatTime(remainingTime)}</span>;
};

export default CountdownTimer;
