import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const PastTendersSvg = ({ fill }: { fill: string }) => (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="2.99695"
            y="11"
            width="15.0062"
            height="7.50312"
            rx="2"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.83264 13.5008H12.1674"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.3358 10.9997V6.69958C16.3354 5.98348 15.7547 5.40321 15.0386 5.40321H10.8427C10.628 5.40288 10.4274 5.29647 10.3067 5.11892L9.39544 3.78003C9.27491 3.60283 9.07453 3.49671 8.86022 3.49658H5.96151C5.61741 3.49636 5.28733 3.63296 5.04401 3.87628C4.80068 4.1196 4.66409 4.44968 4.66431 4.79379V10.9997"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const PastTendersIcon = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <PastTendersSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default PastTendersIcon;
