import { useState } from 'react';
import { InputProps, Input } from 'antd';
import InputMask from 'react-input-mask';
import './index.scss';

// interface Props {
//     value?: string;
//     floatLabel?: string;
//     placeholder?: string;
//     required?: string;
//     className?: string;
//     maxLength?: number;
// }

export const PhoneNumberInput = ({
    value,
    floatLabel,
    placeholder,
    required,
    className,
    maxLength,
    onChange,
    hasError,
    error = false,
    mask = '099 999 9999',
    onBlur = () => {}
}) => {
    const [focus, setFocus] = useState(false);
    if (!placeholder) placeholder = floatLabel;

    const isOccupied = focus || value;

    const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

    const requiredMark = required ? <span className="red">*</span> : null;

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            <InputMask mask={mask} value={value} onChange={onChange} onBlur={onBlur}>
                {() => (
                    <input
                        className={`phone-input ${className} ${
                            error || (hasError() && (value?.includes('_') || !value)) ? 'err' : ''
                        }`}
                        type="tel"
                        maxLength={maxLength}
                    />
                )}
            </InputMask>
            <label className={labelClass}>
                {isOccupied ? floatLabel : placeholder} {requiredMark}
            </label>
        </div>
    );
};
