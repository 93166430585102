import { FC, useEffect, useState } from 'react';
import { Button, Modal, Form, Spin, Checkbox, message } from 'antd';
import { yupSync } from '../../utils';
import { FloatInput } from '../Fields/FloatInput';
import { FloatTextArea } from '../Fields/FloatTextArea';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';

import './Modals.scss';
import { useAddSupplierMutation, useUpdateManagerMutation, useUpdateSupplierMutation } from '../../api/tenders';
import { SupplierI, tenderDataProps } from '../../pages/TenderViewPage/types';
import { Loader } from '../Loader/Loader';
import { PhoneNumberInput } from '../Fields/PhoneNumberInput';

interface SupplierData {
    manager: { name: string; email: string; phone_number: string };
    name: string;
    email: string;
    incumbent: boolean;
    id: string;
}

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    SupplierData: SupplierData;
    data: tenderDataProps;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('This is a required field')
        .test('not-only-spaces', 'This is a required field', (value) => {
            return value.trim() !== '';
        }),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email field is required'),
    phone_number: Yup.string()
        .required('This is a required field')
        .matches(/^[^_]*$/, 'This is a required field')
});

export const EditSupplierModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    setTrigger,
    SupplierData,
    data
}) => {
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [changed, setChanged] = useState(false);

    const onInput = () => {
        setChanged(true);
    };

    const [updateManager, { data: updatedData, isLoading, isSuccess }] = useUpdateManagerMutation();

    console.log('data', data);

    useEffect(() => {
        if (!SupplierData) return;
        form.setFieldsValue({
            name: SupplierData?.manager.name,
            email: SupplierData?.manager?.email,
            phone_number: SupplierData?.manager?.phone_number
        });
    }, [SupplierData]);

    useEffect(() => {
        if (isSuccess) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            messageApi?.success(updatedData.message);
        }
    }, [isSuccess]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const dataToPost: any = {
                    tender_id: data.id,
                    invite_id: SupplierData.id,
                    data: {
                        name: values.name.trim(),
                        email: values.email.trim().toLowerCase(),
                        phone_number: values.phone_number
                    }
                };

                console.log('dataToPost', dataToPost);

                updateManager(dataToPost);
            })
            .catch((err) => console.log('err', err));
    };

    return (
        <>
            <Modal
                destroyOnClose
                className={`modal`}
                centered
                open={openModal}
                onCancel={() => {
                    if (changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button form="schoolForm" type="primary" block onClick={handleFormSubmit}>
                            {isLoading ? <Loader /> : SupplierData ? 'Save' : 'Add'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header addsupplier" style={{ padding: '0' }}>
                        <div className="modal__title">Edit Supplier</div>
                    </div>
                    <div className="modal__main main" style={{ padding: '15px 56px 43px 56px' }}>
                        <Form form={form}>
                            <Form.Item className="input-wrapper" rules={yupSync('name', validationSchema, true)}>
                                <FloatInput
                                    floatLabel="Supplier"
                                    className="input"
                                    maxLength={60}
                                    onInput={onInput}
                                    disabled={true}
                                />
                            </Form.Item>
                            <div className="main__title">Tender Manager</div>
                            <Form.Item
                                className="input-wrapper"
                                name="name"
                                rules={yupSync('name', validationSchema, true)}
                            >
                                <FloatInput floatLabel="Full Name" className="input" maxLength={60} onInput={onInput} />
                            </Form.Item>

                            <Form.Item
                                className="input-wrapper"
                                name="email"
                                rules={yupSync('email', validationSchema, true)}
                            >
                                <FloatInput floatLabel="Email" className="input" maxLength={60} onInput={onInput} />
                            </Form.Item>
                            <Form.Item
                                className="input-wrapper"
                                name="phone_number"
                                rules={yupSync('phone_number', validationSchema, true)}
                            >
                                <PhoneNumberInput
                                    floatLabel="Phone Number"
                                    className="input"
                                    maxLength={256}
                                    value={''}
                                    placeholder={undefined}
                                    required={undefined}
                                    onChange={() => {}}
                                    hasError={() => !!form.getFieldError('phone_number').length}
                                    onBlur={() => yupSync('phone_number', validationSchema, true)}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
