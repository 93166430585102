import { FC, useEffect, useState } from 'react';
import { Button, Modal, Form, Spin, Checkbox, message } from 'antd';
import { yupSync } from '../../utils';
import { FloatInput } from '../Fields/FloatInput';
import { FloatTextArea } from '../Fields/FloatTextArea';

import close from '../../assets/RoundedClose.svg';

import * as Yup from 'yup';

import './Modals.scss';
import { useAddSupplierMutation, useUpdateSupplierMutation } from '../../api/tenders';
import { useAppDispatch } from '../../hooks';
import { SupplierI, tenderDataProps } from '../../pages/TenderViewPage/types';
import { Loader } from '../Loader/Loader';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    SupplierData: SupplierI | null;
    data: tenderDataProps;
}

interface FormData {
    name: string;
    incumbent: boolean;
    email: string;
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('This is a required field')
        .test('not-only-spaces', 'This is a required field', (value) => {
            return value.trim() !== '';
        }),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email field is required')
});

interface SupplierData {
    name: string;
    email: string;
    incumbent: boolean;
}

interface DataI {
    tenderId: number;
    supplierId?: number;
    data: SupplierData;
}

export const AddSupplierModal: FC<ModalProps> = ({
    openModal,
    setOpenModal,
    messageApi,
    setTrigger,
    SupplierData,
    data
}) => {
    const [form] = Form.useForm();
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [changed, setChanged] = useState(false);

    const onInput = () => {
        setChanged(true);
    };

    const [addSupplier, { data: supplierData, isLoading, isSuccess }] = useAddSupplierMutation();
    const [updateSupplier, { data: updatedData, isLoading: updateIsLoading, isSuccess: updateIsSuccess }] =
        useUpdateSupplierMutation();

    useEffect(() => {
        if (!SupplierData) return;
        form.setFieldsValue({
            name: SupplierData?.name,
            email: SupplierData?.email
        });
        setCheckbox(SupplierData?.incumbent);
    }, [SupplierData]);

    useEffect(() => {
        if (isSuccess) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            messageApi?.success(supplierData.message);
        }
        if (updateIsSuccess) {
            setTrigger((prev: boolean) => !prev);
            setOpenModal(false);
            messageApi?.success(updatedData.message);
        }
    }, [isSuccess, updateIsSuccess]);

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                const dataToPost: DataI = {
                    tenderId: data.id,
                    data: {
                        name: values.name.trim(),
                        email: values.email.trim().toLowerCase(),
                        incumbent: checkbox
                    }
                };
                if (SupplierData?.id) {
                    dataToPost.supplierId = SupplierData?.id;
                    updateSupplier(dataToPost);
                } else {
                    addSupplier(dataToPost);
                }
            })
            .catch((err) => console.log('err', err));
    };

    return (
        <>
            <Modal
                destroyOnClose
                className={`modal`}
                centered
                open={openModal}
                onCancel={() => {
                    if (changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal(false);
                    }
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal(false);
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button form="schoolForm" type="primary" block onClick={handleFormSubmit}>
                            {isLoading || updateIsLoading ? <Loader /> : SupplierData ? 'Save' : 'Add'}
                        </Button>
                    </div>
                }
            >
                <div className="modal">
                    <div className="modal__header addsupplier">
                        <div className="modal__title">{SupplierData ? 'Edit' : 'Add'} Supplier</div>
                    </div>
                    <div className="modal__main main" style={{ padding: '15px 56px 43px 56px' }}>
                        <div className="main__subtitle" style={{ margin: '0 0 16px 0' }}>
                            All fields are mandatory
                        </div>

                        <Form form={form}>
                            <Form.Item
                                className="input-wrapper"
                                name="name"
                                rules={yupSync('name', validationSchema, true)}
                            >
                                <FloatInput
                                    floatLabel="Supplier Name"
                                    className="input"
                                    maxLength={60}
                                    onInput={onInput}
                                />
                            </Form.Item>
                            <Form.Item className="input-wrapper" style={{ margin: '-15px 0 0 0' }}>
                                <Checkbox
                                    className="checkbox"
                                    checked={checkbox}
                                    onChange={() => setCheckbox((prev) => !prev)}
                                >
                                    <div className="checkbox-title">Incumbent Supplier</div>
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                className="input-wrapper"
                                name="email"
                                rules={yupSync('email', validationSchema, true)}
                            >
                                <FloatInput floatLabel="Email" className="input" maxLength={60} onInput={onInput} />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
            <Modal
                className={`confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal(false);
                                setIsConfirmModal(false);
                            }}
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
