import { FC, useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Spin, message } from 'antd';
import { yupSync } from '../../utils';
import { FloatInput } from '../Fields/FloatInput';
import { FloatSelect } from '../Fields/FloatSelect';
import {
    useCreateUserMutation,
    useEditUserMutation,
    useLazyGetUserQuery,
    useResendInviteMutation
} from '../../api/users';
import { PhoneNumberInput } from '../Fields/PhoneNumberInput';
import { useLazyGetSchoolsQuery } from '../../api/schools';
import { Loader } from '../Loader/Loader';
import { usePreventSpaceTyping } from '../../hooks/usePreventSpaceTyping';
import { CustomErrorType } from '../../api/types/global';

import close from '../../assets/RoundedClose.svg';
import DefaultAvatar from '../../assets/DefaultAvatar.svg';
import penEdit from '../../assets/pen-edit.svg';
import email from '../../assets/mail.svg';
import Phone from '../../assets/Phone.svg';
import RoundedWhite from '../../assets/RoundedWhite.svg';
import heic2any from 'heic2any';

import * as Yup from 'yup';
import './Modals.scss';

interface ModalProps {
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<{ type: string; open: boolean; id: number | null }>>;
    type: string;
    userId: number | null;
    refetch: () => void;
}

interface FormData {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    school: string;
    job: string;
}

const validationSchema = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string().email('Enter your email: e.g. email@domain.com').required('Email field is required'),
    school: Yup.object().required('School field is required'),
    job: Yup.string(),
    phone_number: Yup.string().matches(/^[^_]*$/, 'Please, enter a valid phone number')
});

export const ModalUsers: FC<ModalProps> = ({ openModal, setOpenModal, type, userId, refetch }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const [err, setErr] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [fileAvatar, setFileAvatar] = useState<any>();
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [searchSchools, setSearchSchools] = useState<string>('');
    const [school, setSchool] = useState<any>(null);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [changed, setChanged] = useState<boolean>(false);
    const [fileErr, setFileErr] = useState('');
    const { handleKeyPress } = usePreventSpaceTyping();

    const [getUserTrigger, { data: dataUser, isSuccess: getUserSuccess }] = useLazyGetUserQuery();

    const [getSchoolsTrigger, { data: dataSchools }] = useLazyGetSchoolsQuery();

    const [createUser, { isLoading: createUserLoading, error: errorCreateUser }] = useCreateUserMutation();

    const [editUser, { error: errorEditUser }] = useEditUserMutation();

    const [invite, { isSuccess: inviteSuccess }] = useResendInviteMutation();

    useEffect(() => {
        if (inviteSuccess) {
            messageApi.success('The invite has been resent successfully');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        }
    }, [inviteSuccess]);

    useEffect(() => {
        if (type !== 'create' && openModal) {
            getUserTrigger(userId);
        }
    }, [type, openModal]);

    useEffect(() => {
        if (!dataUser) return;
        form.setFieldsValue({
            first_name: dataUser.data.first_name ? dataUser.data.first_name : '',
            last_name: dataUser.data.last_name ? dataUser.data.last_name : '',
            email: dataUser.data.email,
            phone: dataUser.data.phone_number ? dataUser.data.phone_number : '',
            school: dataUser.data.school,
            job: dataUser.data.job_title ? dataUser.data.job_title : ''
        });
        setImageUrl(dataUser.data.avatar);
        setSchool(dataUser.data.school);
    }, [getUserSuccess, dataUser, form]);

    useEffect(() => {
        if (errorCreateUser) {
            if (
                (errorCreateUser as CustomErrorType)?.data?.errors?.email?.includes(
                    'Oops! It looks like that email is already in use'
                )
            ) {
                messageApi.error('Oops! It looks like that email is already in use');
            } else {
                messageApi.error((errorCreateUser as CustomErrorType)?.data?.message);
            }
        }
    }, [errorCreateUser]);

    const createUserHandler = async (res: any) => {
        setLoading(true);
        try {
            await createUser(res).unwrap();
            await messageApi.success('School user has been successfully created');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        } catch {
        } finally {
            setLoading(false);
        }
    };

    const editUserHandler = async (res: any) => {
        setLoading(true);
        try {
            await editUser(res);
            await messageApi.success('School user has been successfully edited');
            setOpenModal({ open: false, type: '', id: null });
            refetch();
        } catch {
            if (errorEditUser) {
                messageApi.error('Something went wrong!');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFormSubmit = () => {
        form.validateFields()
            .then((values: any) => {
                if (type === 'create') {
                    const res = new FormData();
                    res.append('email', values.email.toLowerCase());
                    res.append('school_id', school.id);
                    res.append('role', 'school_user');
                    phoneNumber ? res.append('phone_number', phoneNumber) : res.append('phone_number', '');
                    values.first_name ? res.append('first_name', values.first_name) : res.append('first_name', '');
                    values.last_name ? res.append('last_name', values.last_name) : res.append('last_name', '');
                    values.job ? res.append('job_title', values.job) : res.append('job_title', '');
                    fileAvatar && res.append('avatar', fileAvatar);
                    createUserHandler(res);
                }
                if (type === 'edit') {
                    const res = new FormData();
                    res.append('_method', 'PUT');
                    res.append('email', values.email.toLowerCase());
                    res.append('role', 'school_user');
                    res.append('school_id', school.id);
                    phoneNumber ? res.append('phone_number', phoneNumber) : res.append('phone_number', '');
                    values.first_name ? res.append('first_name', values.first_name) : res.append('first_name', '');
                    values.last_name ? res.append('last_name', values.last_name) : res.append('last_name', '');
                    values.job ? res.append('job_title', values.job) : res.append('job_title', '');
                    if (!imageUrl) {
                        res.append('reset_avatar', '1');
                    } else {
                        if (fileAvatar) {
                            res.append('avatar', fileAvatar);
                        }
                    }
                    const formData = {
                        id: userId,
                        data: res
                    };
                    editUserHandler(formData);
                }
            })
            .catch((error: any) => {
                error.errorFields.forEach((elem: any) => {
                    if (elem?.name[0] === 'school') {
                        if (elem.errors[0]) {
                            setErr(true);
                        } else {
                            setErr(false);
                        }
                    }
                });
            });
    };

    useEffect(() => {
        if (searchSchools && searchSchools.length >= 2) {
            const params = {
                //@ts-ignore
                filter: { dropdown_search: searchSchools },
                page: 1
            };
            getSchoolsTrigger(params);
        }
    }, [searchSchools]);

    const handleFileChange = async (event: any) => {
        const maxFileSize = 5 * 1024 * 1024;
        const file = event.target.files[0];
        if (!file) return;
        const fileExtension = file.name.split('.').pop();

        let isValid = false;

        if (
            fileExtension === 'jpeg' ||
            fileExtension === 'jpg' ||
            fileExtension === 'png' ||
            fileExtension === 'heic'
        ) {
            isValid = true;
        }

        if (fileExtension === 'heic') {
            if (!isValid) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            } else if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            } else {
                const HeicUrl = await convertHEICToJPG(file);
                setImageUrl(URL.createObjectURL(HeicUrl));
                setFileAvatar(file);
                setFileErr('');
            }
        } else {
            if (!isValid) {
                setFileErr('You can upload files in the following formats: .jpg, .jpeg, .png, .heic');
            } else if (file.size > maxFileSize) {
                setFileErr('The file size limit is 5 MB');
            } else {
                setChanged(true);
                setImageUrl(URL.createObjectURL(file));
                setFileAvatar(file);
                setFileErr('');
            }
        }
    };

    const clickUpload = () => {
        fileInputRef?.current?.click();
    };

    const convertHEICToJPG = async (heicFile: File) => {
        const res: any = await heic2any({
            blob: heicFile,
            toType: 'image/jpeg',
            quality: 0.5
        });
        const newFile = new File([res], `${heicFile.name}`, {
            lastModified: Math.floor(new Date().getTime() / 1000),
            type: 'image/jpeg'
        });
        return newFile;
    };

    useEffect(() => {
        if (!school) {
            form.resetFields(['school']);
            setErr(false);
        }
    }, [school]);

    const resendInvite = () => {
        //@ts-ignore
        invite(userId).unwrap();
    };

    return (
        <>
            {contextHolder}
            <Modal
                destroyOnClose
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} modal`}
                centered
                open={openModal}
                onOk={() => handleFormSubmit()}
                onCancel={() => {
                    if (form.isFieldsTouched() || changed) {
                        setIsConfirmModal(true);
                    } else {
                        setOpenModal({ open: false, type: '', id: null });
                        refetch();
                    }
                }}
                closeIcon={<img src={close} />}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button
                            block
                            key="back"
                            onClick={() => {
                                if (form.isFieldsTouched() || changed) {
                                    setIsConfirmModal(true);
                                } else {
                                    setOpenModal({ open: false, type: '', id: null });
                                    refetch();
                                }
                            }}
                            className="cancel"
                        >
                            Cancel
                        </Button>
                        <Button block key="submit" type="primary" onClick={() => handleFormSubmit()}>
                            {createUserLoading || loading ? (
                                <Loader />
                            ) : type === 'create' ? (
                                'Create'
                            ) : type === 'edit' ? (
                                'Save Changes'
                            ) : (
                                ''
                            )}
                        </Button>
                    </div>
                }
            >
                <Spin spinning={loading} size="large">
                    <div className="modal">
                        <div className="modal__header">
                            <div className="modal__title">
                                {type === 'create' ? 'Create User' : type === 'edit' ? 'Edit' : ''}
                            </div>
                            {(type === 'view' || type === 'view_pending') && (
                                <div
                                    className="modal__edit"
                                    onClick={() => setOpenModal((prevState) => ({ ...prevState, type: 'edit' }))}
                                >
                                    <img src={penEdit} />
                                    <div>Edit</div>
                                </div>
                            )}
                            <div className="modal__avatar-wrap">
                                <div className="modal__addbtn">
                                    {imageUrl && (type === 'create' || type === 'edit') && (
                                        <img
                                            src={RoundedWhite}
                                            className="modal__delete-avatar-btn"
                                            onClick={() => {
                                                setChanged(true);
                                                setImageUrl('');
                                                setFileAvatar('');
                                            }}
                                        />
                                    )}
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .heic"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                    />

                                    {imageUrl ? (
                                        <img src={imageUrl} alt="avatar" className="upload-image" />
                                    ) : (
                                        <img src={DefaultAvatar} />
                                    )}

                                    <div className="modal__addtext" onClick={clickUpload}>
                                        {type === 'create' && !imageUrl
                                            ? 'Add Photo'
                                            : (type === 'edit' && imageUrl) || (type === 'create' && imageUrl)
                                            ? 'Edit Photo'
                                            : type === 'edit' && !imageUrl
                                            ? 'Add Photo'
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(type === 'create' || type === 'edit') && (
                            <div className="modal__main main">
                                {fileErr && <div className="main__file-error">{fileErr}</div>}
                                <div className="main__subtitle">
                                    Fields marked with <span style={{ color: '#E80A0F' }}>*</span> are mandatory
                                </div>
                                <Form form={form} onFinish={handleFormSubmit}>
                                    <div className="main__block">
                                        <div className="main__row">
                                            <Form.Item
                                                className="small-input-wrapper"
                                                name="first_name"
                                                rules={yupSync('first_name', validationSchema, true)}
                                            >
                                                <FloatInput floatLabel="First Name" className="input" maxLength={60} />
                                            </Form.Item>
                                            <Form.Item
                                                className="small-input-wrapper"
                                                name="last_name"
                                                rules={yupSync('last_name', validationSchema, true)}
                                            >
                                                <FloatInput floatLabel="Last Name" className="input" maxLength={60} />
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            className="input-wrapper"
                                            name="email"
                                            rules={yupSync('email', validationSchema, true)}
                                        >
                                            <FloatInput
                                                floatLabel="Email"
                                                className="input"
                                                maxLength={60}
                                                required={true}
                                                disabled={type === 'edit' ? true : false}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            className="input-wrapper"
                                            name="phone_number"
                                            rules={yupSync('phone_number', validationSchema, true)}
                                        >
                                            <PhoneNumberInput
                                                floatLabel="Phone Number"
                                                className="input"
                                                maxLength={256}
                                                value={dataUser?.data ? dataUser.data : phoneNumber}
                                                placeholder={undefined}
                                                required={undefined}
                                                onChange={(e: any) => setPhoneNumber(e.target.value)}
                                                hasError={() => !!form.getFieldError('phone_number').length}
                                                onBlur={() => yupSync('phone_number', validationSchema, true)}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="main__block">
                                        <Form.Item
                                            className="input-wrapper"
                                            name="school"
                                            rules={yupSync('school', validationSchema, true)}
                                        >
                                            <FloatSelect
                                                search={searchSchools}
                                                floatLabel="School"
                                                className="input-select"
                                                required={true}
                                                err={err}
                                                setSearch={setSearchSchools}
                                                options={dataSchools?.data}
                                                onChange={setSchool}
                                                value={school}
                                                form={form}
                                                hasError={() => false}
                                                type="school"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="main__block">
                                        <Form.Item
                                            className="input-wrapper"
                                            name="job"
                                            rules={yupSync('job', validationSchema, true)}
                                        >
                                            <FloatInput floatLabel="Job Title" className="input" maxLength={60} />
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        )}
                        {(type === 'view' || type === 'view_pending') && (
                            <div className="modal__main main">
                                <div className="main__fullName">
                                    {dataUser?.data?.full_name ? dataUser?.data?.full_name : '-'}
                                </div>
                                <div className="main__wrap-for-info">
                                    <div className="main__info">
                                        <img src={email} />
                                        <span>{dataUser?.data?.email ? dataUser?.data?.email : '- -'}</span>
                                    </div>
                                    <div className="main__info">
                                        <img src={Phone} />
                                        <span>{dataUser?.data?.phone_number ? dataUser?.data?.phone_number : '-'}</span>
                                    </div>
                                </div>
                                <div className="main__wrap-for-info">
                                    <div className="main__job job">
                                        <div className="job__title">Job title</div>
                                        <div className="job__value">{dataUser?.data?.job_title}</div>
                                    </div>
                                </div>

                                <div className="select-option value" style={{ margin: '24px 0' }}>
                                    <div className="select-option__img">
                                        <img src={dataUser?.data?.school?.preview} />
                                    </div>
                                    <div className="select-option__wrap">
                                        <div className="select-option__name">{dataUser?.data?.school?.name}</div>
                                        <div className="select-option__city">{dataUser?.data?.school?.address}</div>
                                    </div>
                                </div>
                                {type === 'view_pending' && (
                                    <button className="main__invite-btn" onClick={resendInvite}>
                                        Resend Invite
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </Spin>
            </Modal>
            <Modal
                className={`${type === 'view' || type === 'view_pending' ? 'view' : ''} confirmModal`}
                centered
                open={isConfirmModal}
                closeIcon={<div style={{ display: 'none', cursor: 'context-menu' }}></div>}
                width={600}
                footer={
                    <div style={{ display: 'flex', justifyItems: 'space-between', gap: '16px', width: '100%' }}>
                        <Button key="back" onClick={() => setIsConfirmModal(false)} block className="cancel">
                            Cancel
                        </Button>
                        <button
                            className="quit"
                            onClick={() => {
                                setOpenModal({ open: false, type: '', id: null });
                                setIsConfirmModal(false);
                                refetch();
                            }}
                            // block
                        >
                            Quit
                        </button>
                    </div>
                }
            >
                <div className="modal-confirm">Your data won’t be saved! Are you sure you want to quit?</div>
            </Modal>
        </>
    );
};
