import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';

type IconProps = Pick<IconComponentProps, 'onClick'>;

const AdminPanelSvg = ({ fill }: { fill: string }) => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5 15.8111H2.50002C1.61602 15.8111 0.900024 15.0951 0.900024 14.2111V3.01113C0.900024 2.12713 1.61602 1.41113 2.50002 1.41113H14.5C15.384 1.41113 16.1 2.12713 16.1 3.01113V14.2111C16.1 15.0951 15.384 15.8111 14.5 15.8111Z"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M0.900024 5.41074H16.1" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M3.30405 3.40284C3.30165 3.40284 3.30005 3.40444 3.30005 3.40684C3.30005 3.40924 3.30165 3.41084 3.30405 3.41084C3.30645 3.41084 3.30805 3.40924 3.30805 3.40684C3.30805 3.40444 3.30645 3.40284 3.30405 3.40284"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.33842 3.40284C5.33602 3.40284 5.33442 3.40444 5.33442 3.40684C5.33442 3.40924 5.33602 3.41084 5.33842 3.41084C5.34082 3.41084 5.34242 3.40924 5.34242 3.40684C5.34242 3.40444 5.34082 3.40284 5.33842 3.40284"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.3704 3.40284C7.368 3.40284 7.3664 3.40444 7.3664 3.40684C7.3664 3.40924 7.368 3.41084 7.3704 3.41084C7.3728 3.41084 7.3744 3.40924 7.3744 3.40684C7.3744 3.40444 7.372 3.40284 7.3704 3.40284"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.50004 8.21094V9.01094"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.50004 12.2109V13.0109"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.42001 9.41094L7.10961 9.81094"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.89041 11.4109L10.58 11.8109"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.42001 11.8109L7.10961 11.4109"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.89041 9.81094L10.58 9.41094"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.63121 9.48032C10.256 10.1051 10.256 11.1179 9.63121 11.7427C9.00641 12.3675 7.99361 12.3675 7.36881 11.7427C6.74401 11.1179 6.74401 10.1051 7.36881 9.48032C7.99361 8.85552 9.00641 8.85552 9.63121 9.48032"
            stroke={fill}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const AdminPanelIcon = (props: Partial<CustomIconComponentProps & IconProps>) => (
    <Icon component={() => <AdminPanelSvg fill={props.fill || 'currentColor'} />} {...props} />
);

export default AdminPanelIcon;
